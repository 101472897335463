import React from 'react';
// import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as routes from '../_CONS/routes';
/*** STYLE  ***/
// import styled from 'styled-components/macro';

/**
 * Material UI Theme and Addon Styles
 */
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
/**
 * Custom Theme and Overrides
 */
// import { color, util } from '../styles/style';
import theme from '../styles/theme';
/*** UI LIBS  ***/

/*** SCENES  ***/
import StudentPathway from '../scenes/StudentPathway';
import AdminPathway from '../scenes/AdminPathway';
import LandingPathway from '../scenes/LandingPathway';
/*** SESSIONS  ***/
import {
  userIsAuthenticatedRedir,
  userIsAdminChain,
  // userIsStudentChain,
  // userIsNotAuthenticatedRedir,
  // userIsAdminRedir,
  // userIsAuthenticated,
  // userIsNotAuthenticated,
  // userIsStudentRedir,
} from '../sessions/auth';

const Student = userIsAuthenticatedRedir(StudentPathway);
const Admin = userIsAdminChain(AdminPathway);

function NoMatch({ location }) {
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
class App extends React.Component {
  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />

          <Switch>
            <Route path={routes.STUDENT} component={Student} />
            <Route path={routes.ADMIN} component={Admin} />
            {/* <Route path={routes.ADMIN} component={Admin} />
            <Route path={routes.STUDENT} component={Student} /> */}
            <Route path={routes.LANDING} component={LandingPathway} />

            <Route component={NoMatch} />
          </Switch>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
