import firebase from '../api/firebaseAdmin';
import firebaseStudent from '../api/firebaseStudent';

export const setQuestion = qObj => {
  return dispatch => {
    const { section, level, view, qbank, qid, questions, isDraft } = qObj;

    dispatch({
      type: 'SET_QUESTION',
      section: section,
      level: level,
      view: view,
      qbank: qbank,
      questions: questions,
      qid: qid,
      status: 'loading',
      isDraft,
    });
    return Promise.resolve();

    // dispatch({ type: 'STUDY_GUIDE_STATUS', payload: 'review' });
  };
};
export const setQuestionView = view => {
  // console.log(`view-> ${view}`);
  return dispatch => {
    dispatch({
      type: 'SET_QUESTION_VIEW',
      view: view,
    });
    return Promise.resolve();

    // dispatch({ type: 'STUDY_GUIDE_STATUS', payload: 'review' });
  };
};
export const setSection = uObj => {
  return dispatch => {
    dispatch({
      type: 'SET_SECTION',
      status: uObj.status,
      section: uObj.section,
      level: uObj.level,
      qbank: uObj.qbank,
      view: uObj.view,
      qid: uObj.qid,
    });
    return Promise.resolve();
  };
};

export const sectionReset = uObj => {
  return (dispatch, getState) => {
    // console.log(`uObj = ${JSON.stringify(uObj)}`);

    dispatch({
      type: 'SECTION_RESET',
      section: uObj.section,
      level: uObj.level,
      qbank: uObj.qbank,
      view: uObj.list,
    });
    return Promise.resolve();
  };
};
export const _publishComplete = () => {
  return dispatch => {
    dispatch({
      type: 'PUBLISH_COMPLETE',
      status: 'published',
    });
    return Promise.resolve();
  };
};
export const _unpublishComplete = () => {
  return dispatch => {
    dispatch({
      type: 'UNPUBLISH_COMPLETE',
      status: 'unpublished',
    });
    return Promise.resolve();
  };
};
export const _removeComplete = () => {
  return dispatch => {
    dispatch({
      type: 'REMOVE_COMPLETE',
      status: 'removed',
    });
    return Promise.resolve();
  };
};

export const setQbank = uObj => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SET_QBANK',
      section: uObj.section,
      level: uObj.level,
      qbank: uObj.qbank,
      isDraft: uObj.isDraft,
      view: 'list',
    });
    return Promise.resolve();
  };
};
export const setQuestionById = qid => {
  return dispatch => {
    dispatch({
      type: 'SET_QUESTION_BY_ID',
      qid: qid,
    });
    return Promise.resolve();
  };
};
export const saveQuestion = (level, qbank, uObj) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();
    const levelQbank = `${level}/${qbank}`;
    const bankRef = firebase.ref(levelQbank);
    const qid = bankRef.push().key;
    // console.log(`levelQbank = ${levelQbank} qbank= ${qbank}`);
    // console.log(`uObj-> ${JSON.stringify(uObj)}`);
    const newObj = {
      ...uObj,
      uid: qid,
    };
    return bankRef
      .child(qid)
      .update(newObj)
      .then(() => {
        const setObj = {
          ...newObj,
          isDraft: true,
          qid: qid,
          questions: [qid],
        };
        dispatch(setQuestion(setObj));
        // return Promise.resolve();
      });
    // type: 'SET_QUESTION',
    // section: section,
    // view: view,
    // qbank: qbank,
    // questions: questions,
    // qid: qid,
    // status: 'loading',
    // return Promise.resolve();
  };
  //   return firebase
  //     .ref(qbank)
  //     .child(qid)
  //     .update(uObj);
  // };
};

export const updateQuestion = (level, qbank, qid, uObj) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();
    const levelQbank = `${level}/${qbank}`;
    // console.log(`qid.id= ${qid}`);
    // console.log(`levelQbank = ${levelQbank} qbank= ${qbank}`);
    // console.log(`uObj-> ${JSON.stringify(uObj)}`);
    return firebase
      .ref(levelQbank)
      .child(qid)
      .update(uObj);
  };
};
export const removeQuestion = (level, qbank, qid) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();
    const levelQbank = `${level}/${qbank}`;
    // console.log(`qid.id= ${qid}`);
    // console.log(`levelQbank = ${levelQbank} qbank= ${qbank}`);
    // console.log(`qid.id= ${qid}`);
    // console.log(`qbank= ${qbank}`);
    // const dobj = {
    //   archived: true,
    //   archivedDate: new Date(),
    // };
    return firebase
      .ref(levelQbank)
      .child(qid)
      .remove()
      .then(() => {
        dispatch(_removeComplete());
      });

    // .update(dobj);
    // return firebase
    //   .ref(qbank)
    //   .child(qid)
    //   .remove();
  };
};
export const publishQuestionBatch = (coll, type, level, jdata) => {
  return async (dispatch, getState) => {
    const db = firebaseStudent.database();

    var qbankRef = db.ref(coll);
    const promises = [];
    await jdata.forEach(item => {
      let newq = {
        ...item,
        type: type,
        level: level,
        published: true,
        publishedDate: new Date(),
      };
      promises.push(qbankRef.push(newq));
    });
    return Promise.all(promises);
  };
};
export const _publishQuestionToStudents = (qbank, qid, qdata) => {
  return (dispatch, getState) => {
    // console.log(`qbank ${qbank}, qid ${qid}, qdata, ${qdata}`);
    const db = firebaseStudent.database();
    return db.ref(`${qbank}/${qid}`).set(qdata);
  };
};

export const publishQuestions = (level, qbank, questions) => {
  return async (dispatch, getState) => {
    //const firebase = getFirebase();
    const levelQbank = `${level}/${qbank}`;
    const qbankRef = firebase.ref(levelQbank);
    const promises = [];
    await questions.forEach(item => {
      promises.push(
        qbankRef.child(item).update({
          published: true,
          publishedDate: new Date(),
        })
      );
    });
    return Promise.all(promises).then(() => {
      dispatch(_publishComplete());
    });
  };
};
export const unpublishQuestion = (level, qbank, qid) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();
    const levelQbank = `${level}/${qbank}`;
    const qRef = firebase.ref(levelQbank).child(qid);
    // console.log(`qid.id= ${qid}`);
    // console.log(`qbank= ${qbank}`);
    const uObj = {
      published: false,
      archivedDate: new Date(),
    };
    return qRef.update(uObj).then(() => {
      dispatch(_unpublishComplete());
    });
  };
};
export const publishQuestion = (level, qbank, qid) => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();
    const levelQbank = `${level}/${qbank}`;
    const qRef = firebase.ref(levelQbank).child(qid);
    // console.log(`qid.id= ${qid}`);
    // console.log(`qbank= ${qbank}`);
    const uObj = {
      published: true,
      publishedDate: new Date(),
    };
    return qRef.update(uObj).then(() => {
      dispatch(_publishComplete());
    });
  };
};
