import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
/*** CONTAINERS  ***/
import { Card, CardHeader } from '../../containers/Card';
/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/

/*** ACTIONS & APIs ***/
import firebase from '../../store/api/firebaseAdmin';
// import { mbeDB } from '../../store/api/fb';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/
import EditPromo from './EditPromo';
import NewBanner from '../../promos/NewBanner';
const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 700,
    textTransform: 'uppercase',
  },
});
class Promos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoList: [],
    };
    this.promoRef = firebase.database().ref('promos');
  }

  onListUpdate = snapshot => {
    let items = [];
    //console.log(`onListUpdate = ${snapshot}`);
    snapshot.forEach(function(childsnap) {
      items.push({ id: childsnap.key, data: childsnap.val() });
    });
    // const items = snapshot.map(childsnap => {
    //
    // })
    // console.log(`snapshot${snapshot.val()}`);
    this.setState({
      promoList: items,
    });
  };

  componentDidMount() {
    this.promoRef.on('value', snapshot => this.onListUpdate(snapshot));
    // this.promoRef = ref.on('value', function(snapshot) {
    //   this.onListUpdate(snapshot.val());
    // });
  }
  componentWillUnmount() {
    this.promoRef.off();
  }
  render() {
    const { classes } = this.props;
    const { promoList } = this.state;

    return (
      <Card>
        <CardHeader title="Promotional CTAs" />
        {promoList.length > 0 ? (
          promoList.map(item => {
            return (
              <ExpansionPanel key={item.id} defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    {item.data.type}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <EditPromo pid={item.id} promo={item.data} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })
        ) : (
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>default</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <NewBanner />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        <></>
      </Card>
    );
  }
}

Promos.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Promos);
