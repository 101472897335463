import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import New from './New';
import View from './View';
// import Update from './Update';
import List from './List';
const Users = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/view`} component={View} />
      <Route path={`${match.url}/new`} component={New} />
      {/* <Route path={`${match.url}/update`} component={Update} /> */}

      <Route path={`${match.url}`} component={List} />
    </Switch>
  );
};
Users.propTypes = {};
export default compose(
  firestoreConnect(() => [
    {
      collection: 'users',
      where: [['isSuper', '==', false]],
    },
  ]),
  connect(state => ({
    users: state.firestore.ordered.users,
    // profile: state.firebase.profile // load profile
  }))

  // connect((state, props) => ({
  //   users: state.firestore.ordered.users,
  // }))
)(Users);
