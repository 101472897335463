import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { color, util } from '../../styles/style';
const SideBarControls = styled.div`
  padding: ${util.padding};
`;
const QuestionCountText = styled.div`
  background-color: ${color.dark};
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  border-radius: 3px;
  letter-spacing: 1.5px;
  ${'' /* margin-bottom: 1rem; */} font-weight: 700;
`;

const QuestionCount = props => {
  const { currentQuestionNumber, totalQuestions } = props;
  return (
    <SideBarControls>
      {currentQuestionNumber && (
        <QuestionCountText>
          {`${currentQuestionNumber} / ${totalQuestions}`}
        </QuestionCountText>
      )}
    </SideBarControls>
  );
};

QuestionCount.propTypes = {};

export default QuestionCount;
