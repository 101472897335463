import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
/*** ROUTER  ***/
import { withRouter } from 'react-router-dom';
/*** STYLE  ***/
// import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Help from '@material-ui/icons/Help';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/
import HelpExpandPanels from '../../../components/HelpExpandPanels';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
/*** ACTIONS & APIs ***/
import { signOut } from '../../../store/actions/authActions';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/

// const styles = theme => ({
//   root: {
//     width: '100%',
//   },
// });

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    scroll: 'paper',
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDialogOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleDialogClose = () => {
    this.setState({ open: false });
  };
  openProfile = () => {
    console.log(`openProfile`);
    const { history } = this.props;
    history.push(`/admin/profile`);
    this.setState({ anchorEl: null });
  };
  openUsers = () => {
    console.log(`openUsers`);
    const { history } = this.props;
    history.push(`/admin/users`);
    this.setState({ anchorEl: null });
  };
  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { initials } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleDialogClose}
          scroll={this.state.scroll}
          fullWidth={true}
          maxWidth={'md'}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">FAQ's</DialogTitle>
          <DialogContent>
            <HelpExpandPanels />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <IconButton color="inherit" onClick={this.handleDialogOpen('paper')}>
          <Help />
        </IconButton>
        <IconButton
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={this.handleMenu}
          color="inherit"
        >
          <Avatar>{initials}</Avatar>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.openProfile}>PROFILE</MenuItem>
          <MenuItem onClick={this.openUsers}>USERS</MenuItem>
          <Divider />
          <MenuItem onClick={this.props.signOut}>LOG OUT</MenuItem>
        </Menu>
      </div>
    );
  }
}
UserMenu.propTypes = {
  signOut: PropTypes.func.isRequired,
  initials: PropTypes.string.isRequired,
};
const mapStateToProps = state => {
  return {
    initials: state.firebase.profile.initials,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserMenu)
);
