// import { rgba } from 'polished';
export const color = {
  /* Colors */
  primaryLightest: '#e0e4ff',
  primaryLight: '#ADB2F3',
  primary: '#5c67e5',
  primaryDark: '#4952b8',
  primaryDarker: '#34329F',
  secondaryLight: '#B9FFE9',
  secondary: '#01df9a',
  secondaryDark: '#00BF83',
  secondaryDarker: '#00A572',
  /* Shades */
  white: '#ffffff',
  grey: '#f5f7fa',
  greyLight: '#ECEEF1',
  blueLightest: '#cfdae6',
  blueLight: '#bfccd9',
  blueDark: '#adbdcc',
  blueDarkest: '#8595a6',
  dark: '#465359',
  darkest: '#272f32',
  greenLightest: '#c8e6c9',
  greenLight: '#81c784',
  greenMain: '#4caf50',
  greenDark: '#388e3c',
  greenDarkest: '#1b5e20',

  redLightest: '#ffebee',
  redLight: '#ffcdd2',
  redMain: '#f44336',
  redDark: '#d32f2f',
  redDarkest: '#b71c1c',

  /* Errors */
  succcess: '#a8e619',
  info: '#54ccee',
  warning: '#ff7a37',
  danger: '#e52254',
  dangerDark: '#C1103D',
  /* Utilities */
  links: '#5c67e5',
};

export const util = {
  radiusSmall: '3px',
  radius: '12px',
  radiusBig: '290486px',
  borderRadius: '0.8rem',
  // overlay: rgba('black', 0.54),
  // boxShadow: `0 2px 4px 0 ${rgba('black', 0.1)}`,
  fontFamily: 'Open Sans',
  padding: 'calc(1rem + 10px)',
  paddingSide: '2.4rem',
  paddingTop: '2.4rem',
  BtnPaddingVert: `calc(0.5rem - 1px)`,
  BtnPaddingHorz: '3rem',
};

export const fontType = {
  copy: '14px',
  title: '2rem',
  subTitle: '18px',
  answerLetter: '36px',
  answerCopy: '18px',
  feedbackCopy: '14px',
  questionCopy: '14px',
};
export const fontSize = {
  title: '2rem',
  answerLetter: '36px',
  answerCopy: '18px',
  feedbackCopy: '14px',
  questionCopy: '14px',
};

export const fontWeight = {
  extraLight: 200,
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export default {
  util,
  color,
  fontWeight,
  fontSize,
};

// export const fonts = [
//   {
//     family: 'Graphik',
//     name: 'Graphik-Thin',
//     weight: 100,
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-ThinItalic',
//     weight: 100,
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Extralight',
//     weight: 200,
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-ExtralightItalic',
//     weight: 200,
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Light',
//     weight: 300,
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-LightItalic',
//     weight: 300,
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Regular',
//     weight: 'normal',
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-RegularItalic',
//     weight: 'normal',
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Medium',
//     weight: 500,
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-MediumItalic',
//     weight: 500,
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Semibold',
//     weight: 600,
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-SemiboldItalic',
//     weight: 600,
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Bold',
//     weight: 'bold',
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-BoldItalic',
//     weight: 'bold',
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Black',
//     weight: 800,
//     style: 'normal',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-BlackItalic',
//     weight: 800,
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-SuperItalic',
//     weight: 900,
//     style: 'italic',
//   },
//   {
//     family: 'Graphik',
//     name: 'Graphik-Super',
//     weight: 900,
//     style: 'normal',
//   },
// ];

// export const fontFace = fonts
//   .map(
//     font => `
//       @font-face {
//         font-family: ${font.family};
//         src: url('/static/fonts/${font.name}.woff2') format('woff2'),
//              url('/static/fonts/${font.name}.woff') format('woff');
//         font-weight: ${font.weight};
//         font-style: ${font.style};
//         font-display: swap;
//       }
//     `
//   )
//   .join('');
