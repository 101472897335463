import React from 'react';
import styled from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';

// import PropTypes from 'prop-types';
// import Typography from '@material-ui/core/Typography';
//   ${'' /* margin-bottom: 0.5em; */}
const HeaderText = styled.div`
  font-size: 2.2rem;

  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: left;
  font-weight: ${fontWeight.semiBold};
`;
const HeaderTitle = ({ children, ...others }) => (
  <HeaderText>{children}</HeaderText>
);

HeaderTitle.propTypes = {};
HeaderTitle.defaultProps = {};

export default HeaderTitle;
