import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import { Link, Redirect } from 'react-router-dom';
import { withFirebase } from 'react-redux-firebase';
import Loading from '../../../components/Loading';
/* UI lib components */
// import Button from '@material-ui/core/Button';
// import FormControl from '@material-ui/core/FormControl';
// import Input from '@material-ui/core/Input';
// import InputLabel from '@material-ui/core/InputLabel';
import SignUpCta from '../../../elements/SignUpCta';

// import AuthErrorTxt from '../../../elements/AuthErrorTxt';
import LandingInfoText from '../../../elements/LandingInfoText';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
// import NoMode from './NoMode';

class ReturnAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: null,
      actionCode: null,
      apiKey: null,
      continueUrl: null,
      lang: null,
      email: '',
      password: '',
      ready: false,
      hasErrors: false,
      error: {},
      hasInfoText: false,
      infoText: '',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    const mode = params.mode;
    const actionCode = params.oobCode;
    // const apiKey = params.apiKey ? params.apiKey : '';
    const continueUrl = params.continueUrl ? params.continueUrl : '/';
    const lang = params.lang ? params.lang : 'en';
    console.log(`mode _____- ${mode}`);
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        this.startResetPassword(mode, actionCode, continueUrl, lang);
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.startVerifyEmail(mode, actionCode, continueUrl, lang);
        break;
      default:
        // Error: invalid mode.
        this.setState({
          mode: 'noMode',
        });
    }
  }

  startVerifyEmail(mode, actionCode, continueUrl, lang) {
    const { firebase } = this.props;
    // Try to apply the email verification code.
    firebase
      .auth()
      .applyActionCode(actionCode)
      .then(function(resp) {
        // Email address has been verified.
        console.log(`applyActionCode resp=> ${JSON.stringify(resp)}`);
        this.setState({
          mode,
          actionCode,
          continueUrl,
          lang,
          ready: true,
          hasErrors: false,
          error: {},
          hasInfoText: true,
          infoText: 'Email address has been verified. Sign-in to continue!',
        });
      })
      .catch(error => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        this.setState({
          mode,
          actionCode,
          continueUrl,
          lang,
          ready: true,
          hasInfoText: false,
          infoText: '',
          hasErrors: true,
          error: {
            code: error.code,
            msg: error.message,
          },
        });
      });
  }
  startResetPassword(mode, actionCode, continueUrl, lang) {
    const { firebase } = this.props;
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(email => {
        this.setState({
          email,
          mode,
          actionCode,
          continueUrl,
          lang,
          ready: true,
          hasInfoText: true,
          infoText: 'Verified! Please enter new Password.',
          hasErrors: false,
        });
      })
      .catch(error => {
        this.setState({
          mode,
          actionCode,
          continueUrl,
          lang,
          ready: true,
          hasErrors: true,
          error: {
            code: error.code,
            message: error.message,
          },
        });
      });
  }

  handlePasswordResetForm = (success, uobj) => {
    const { firebase, history } = this.props;
    const { email, actionCode } = this.state;
    const { password } = uobj;
    if (success) {
      firebase
        .confirmPasswordReset(actionCode, password)
        .then(() => {
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
              history.push('/');
            });
          // firebase.login({
          //   email: email,
          //   password: password,
          // });
        })
        .catch(err => {
          this.setState({
            ready: true,
            hasErrors: true,
          });
        });
    } else {
      const { email } = uobj;
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          this.setState({
            ready: true,
            hasErrors: false,
            mode: 'done',
            hasInfoText: true,
            infoText:
              'You should receive an email with a link to reset your password',
          });
        })
        .catch(err => {
          this.setState({
            ready: true,
            hasErrors: true,
          });
        });
    }
  };

  handleVerifyEmailForm = (type, uobj) => {
    const { firebase } = this.props;
    const { email, password, actionCode } = this.state;

    firebase
      .confirmPasswordReset(actionCode, password)
      .then(() => {
        firebase.login({
          email: email,
          password: password,
        });
      })
      .catch(err => {
        this.setState({
          ready: true,
          hasErrors: true,
        });
      });
  };
  render() {
    const {
      mode,

      ready,
      hasErrors,

      hasInfoText,
      infoText,
    } = this.state;
    const { authError, auth } = this.props;

    if (auth.uid !== undefined)
      return (
        <Redirect
          to={{
            pathname: `/admin`,
            state: { role: 'admin' },
          }}
        />
      );
    if (!ready) {
      return <Loading />;
    }

    return (
      <>
        {hasInfoText && <LandingInfoText>{infoText}</LandingInfoText>}
        {mode === 'resetPassword' && (
          <ResetPassword
            hasErrors={hasErrors}
            authError={authError}
            {...this.state}
            formSubmit={this.handlePasswordResetForm}
          />
        )}
        {mode === 'verifyEmail' && (
          <VerifyEmail
            {...this.state}
            formSubmit={this.handleVerifyEmailForm}
          />
        )}

        {/* {mode === 'noMode' && <NoMode {...this.state} />} */}

        <SignUpCta>
          <Link
            to={{
              pathname: '/',
            }}
          >
            Sign in.
          </Link>
        </SignUpCta>
      </>
    );
  }
}

ReturnAction.propTypes = {
  authError: PropTypes.object,
  auth: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};
export default compose(
  withFirebase,
  connect(mapStateToProps)
)(ReturnAction);
