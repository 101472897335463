import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
// import styled from 'styled-components/macro';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
/*** CONTAINERS  ***/
import { Card, CardContent } from '../../containers/Card';
/*** GLOBAL COMPONENTS  ***/
import AvatarCard from '../../components/AvatarCard';
// import PromoSection from '../../components/PromoSection';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
// import Button from '../../elements/Button';
/*** ACTIONS & APIs ***/

/*** UTILS & HELPERS ***/
// import { color, util, fontWeight, fontType } from '../../styles/style';
/*** SCREENS  ***/

function TabContainer(props) {
  return <>{props.children}</>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class Profile extends Component {
  state = {
    value: 0,
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { user } = this.props;
    const { value } = this.state;

    return (
      <>
        <Grid item xs={4}>
          <Grid container spacing={24} direction={'column'}>
            <Grid item>
              <AvatarCard user={user} />
            </Grid>
            {/* <Grid item>
              <PromoCta>
                <PromoCtaCopy>
              <PromoCtaCopyTitle>
              Call To Action Headline Tile
              </PromoCtaCopyTitle>
              Claritas commodo duis ullamcorper esse nunc. Vel qui
              demonstraverunt lectorum decima sed.
                </PromoCtaCopy>
                <PromoCtaButton>
              <Button color="default" variant="outlined">
              CTA Button
              </Button>
                </PromoCtaButton>
              </PromoCta>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs>
          <Card>
            <Tabs value={value} onChange={this.handleChange}>
              <Tab label="Settings" />
            </Tabs>
            {value === 0 && (
              <TabContainer>
                <CardContent>
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="First Name"
                          // autoComplete="Jerry"
                          // autoFocus
                          value={user.firstName}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="First Name"
                          // autoComplete="Jerry"
                          // autoFocus
                          value={user.lastName}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Email"
                          value={user.email}
                          InputProps={{
                            readOnly: true,
                          }}
                          // autoComplete="Jerry"
                          // autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      {/* <FormControl fullWidth>
                        <TextField
                          label="Password"
                          autoComplete="Jerry"
                          autoFocus
                        />
                      </FormControl> */}
                    </Grid>
                  </Grid>
                </CardContent>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <CardContent>
                  <Typography variant="h6">Settings</Typography>
                  graph
                </CardContent>
              </TabContainer>
            )}
          </Card>
        </Grid>
      </>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
};
export default Profile;
