import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components/macro';
// import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import FormControl from '@material-ui/core/FormControl';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
// import Select from '@material-ui/core/Select';

/*** CONTAINERS  ***/
import { MainScreen } from '../../../../containers/Main';
import { Card, CardContent } from '../../../../containers/Card';
import { FormControl } from '../../../../containers/Form';

/*** GLOBAL COMPONENTS  ***/
import LoadingModal from '../../../../components/LoadingModal';
import ContinueModal from '../../../../components/ContinueModal';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import FlushLinkButton from '../../../../elements/FlushLinkButton';

// import AuthErrorTxt from '../../../../elements/AuthErrorTxt';

/*** ACTIONS & APIs ***/
import {
  createNewUser,
  resetUserView,
} from '../../../../store/actions/userActions';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/
//import UserDialogModal from '../UserDialogModal';
const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dialogStatus: 'ready',
      // dialogType: 'create',
      // isDialogOpen: false,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      // password: Math.random()
      //   .toString(36)
      //   .slice(-8),
      role: 'admin',
      status: 'active',
      // userObj: {},
    };
    this.goBackScreen = this.goBackScreen.bind(this);
  }

  componentDidMount() {
    this.resetFormFields();
  }
  resetFormFields() {
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
      password: Math.random()
        .toString(36)
        .slice(-8),
    });
  }
  goBackScreen(e) {
    const { history } = this.props;
    history.push(`/admin/users`);
    e.preventDefault();
  }
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSelectChange = event => {
    this.setState({ accountType: event.target.value });
  };
  handleDialogCancel = () => {
    console.log(`handle CANCEL`);
    this.setState({ isDialogOpen: false });
    // this.cancelFooterAction();
  };
  handleDialogClose = () => {
    console.log(`handle CLOSE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };

  handleDialogDone = () => {
    console.log(`handle DONE`);
    const { history, resetUserView } = this.props;
    resetUserView('list', 'start').then(() => {
      history.push(`/admin/users`);
      // this.resetFormFields();
    });

    // this.resetFormFields();
    // this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogAction = () => {
    console.log(`handle ACTION`);
    const { resetUserView } = this.props;
    resetUserView('new', 'start').then(() => {
      this.resetFormFields();
    });
    // this.setState({ ...initialState });
    // this.postFooterAction();
  };
  // handleDialogDone = () => {
  //   console.log(`handle DONE`);
  //   const { history } = this.props;
  //   history.push(`/admin/users`);
  //   this.resetFormFields();
  //   this.setState({ isDialogOpen: false });
  // };
  // handleDialogAction = () => {
  //   console.log(`handle ACTION`);
  //   this.resetFormFields();
  // };
  handleSubmit = e => {
    e.preventDefault();
    console.log(`state = ${JSON.stringify(this.state)}`);
    const { isDialogOpen, dialogStatus, dialogType, ...sobj } = this.state;
    const { createNewUser } = this.props;
    console.log(`state = ${JSON.stringify(sobj)}`);
    // const sobj = {
    //   ...other,
    // };
    const studentObj = {
      ...sobj,
      startDate: new Date(),
    };
    createNewUser(studentObj);
    // this.setState({
    //   userObj: sobj,
    //   dialogType: 'create',
    //   dialogStatus: 'ready',
    //   isDialogOpen: true,
    // });
    // this.props.createNewStudent(this.state);
  };

  render() {
    const {
      // dialogType,
      // dialogStatus,
      // isDialogOpen,
      email,
      password,
      // password2,
      firstName,
      lastName,
      // accountType,
      // userObj,
    } = this.state;
    const { error, uStatus } = this.props;
    return (
      <>
        <LoadingModal open={uStatus === 'loading'} />
        <ContinueModal
          title="Email Sent"
          bodyContent="You have successfully CREATED a new Admin User and an Email has been sent to them. What would you like to do now?"
          open={uStatus === 'email-sent'}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />
        <Screen>
          <MainScreen
            title="New User"
            leftSideItem={
              <FlushLinkButton onClick={this.goBackScreen}>
                <ArrowBackIcon />
                BACK
              </FlushLinkButton>
            }
            spacing={24}
          >
            <Grid item xs={12}>
              <Card>
                <form onSubmit={this.handleSubmit}>
                  <CardContent>
                    <Grid container spacing={24}>
                      <Grid item xs>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="firstName">
                            First Name
                          </InputLabel>
                          <Input
                            type="text"
                            name="firstName"
                            value={firstName}
                            id="firstName"
                            autoFocus
                            onChange={this.handleChange}
                          />
                        </FormControl>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="lastName">Last Name</InputLabel>
                          <Input
                            type="text"
                            name="lastName"
                            value={lastName}
                            id="lastName"
                            onChange={this.handleChange}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                      <Grid item xs>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="email">Email Address</InputLabel>
                          <Input
                            id="email"
                            name="email"
                            value={email}
                            autoComplete="email"
                            onChange={this.handleChange}
                          />
                          <FormHelperText id="component-error-text">
                            {error ? error : null}
                          </FormHelperText>
                        </FormControl>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="passwordg">Password</InputLabel>
                          <Input
                            name="passwordg"
                            type="text"
                            value={password}
                            id="passwordg"
                            readOnly
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Create User
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Card>
            </Grid>
          </MainScreen>
        </Screen>
      </>
    );
  }
}

New.propTypes = {
  studentStatus: PropTypes.object,
  sStatus: PropTypes.string,
  error: PropTypes.string,
  newid: PropTypes.string,
};
const mapStateToProps = state => {
  return {
    userStatus: state.userStatus,
    uStatus: state.userStatus.status,
    error: state.userStatus.error,
    newid: state.userStatus.currentId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewUser: uobj => dispatch(createNewUser(uobj)),
    resetUserView: (view, status) => dispatch(resetUserView(view, status)),
  };
};
// const mapDispatchToProps = dispatch => {
//   return {
//     addUser: creds => dispatch(addUser(creds)),
//   };
// };
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
  // withStyles(styles)
)(New);
