import { createMuiTheme } from '@material-ui/core/styles';
import { color, util, fontWeight } from './style';

const overrides = {
  MuiAppBar: {
    root: {
      zIndex: 1300,
    },
    colorDefault: {
      backgroundColor: color.white,
      color: color.blueLight,
      boxShadow: '0 2px 7px 0 #CFDAE6',
    },
  },
  MuiToolbar: {
    root: {
      justifyContent: 'space-between',
    },
  },
  MuiTableCell: {
    root: {
      textAlign: 'left',
      paddingRight: 24,
    },
  },
  MuiTableHead: {
    root: {
      textTransform: 'uppercase',
      color: color.blueLightest,
    },
  },
  MuiButton: {
    contained: {
      color: color.white,
      borderRadius: util.radiusBig,
      fontWeight: fontWeight.bold,
      height: 48,
      padding: 'calc(.5em - 1px) 3em',
      verticalAlign: 'middle',
      justifyContent: 'center',
      boxShadow: 'none',
      '&$focusVisible': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        color: color.white,
        boxShadow: 'none',
        backgroundColor: color.primaryLight,
      },
    },

    containedPrimary: {
      color: color.white,
      backgroundColor: color.primary,
      boxShadow: 'none',
      '&$focusVisible': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        color: color.white,
        boxShadow: 'none',
        backgroundColor: color.primaryLight,
      },
      '&:hover': {
        backgroundColor: color.primaryDark,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: color.primary,
        },
        '&$disabled': {
          backgroundColor: color.primaryLight,
        },
      },
    },
    /* Styles applied to the root element if `variant="[contained | fab]"` and `color="secondary"`. */
    containedSecondary: {
      color: color.white,
      backgroundColor: color.secondary,
      '&$focusVisible': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        color: color.white,
        boxShadow: 'none',
        backgroundColor: color.secondaryLight,
      },
      '&:hover': {
        backgroundColor: color.secondaryDark,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: color.secondary,
        },
      },
    },
    outlined: {
      borderRadius: util.radiusBig,
      height: 48,
      padding: 'calc(.5em - 1px) 3em',
      verticalAlign: 'middle',
      justifyContent: 'center',
      border: `1px solid ${color.blueLightest}`,
      color: color.black,
      backgroundColor: color.white,
      boxShadow: 'none',
      '&$focusVisible': {
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        color: color.blueDarkest,
        boxShadow: 'none',
        backgroundColor: color.grey,
      },
      '&:hover': {
        backgroundColor: color.white,
        color: color.primary,
        border: `1px solid ${color.primary}`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: color.white,
        },
        '&$disabled': {
          backgroundColor: color.grey,
        },
      },
    },
    sizeSmall: {
      padding: '.25em 3em',
      height: 32,
    },
    label: {
      textTransform: 'uppercase',
    },
  },
  MuiCard: {
    // Name of the rule
    root: {
      width: '100%',
      boxShadow: '0 2px 7px 0 #CFDAE6',
      padding: 0,
      // marginTop: '1rem',
      // marginBottom: '1rem',
    },
  },
  MuiCardContent: {
    root: {
      padding: util.padding,
    },
  },
  MuiCardHeader: {
    root: {
        padding: `16px 16px 16px ${util.padding}`,
    },
    title: {
      fontSize: 20,
      fontWeight: fontWeight.semiBold,
    },
  },
  MuiExpansionPanel: {
    root: {
      boxShadow: 'none',
      display: 'block',
    },
  },
  MuiExpansionPanelDetails: {
    root: {
      display: 'block',
    },
  },
  MuiExpansionPanelSummary: {
    root: {
      color: color.dark,
      fontSize: '14px',
      fontWeight: '600',
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: '14px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  },
  MuiLinearProgress: {
    root: {
      colorSecondary: color.secondary,
      height: 9,
      borderRadius: 50,
    },
    barColorSecondary: color.grey,
  },
  MuiTable: {
    // table: {
    //   minWidth: '100%',
    // },
    root: {
      width: '100%',
    },
    // tableWrapper: {
    //   overflowX: 'auto',
    // },
  },
  MuiTabs: {
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
  },
  MuiTab: {
    root: {
      textTransform: 'initial',
      minWidth: 72,
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        color: '#1890ff',
        fontWeight: fontWeight.semiBold,
      },
      '&:focus': {
        color: '#40a9ff',
      },
    },
  },
  MuiAvatar: {
    colorDefault: {
      backgroundColor: color.blueDarkest,
    },
  },
};
const palette = {
  background: {
    paper: color.white,
    default: color.grey,
  },
  primary: {
    main: color.primary,
    dark: color.primaryDark,
  },
  secondary: {
    main: color.secondary,
    dark: color.secondaryDark,
    contrastText: '#ffffff',
  },
  danger: {
    main: color.red,
    dark: color.redDark,
    contrastText: '#ffffff',
  },
};
const spacing = {
  unit: 8,
};
const typography = {
  useNextVariants: true,
  fontFamily: ['Open Sans', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  h1: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1.125,
    marginBottom: '0.5em',
    letterSpacing: '0.5px',
  },
  body1: {
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 12,
  },
  // button: {
  //   fontStyle: "italic"
  // }
};
const themeName = 'Royal Blue Caribbean Green Antelope';

export default createMuiTheme({
  overrides,
  palette,
  typography,
  spacing,
  themeName,
});
