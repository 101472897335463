import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* style lib */

/* UI lib components */
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

/* GLOBAL ELEMENTS */
import SignUpCta from '../../../elements/SignUpCta';
import AuthErrorTxt from '../../../elements/AuthErrorTxt';
import LandingInfoText from '../../../elements/LandingInfoText';
/* STYLES & UTILS */
import { forgotPassword } from '../../../store/actions/authActions';

// const SignUpCta = styled.div`
//   font-size: ${fontType.copy};
//   font-weight: ${fontWeight.semiBold};
//   margin: 1.5rem 0;
//   text-align: center;
// `;

class ForgotPassword extends Component {
  state = {
    email: '',
    emailSent: false,
    infoText: 'You should receive an email with a link to reset your password',
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleEmailSubmit = e => {
    e.preventDefault();
    this.props.forgotPassword(this.state);
  };
  render() {
    const { authError, emailSent } = this.props;
    return (
      <>
        {emailSent && (
          <LandingInfoText>
            You should receive an email with a link to reset your password
          </LandingInfoText>
        )}
        <form onSubmit={this.handleEmailSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={this.handleChange}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <Button
              type="submit"
              variant="contained"
              fullWidth={true}
              color="primary"
            >
              Send Reset Email
            </Button>
            <AuthErrorTxt>{authError ? <p>{authError}</p> : null}</AuthErrorTxt>
            <SignUpCta>
              <Link
                to={{
                  pathname: '/',
                }}
              >
                Sign in.
              </Link>
            </SignUpCta>
          </FormControl>
        </form>
      </>
    );
  }
}

ForgotPassword.propTypes = {};

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    emailSent: state.auth.emailSent,
    role: state.firebase.profile.role,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: creds => dispatch(forgotPassword(creds)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);
