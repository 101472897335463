import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

/*** ACTIONS & APIs ***/

/*** UTILS & HELPERS ***/
import subjectTopics from '../../_CONS/subjectTopics';
/*** SCREENS  ***/

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    maxWidth: 1000 - 2 * theme.spacing.unit,
  },
  textField: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
const listSubjects = Object.keys(subjectTopics);
const topicObject = {
  topicCode: '',
  subTopicCode: '',
  subSubTopicCode: '',
  subSubSubTopicCode: '',
};
class SelectClassification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectCode: props.subjectCode ? props.subjectCode : '',
      subject: props.subjectCode ? subjectTopics[props.subjectCode].label : '',
      classification: props.classification,
      mainClassification: '',
      // classification: props.classification ? props.classification : '',
      // classificationArray: props.classification
      //   ? props.classification[0].split('-')
      //   : [],
      ...topicObject,
      topicCodes: [],
      subTopicCodes: [],
      subSubTopicCodes: [],
      subSubSubTopicCodes: [],
      // topicCode: '',
      // subTopicCode: '',
      // subSubTopicCode: '',
      // subSubSubTopicCode: '',
    };
  }
  componentDidMount() {
    const { subjectCode, classification } = this.props;

    if (classification === '') {
      let onlyTopicCodes = this.listTopics(subjectCode);
      // this.setState({
      //   topicCodes: onlyTopicCodes,
      // });
      this.setState({ classification: '', topicCodes: onlyTopicCodes });
      return;
    }
    // if (!classification) {
    //   this.setState({ classification: '' });
    //   return;
    // }
    // const mainClassificationValue = classification[0];

    const classificationArray = classification.split('-');

    console.log(`componentDidMount=> ${classificationArray.length}`);
    if (classificationArray) {
      if (classificationArray.length === 4) {
        const topicCodes = this.listTopics(subjectCode);

        // const topicCodes = subjectTopics[subjectCode].topics;
        const topicCode = classificationArray[0];
        const subTopicCodes = this.listSubTopics(subjectCode, topicCode);

        // const subTopicCodes = topicCodes[topicCode].subtopics;
        const subTopicCode = classificationArray[1];
        const subSubTopicCodes = this.listSubSubTopics(
          subjectCode,
          topicCode,
          subTopicCode
        );
        // const subSubTopicCodes = subTopicCodes[subTopicCode].subsubtopics;
        const subSubTopicCode = classificationArray[2];
        const subSubSubTopicCodes = this.listSubSubSubTopics(
          subjectCode,
          topicCode,
          subTopicCode,
          subSubTopicCode
        );
        // const subSubSubTopicCodes =
        //   subSubTopicCodes[subSubTopicCode].subsubsubtopics;
        const subSubSubTopicCode = classificationArray[3];

        this.setState({
          topicCodes,
          topicCode,
          subTopicCodes,
          subTopicCode,
          subSubTopicCodes,
          subSubTopicCode,
          subSubSubTopicCodes,
          subSubSubTopicCode,
        });
      }
      if (classificationArray.length === 3) {
        const topicCodes = this.listTopics(subjectCode);

        // const topicCodes = subjectTopics[subjectCode].topics;
        const topicCode = classificationArray[0];
        const subTopicCodes = this.listSubTopics(subjectCode, topicCode);

        // const subTopicCodes = topicCodes[topicCode].subtopics;
        const subTopicCode = classificationArray[1];
        const subSubTopicCodes = this.listSubSubTopics(
          subjectCode,
          topicCode,
          subTopicCode
        );
        // const subSubTopicCodes = subTopicCodes[subTopicCode].subsubtopics;
        const subSubTopicCode = classificationArray[2];

        this.setState({
          topicCodes,
          topicCode,
          subTopicCodes,
          subTopicCode,
          subSubTopicCodes,
          subSubTopicCode,
        });
      }
      if (classificationArray.length === 2) {
        const topicCodes = this.listTopics(subjectCode);

        // const topicCodes = subjectTopics[subjectCode].topics;
        const topicCode = classificationArray[0];
        const subTopicCodes = this.listSubTopics(subjectCode, topicCode);

        // const subTopicCodes = topicCodes[topicCode].subtopics;
        const subTopicCode = classificationArray[1];

        this.setState({
          topicCodes,
          topicCode,
          subTopicCodes,
          subTopicCode,
        });
      }
      if (classificationArray.length === 1) {
        const topicCodes = this.listTopics(subjectCode);

        // const topicCodes = subjectTopics[subjectCode].topics;
        const topicCode = classificationArray[0];
        // const subTopicCodes = this.listSubTopics(subjectCode, topicCode);

        // const subTopicCodes = topicCodes[topicCode].subtopics;
        // const subTopicCode = classificationArray[1];

        this.setState({
          topicCodes,
          topicCode,
        });
      }
    }
  }

  handleSubjectCodeChange = event => {
    const code = event.target.value;
    const label = subjectTopics[code].label;
    const topicCodes = this.listTopics(code);
    const classification = '';
    const uobj = {
      subjectCode: code,
      subject: label,
      classification,
    };
    this.props.handleUpdate(uobj);
    this.setState({
      subjectCode: event.target.value,
      subject: label,
      topicCodes: topicCodes,
      ...topicObject,
      classification: classification,
    });
  };
  listTopics(subjectCode) {
    const topics = subjectTopics[subjectCode].topics;
    const topicList = Object.keys(topics).map(topic => {
      return Object.assign({ key: topic, label: topics[topic].label }, {});
    });
    console.log(`topics_${JSON.stringify(topicList)}`);
    return topicList;
  }
  handleChangeTopicCode = event => {
    const { subjectCode, subject } = this.state;
    const newcode = event.target.value;
    const subTopicCodes = this.listSubTopics(subjectCode, newcode);
    const classification = newcode;
    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);

    this.setState({
      topicCode: newcode,
      classification: classification,
      subTopicCodes: subTopicCodes,
      subTopicCode: '',
      subSubTopicCode: '',
      subSubSubTopicCode: '',
    });
  };
  listSubTopics(subjectCode, topicCode) {
    // const { subjectCode } = this.state;

    const getSubtopics = subjectTopics[subjectCode].topics[topicCode];
    let subtopics;
    if (getSubtopics === undefined) {
      return [];
    } else {
      subtopics = subjectTopics[subjectCode].topics[topicCode].subtopics;
    }
    if (subtopics) {
      const subtopicsList = Object.keys(subtopics).map(topic => {
        return Object.assign({ key: topic, label: subtopics[topic].label }, {});
      });
      console.log(`subtopics_${JSON.stringify(subtopicsList)}`);
      return subtopicsList;
    }
    return [];
  }
  handleChangeSubTopicCode = event => {
    const newcode = event.target.value;
    const { subjectCode, subject, topicCode } = this.state;

    const subSubTopicCodes = this.listSubSubTopics(
      subjectCode,
      topicCode,
      newcode
    );

    const classification = `${topicCode}-${newcode}`;

    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);
    this.setState({
      subTopicCode: newcode,
      classification: classification,
      subSubTopicCodes: subSubTopicCodes,
      subSubTopicCode: '',
      subSubSubTopicCode: '',
    });
  };

  listSubSubTopics(subjectCode, topicCode, subTopicCode) {
    // const { subjectCode } = this.state;

    const topicObj = subjectTopics[subjectCode].topics[topicCode];
    if (topicObj.hasOwnProperty('subtopics')) {
      // const subtopics = topicObj.subtopics;
      const subTopicObj = topicObj.subtopics[subTopicCode];
      if (subTopicObj.hasOwnProperty('subsubtopics')) {
        const subSubTopics = subTopicObj.subsubtopics;
        const subsubtopicsList = Object.keys(subSubTopics).map(topic => {
          return Object.assign(
            { key: topic, label: subSubTopics[topic].label },
            {}
          );
        });
        console.log(`subsubtopicsList{JSON.stringify(subsubtopicsList)}`);
        return subsubtopicsList;
      }
    }
    return [];
  }
  handleChangeSubSubTopicCode = event => {
    const newcode = event.target.value;
    const { subjectCode, subject, topicCode, subTopicCode } = this.state;
    const subSubSubTopicCodes = this.listSubSubSubTopics(
      subjectCode,
      topicCode,
      subTopicCode,
      newcode
    );
    const classification = `${topicCode}-${subTopicCode}-${newcode}`;

    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);

    this.setState({
      subSubTopicCode: newcode,
      classification,
      subSubSubTopicCodes,
      subSubSubTopicCode: '',
    });
  };

  listSubSubSubTopics(subjectCode, topicCode, subTopicCode, subSubTopicCode) {
    // const { subjectCode } = this.state;
    const subTopicObject =
      subjectTopics[subjectCode].topics[topicCode].subtopics[subTopicCode];
    if (subTopicObject.hasOwnProperty('subsubtopics')) {
      const subsubtopicObj = subTopicObject.subsubtopics[subSubTopicCode];
      if (subsubtopicObj.hasOwnProperty('subsubsubtopics')) {
        const subsubsubtopics = subsubtopicObj.subsubsubtopics;
        const subsubsubtopicsList = Object.keys(subsubsubtopics).map(code => {
          return Object.assign(
            { key: code, label: subsubsubtopics[code].label },
            {}
          );
        });
        console.log(`subsubsubtopicsList{JSON.stringify(subsubsubtopicsList)}`);
        return subsubsubtopicsList;
      }
    }

    return [];
  }
  handleChangeSubSubSubTopicCode = event => {
    const newcode = event.target.value;
    const {
      subjectCode,
      subject,
      topicCode,
      subTopicCode,
      subSubTopicCode,
    } = this.state;
    const classification = `${topicCode}-${subTopicCode}-${subSubTopicCode}-${newcode}`;
    const uobj = {
      subjectCode,
      subject,
      classification,
    };
    this.props.handleUpdate(uobj);

    this.setState({ subSubSubTopicCode: event.target.value, classification });
  };

  render() {
    const { classes, type } = this.props;
    const {
      subjectCode,
      classification,
      subject,
      topicCode,
      subTopicCode,
      subSubTopicCode,
      subSubSubTopicCode,
      topicCodes,
      subTopicCodes,
      subSubTopicCodes,
      subSubSubTopicCodes,
    } = this.state;

    return (
      <>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            {type === 'study' ? (
              <FormControl className={classes.formControl}>
                <InputLabel shrink={true} htmlFor="show-subject-code">
                  Subject Code
                </InputLabel>
                <TextField
                  id="show-subject-code"
                  value={subjectCode}
                  className={classes.textField}
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                    shrink: 'true',
                  }}
                />
              </FormControl>
            ) : (
              <FormControl className={classes.formControl}>
                <InputLabel shrink={true} htmlFor="select-subject-code">
                  Subject Code
                </InputLabel>
                <Select
                  value={subjectCode}
                  onChange={this.handleSubjectCodeChange}
                >
                  <MenuItem value="" disabled>
                    Select...
                  </MenuItem>
                  {listSubjects.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel shrink={true} htmlFor="show-subject">
                Subject
              </InputLabel>
              <TextField
                id="show-subject"
                value={subject}
                className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: true,
                  shrink: 'true',
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <InputLabel shrink={true} htmlFor="classification">
                Classification
              </InputLabel>
              <TextField
                id="classification"
                value={classification}
                // className={classes.textField}

                margin="normal"
                InputProps={{
                  readOnly: true,
                  shrink: 'true',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify="flex-start" alignItems="center">
          <Grid item>
            {subjectCode && (
              <>
                {topicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple">
                      Topic Code
                    </InputLabel>
                    <Select
                      value={this.state.topicCode}
                      onChange={this.handleChangeTopicCode}
                    >
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      {topicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            {topicCode && (
              <>
                {subTopicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple">
                      Sub Topic Code
                    </InputLabel>
                    <Select
                      value={this.state.subTopicCode}
                      onChange={this.handleChangeSubTopicCode}
                    >
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      {subTopicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            {subTopicCode && (
              <>
                {subSubTopicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple">
                      Sub Sub Topic Code
                    </InputLabel>
                    <Select
                      value={this.state.subSubTopicCode}
                      onChange={this.handleChangeSubSubTopicCode}
                    >
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      {subSubTopicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            {subSubTopicCode && (
              <>
                {subSubSubTopicCodes.length > 0 && (
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="select-multiple">
                      Sub Sub Sub Topic Code
                    </InputLabel>
                    <Select
                      value={subSubSubTopicCode}
                      onChange={this.handleChangeSubSubSubTopicCode}
                    >
                      <MenuItem value="" disabled>
                        Select...
                      </MenuItem>
                      {subSubSubTopicCodes.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {`(${item.key}) ${item.label}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

SelectClassification.propTypes = {
  classes: PropTypes.object.isRequired,
  subjectCode: PropTypes.string.isRequired,
  classification: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectClassification);
