import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { compose } from 'redux';
import { connect } from 'react-redux';
/*** ROUTER  ***/
import { withRouter } from 'react-router-dom';
/*** STYLE  ***/

/*** UI LIBS  ***/
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/

/*** ACTIONS  ***/
import { setStudent } from '../../../store/actions/studentActions';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/

// import { withStyles } from '@material-ui/core/styles';

// import styled from 'styled-components/macro';

const ITEM_HEIGHT = 48;

class RowMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  deleteClick = event => {
    const { uid } = this.props;

    const qObj = {
      uid,
    };
    this.props.setStudent(qObj).then(() => {
      this.props.menuActionDelete(event, 'delete');
    });
    // this.setState({ anchorEl: event.currentTarget });
    this.setState({ anchorEl: null });
  };
  selectClick = (event, option) => {
    const { location, history, section, uid } = this.props;
    const qObj = {
      uid: uid,
      section,
    };
    const url = `${location.pathname}/${option}`;
    this.props.setStudent(qObj).then(() => {
      history.push(url);
    });
    // this.setState({ anchorEl: event.currentTarget });
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    // const { classes, isDraft } = this.props;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
        >
          <MenuItem key="view" onClick={e => this.selectClick(e, 'view')}>
            View
          </MenuItem>
          {/* <MenuItem key="update" onClick={e => this.selectClick(e, 'update')}>
            Update
          </MenuItem> */}
          <Divider key="divideDel" />
          <MenuItem key="delete" onClick={e => this.deleteClick(e, 'delete')}>
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
RowMenu.propTypes = {
  uid: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  menuActionDelete: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    setStudent: qObj => dispatch(setStudent(qObj)),
  };
};
export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(RowMenu);
