import React from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent } from '../../containers/Card';
const styles = {
  bigAvatar: {
    width: 90,
    height: 90,
    margin: 'auto',
    marginBottom: 20,
  },
};
const AvatarCard = props => {
  const { classes, user } = props;
  const initials = user.initials
    ? user.initials
    : `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;

  return (
    <Card>
      <CardContent>
        <Avatar className={classes.bigAvatar}>{initials}</Avatar>
        <Typography align="center" variant="h6">
          {user.firstName} {user.lastName}
        </Typography>
      </CardContent>
    </Card>
  );
};

AvatarCard.propTypes = {};

export default withStyles(styles)(AvatarCard);
