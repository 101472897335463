import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
// import { connect } from 'react-redux';
// import { compose } from 'redux';
// import { connect } from 'react-redux';
/*** ROUTER  ***/

/*** STYLE  ***/
// import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
/*** CONTAINERS  ***/
import { Card, CardHeader, CardContent } from '../../containers/Card';
/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
// import Button from '../../elements/Button';
/*** ACTIONS & APIs ***/

/*** UTILS & HELPERS ***/
import { color, fontWeight } from '../../styles/style';
/*** SCREENS  ***/

// const EditCard = styled.div`
//   color: ${color.dark};
//   ${props =>
//     props.isDirty &&
//     `
//     background-color: ${color.secondaryLight};
//   `};
// `;

const styles = theme => ({
  card: {
    display: 'flex',
    width: '100%',
  },
  content: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: fontWeight.semiBold,
    color: color.blueDarkest,
    height: '100%',
  },
  questionCopy: {
    backgroundColor: color.grey,
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: 14,
    lineHeight: 'initial',
    fontWeight: fontWeight.regular,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class CardFieldNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: true,
      oldContent: props.content,
      newContent: props.content,
    };
    // this.toggleEditMode = this.toggleEditMode.bind(this);
    // this.saveFieldValue = this.saveFieldValue.bind(this);
    // this.cancelFieldChange = this.cancelFieldChange.bind(this);
  }

  toggleEditMode() {
    this.setState(state => ({
      editMode: !state.editMode,
    }));
  }
  // cancelFieldChange() {
  //   const { oldContent } = this.state;
  //   this.setState({ newContent: oldContent, editMode: false });
  // }
  // saveFieldValue() {
  //   const { newContent } = this.state;
  //
  //
  //   this.props.cardAction(newContent);
  //
  //   this.setState(state => ({
  //     editMode: !state.editMode,
  //   }));
  // }
  // handleChange = name => event => {
  //   console.log(`_______handleChange_____${event.target.value}`);
  //   this.setState({
  //     [name]: event.target.value,
  //   });
  // };

  render() {
    const {
      classes,
      label,
      // name,
      // cardAction,
      // content,
      // isMultiline,
      // ...other
    } = this.props;
    // const { editMode, newContent, oldContent } = this.state;
    return (
      <Grid item xs={12}>
        <Card>
          <CardHeader title={label} />

          <CardContent className={classes.content}>
            {this.props.children}
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

CardFieldNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardFieldNew);
