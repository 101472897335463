const initState = {
  view: null,
  action: null,
  status: null,
  loading: false,
  error: null,
  data: {},
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SET_APP_STATUS':
      return {
        ...state,
        view: action.view,
        action: action.action,
        status: action.status,
        loading: action.loading,
        error: action.error,
        data: action.data,
      };
    case 'RESET_APP_STATUS':
      return {
        ...state,
        view: action.view,
        action: action.action,
        status: action.status,
        loading: false,
        data: {},
        error: null,
      };
    case 'CHANGE_APP_DATA':
      return {
        ...state,
        loading: action.loading,
        data: action.data,
      };
    default:
      return state;
  }
};

export default appReducer;
