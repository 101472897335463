import format from 'date-fns/format';
import isDate from 'date-fns/is_date';
import parse from 'date-fns/is_date';
const formatDateTime = inputVal => {
  // console.log(`formatDateTime=>${inputVal.toDate()}`);
  if (inputVal === undefined) {
    return 'no date time';
  }
  if (inputVal.hasOwnProperty('_seconds')) {
    const raw = new Date(0).setUTCSeconds(inputVal._seconds);
    //const d = parse(inputVal._seconds);
    return format(raw, 'MM/DD/YY - HH:MMA');
  }

  if (!isDate(inputVal)) {
    return format(inputVal.toDate(), 'MM/DD/YY - HH:MMA');
  }
  return format(inputVal, 'MM/DD/YY - HH:MMA');
};

export { formatDateTime };
