import React from 'react';
import PropTypes from 'prop-types';
import MuiBtn from '@material-ui/core/Button';

const Button = ({ children, ...others }) => (
  <MuiBtn {...others}>{children}</MuiBtn>
);

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};
Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  className: '',
  disabled: false,
  onClick: () => {},
};

export default Button;
