import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
//import { compose } from 'redux';
//import { connect } from 'react-redux';
/*** ROUTER  ***/
//import { withRouter } from 'react-router-dom';
/*** STYLE  ***/

/*** UI LIBS  ***/
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/

/*** ACTIONS  ***/
//import { setUser } from '../../../../../../store/actions/userActions';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/

// import { withStyles } from '@material-ui/core/styles';

// import styled from 'styled-components/macro';

const ITEM_HEIGHT = 48;

class UserTableRowMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { currentAuthId, uid } = this.props;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
            },
          }}
        >
          <MenuItem
            key="view"
            onClick={e => this.props.menuAction(e, uid, 'view')}
          >
            View
          </MenuItem>

          {currentAuthId !== uid && [
            <Divider key="divideDel" />,
            <MenuItem
              key="delete"
              // onClick={e => this.deleteClick(e, 'delete')}
              onClick={e => this.props.menuAction(e, uid, 'delete')}
            >
              Delete
            </MenuItem>,
          ]}
        </Menu>
      </div>
    );
  }
}
UserTableRowMenu.propTypes = {
  currentAuthId: PropTypes.string,
  uid: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  menuAction: PropTypes.func,
};
export default UserTableRowMenu;
