const initState = {
  view: null,
  status: null,
  currentId: null,
  current: null,
  sbank: null,
  error: null,
};
const studentStatus = (state = initState, action) => {
  switch (action.type) {
    case 'STUDENTS_VIEW':
      console.log(`STUDENTS_VIEW ${action.view} ${action.status}`);
      return {
        ...state,
        view: action.view,
        status: action.status,
      };
    case 'STUDENTS_STATUS':
      console.log(`STUDENTS_STATUS ${action.status}`);
      return {
        ...state,
        status: action.status,
      };
    case 'SET_STUDENT':
      console.log(`SET_STUDENT ${action.currentId}`);
      return {
        ...state,
        status: action.status,
        currentId: action.currentId,
        sbank: action.sbank,
      };
    case 'NEW_STUDENT_SUCCESS':
      console.log(`NEW_STUDENT_SUCCESS ${action.status}`);
      return {
        ...state,
        currentId: action.currentId,
        status: action.status,
      };
    case 'STUDENT_EMAIL_SENT':
      console.log(`STUDENT_EMAIL_SENT ${action.status}`);
      return {
        ...state,
        status: action.status,
      };

    case 'UPDATE_STUDENT_SUCCESS':
      console.log(`UPDATE_STUDENT_SUCCESS ${action.status}`);
      return {
        ...state,
        currentId: action.currentId,
        status: action.status,
      };

    case 'STUDENT_ERROR':
      console.log(`STUDENT_ERROR ${action.err.message}`);
      return {
        ...state,
        status: 'error',
        error: action.err.message,
      };
    default:
      return state;
  }
};

export default studentStatus;
