import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
// import { compose } from 'redux';
// import { connect } from 'react-redux';
/*** ROUTER  ***/
/*** STYLE  ***/
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
/*** CONTAINERS  ***/
/*** GLOBAL COMPONENTS  ***/
// import Loading from '../../components/Loading';
/*** LOCAL COMPONENTS  ***/
import Content from './Content';
/*** ELEMENTS  ***/
/*** ACTIONS & APIs ***/
// import {
//   createNewStudent,
//   removeStudent,
//   updateStudent,
// } from '../../store/actions/studentActions';
/*** UTILS & HELPERS ***/
/*** SCREENS  ***/
const DialogContentText = withStyles(theme => ({
  root: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
}))(MuiDialogContentText);
// const DialogContentWorking = withStyles(theme => ({
//   root: {
//     margin: 0,
//     height: '100%',
//
//     padding: '6rem',
//   },
// }))(MuiDialogContent);

class ContinueModal extends React.Component {
  handlePreDone = event => {
    this.props.handleDone();
  };
  handlePreAction = event => {
    this.props.handleAction();
  };

  render() {
    const { open, title, bodyText } = this.props;
    return (
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
      >
        <Content
          title={title}
          hasActions={true}
          leftLabel="Done"
          leftAction={this.props.handleDone}
          rightLabel="Add Another"
          rightAction={this.props.handleAction}
        >
          <MuiDialogContent>
            <DialogContentText>{bodyText}</DialogContentText>
          </MuiDialogContent>
        </Content>
      </Dialog>
    );
  }
}

ContinueModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleAction: PropTypes.func,
  handleDone: PropTypes.func,
};

export default ContinueModal;
