import React from 'react';
//import PropTypes from 'prop-types';
/***    router          ***/

/***    data and store  ***/
import format from 'date-fns/format';
/***    ui & styles     ***/
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
/***    containers      ***/
import { CardContent } from '../../../../../containers/Card';
/***    assets          ***/
const Billing = props => {
  const { student } = props;
  const { transactionData } = student;
  const orderId = transactionData ? transactionData.id : 'bundle';
  const upgradedDate = transactionData
    ? transactionData.updatedAt
    : student.upgradedDate;
  const orderAmount = transactionData ? transactionData.amount : '0.00';
  const paymentType = transactionData
    ? transactionData.paymentInstrumentType
    : 'n/a';

  return (
    <CardContent>
      <Grid container spacing={24}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Id"
              value={orderId}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Date"
              value={format(upgradedDate, 'MM/DD/YY - HH:MMA')}
              InputProps={{
                readOnly: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={24}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Amount"
              value={orderAmount}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              // autoComplete="Jerry"
              // autoFocus
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Payment Type"
              value={paymentType}
              InputProps={{
                readOnly: true,
              }}
              // autoComplete="Jerry"
              // autoFocus
            />
          </FormControl>
        </Grid>
      </Grid>
    </CardContent>
  );
};

Billing.propTypes = {};

export default Billing;
