const initState = {
  status: 'init',
  section: null,
  level: null,
  view: null,
  qbank: null,
  qid: '',
  errors: null,
  questions: null,
  querys: null,
  isDraft: true,
};

const questionStatus = (state = initState, action) => {
  switch (action.type) {
    case 'SET_QUESTION':
      console.log('SET_QUESTION');
      return {
        ...state,
        section: action.section,
        level: action.level,
        view: action.view,
        qbank: action.qbank,
        status: action.status,
        qid: action.qid,
        questions: action.questions,
        isDraft: action.isDraft,
      };
    case 'SECTION_RESET':
      console.log('SECTION_RESET');
      return {
        ...state,
        qbank: action.qbank,
        section: action.section,
        level: action.level,
        view: action.view,
      };

    case 'SET_QUESTION_VIEW':
      console.log('SET_QUESTION_VIEW');
      return {
        ...state,
        view: action.view,
      };
    case 'PUBLISH_COMPLETE':
      console.log('PUBLISH_COMPLETE');
      return {
        ...state,
        status: action.status,
      };
    case 'REMOVE_COMPLETE':
      console.log('REMOVE_COMPLETE');
      return {
        ...state,
        status: action.status,
      };
    case 'UNPUBLISH_COMPLETE':
      console.log('UNPUBLISH_COMPLETE');
      return {
        ...state,
        status: action.status,
      };
    case 'SET_SECTION':
      console.log('SET_SECTION');
      return {
        ...state,
        status: action.status,
        section: action.section,
        level: action.level,
        qbank: action.qbank,
        view: action.view,
        qid: action.qid,
      };
    case 'SET_QBANK':
      console.log('SET_QBANK');
      return {
        ...state,
        section: action.section,
        level: action.level,
        qbank: action.qbank,
        isDraft: action.isDraft,
        view: action.view,
      };
    case 'SET_QUESTION_BY_ID':
      console.log('SET_QUESTION_BY_ID');
      return {
        ...state,
        qid: action.qid,
      };

    case 'QUESTION_ERROR':
      console.log('QUESTION_ERROR');
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default questionStatus;
