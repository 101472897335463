import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, getVal } from 'react-redux-firebase';
/*** ROUTER  ***/
import { withRouter } from 'react-router-dom';
/*** STYLE  ***/
import styled from 'styled-components/macro';

import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
/*** CONTAINERS  ***/
import { MainScreen, MainFooter } from '../../../containers/Main';
/*** GLOBAL COMPONENTS  ***/
import DialogModal from '../../../components/DialogModal';
/*** LOCAL COMPONENTS  ***/
import EditForm from './EditForm';
/*** ELEMENTS  ***/
import SimpleBtn from '../../../elements/SimpleBtn';
import FlushLinkButton from '../../../elements/FlushLinkButton';
/*** ACTIONS  ***/
import {
  setQuestionView,
  removeQuestion,
  publishQuestion,
} from '../../../store/actions/questionActions';
/*** UTILS & HELPERS ***/
// import { color } from '../../../styles/style';
/*** SCREENS  ***/

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;
const FooterLeft = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;
`;
const FooterRight = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  align-items: center;
`;

class EditQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      dialogType: 'preview',
      dialogStatus: 'ready',
      view: 'edit',
    };

    this.goBackScreen = this.goBackScreen.bind(this);
    this.preDelete = this.preDelete.bind(this);
    this.prePublish = this.prePublish.bind(this);
    // this.loadPreview = this.loadPreview.bind(this);
  }
  goBackScreen(e) {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);

    e.preventDefault();
  }
  handleDialogCancel = () => {
    console.log(`handle CANCEL`);
    this.setState({ isDialogOpen: false });
    // this.cancelFooterAction();
  };
  handleDialogClose = () => {
    console.log(`handle CLOSE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogDone = () => {
    console.log(`handle DONE`);
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);
    this.setState({ isDialogOpen: false });
    // this.postFooterAction();
  };

  handleDialogAction = () => {
    console.log(`handle ACTION`);
    const { section } = this.props;
    this.props.history.push(`/admin/${section}/new`);
    this.setState({ isDialogOpen: false });
  };
  loadPreview = event => {
    const { section, history } = this.props;
    event.preventDefault();
    const url = `/admin/${section}/preview`;
    this.props.setQuestionView('preview').then(() => {
      history.push(url);
    });
  };

  loadNew = event => {
    const { section, history } = this.props;
    event.preventDefault();
    const url = `/admin/${section}/new`;
    this.props.setQuestionView('new').then(() => {
      history.push(url);
    });
  };

  preDelete(e) {
    const { qid } = this.props;
    console.log(`preDelete qid=${qid}`);
    this.setState({
      qid,
      questions: [qid],
      dialogType: 'delete',
      dialogStatus: 'ready',
      isDialogOpen: true,
    });
  }
  prePublish(e) {
    this.setState({
      dialogType: 'publish',
      dialogStatus: 'ready',
      isDialogOpen: true,
    });
  }
  // handleCardAction = name => event => {
  //   console.log(`name=${name} event=${event}`);
  // };
  render() {
    const { qid, currentQuestion } = this.props;
    const questions = [qid];
    const { isDialogOpen, dialogType, dialogStatus } = this.state;

    return (
      <>
        <DialogModal
          type={dialogType}
          open={isDialogOpen}
          status={dialogStatus}
          qid={qid}
          questions={questions}
          handleClose={this.handleDialogClose}
          handleCancel={this.handleDialogCancel}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />
        <Screen>
          <MainScreen
            title="Edit Question"
            leftSideItem={
              <FlushLinkButton onClick={this.goBackScreen}>
                <ArrowBackIcon />
                BACK
              </FlushLinkButton>
            }
            spacing={24}
          >
            {currentQuestion && <EditForm currentQuestion={currentQuestion} />}
          </MainScreen>
          <MainFooter>
            <FooterLeft>
              <SimpleBtn
                variant="contained"
                type="remove"
                onClick={this.preDelete}
              >
                DELETE
              </SimpleBtn>
            </FooterLeft>
            <FooterRight>
              <SimpleBtn
                variant="outlined"
                type="default"
                onClick={this.loadPreview}
              >
                PREVIEW
              </SimpleBtn>
              <SimpleBtn
                variant="contained"
                type="primary"
                onClick={this.loadNew}
              >
                ADD NEW
              </SimpleBtn>
              <SimpleBtn
                variant="contained"
                type="secondary"
                onClick={this.prePublish}
              >
                PUBLISH
              </SimpleBtn>
            </FooterRight>
          </MainFooter>
        </Screen>
      </>
    );
  }
}

EditQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    qpath: `${state.questionStatus.level}/${state.questionStatus.qbank}/${
      state.questionStatus.qid
    }`,
    qbank: state.questionStatus.qbank,
    level: state.questionStatus.level,
    status: state.questionStatus.status,
    qid: state.questionStatus.qid,
    section: state.questionStatus.section,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeQuestion: (level, qbank, qid) =>
      dispatch(removeQuestion(level, qbank, qid)),
    publishQuestion: (level, qbank, qid) =>
      dispatch(publishQuestion(level, qbank, qid)),
    setQuestionView: view => dispatch(setQuestionView(view)),
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect(props => {
    // if (!props.questions) return [];
    if (!props.qpath) return [];
    // const qbank = get(props, 'qbank');
    // const qid = get(props, 'qid');
    return [
      {
        path: get(props, 'qpath'),
      },
    ];
  }),
  connect(({ firebase }, props) => ({
    currentQuestion: getVal(firebase, `data/${props.qpath}`), // lodash's get can also be used
  })),

  withStyles(styles)
)(EditQuestion);
