import React from 'react';

import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
// import classNames from 'classnames';
// import { withStyles } from '@material-ui/core/styles';
// import { lighten } from '@material-ui/core/styles/colorManipulator';
/*** UI LIBS  ***/
import styled from 'styled-components/macro';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';

import IconRemoveRedEye from '@material-ui/icons/RemoveRedEye';
// import FilterListIcon from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import { color } from '../../../styles/style';
const StyledToolbar = styled(({ isActive, ...other }) => (
  <Toolbar {...other} />
))`
  padding-left: 8px;
  padding-right: 8px;
  color: ${props => (props.isActive ? '#01df9a' : color.dark)};
  background-color: ${props =>
    props.isActive ? color.secondaryLight : 'inherit'};
`;
const FilterInputLabel = styled(({ isActive, ...other }) => (
  <Typography classes={{ root: 'root' }} {...other} />
))`
  &&&&&&&& .root {
    color: ${props => (props.isActive ? color.secondary : color.dark)};
    margin-right: 10rem;
  }
`;
const FilterInputTextField = styled(({ isActive, ...other }) => (
  <TextField classes={{ marginDense: 'marginDense' }} {...other} />
))`
  background: #ffffff;
  &&&&&&&&& .marginDense {
    font-size: 0.8rem;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;
const FilterInputLabelArea = styled.div`
  color: ${props => (props.isActive ? color.secondary : color.dark)};
  margin-right: 1rem;
`;
const BatchArea = styled.div`
  display: flex;
  align-items: center;
  color: ${color.secondary};
`;
// const BatchActionArea = styled.div`
//   display: flex;
//   color: ${color.secondary};
// `;
// const Spacer = styled.div`
//   flex: 1 1 100%;
// `;
const FilterArea = styled.div`
  color: ${props => (props.isActive ? color.secondary : color.dark)};
  display: flex;
`;
const FilterInputArea = styled.div`
  color: ${color.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// const toolbarStyles = theme => ({
//   root: {
//     paddingRight: theme.spacing.unit,
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
//   spacer: {
//     flex: '1 1 100%',
//   },
//   actions: {
//     color: theme.palette.text.secondary,
//     display: 'flex',
//   },
//   filters: {
//     color: theme.palette.text.secondary,
//     display: 'flex',
//     alignSelf: 'flex-start',
//     flex: '1 0 100%',
//   },
//   filterInput: {
//     minWidth: '200px',
//   },
//   title: {
//     flex: '0 0 auto',
//   },
// });

const EnhancedTableToolbar = props => {
  const { numSelected, isDraft, superAdmin, filterOn } = props;
  return (
    <StyledToolbar
      disableGutters={true}
      variant="dense"
      isActive={numSelected > 0}
    >
      <FilterArea isActive={numSelected > 0}>
        <Tooltip title="Search list">
          <IconButton
            aria-label="Search list"
            disableRipple
            color="inherit"
            onClick={props.toggleFilter}
          >
            <Search />
          </IconButton>
        </Tooltip>
        {filterOn && (
          <FilterInputArea>
            <FilterInputLabelArea isActive={numSelected > 0}>
              <FilterInputLabel color="inherit" variant="subtitle2">
                ID Search:
              </FilterInputLabel>
            </FilterInputLabelArea>
            <FilterInputTextField
              id="qsearch"
              name="qsearch"
              margin="dense"
              onChange={props.handleChange}
            />
          </FilterInputArea>
        )}
      </FilterArea>
      <BatchArea>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle2">
            {numSelected} selected
          </Typography>
        )}
        {numSelected > 0 && (
          <>
            <Tooltip title="Preview">
              <IconButton
                color="secondary"
                aria-label="Preview"
                disableRipple
                onClick={e => props.action(e, 'preview')}
              >
                <IconRemoveRedEye />
              </IconButton>
            </Tooltip>
            {isDraft && superAdmin && (
              <Tooltip title="Publish">
                <IconButton
                  disableRipple
                  aria-label="Publish"
                  onClick={e => props.action(e, 'publish')}
                >
                  <PublishIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </BatchArea>
    </StyledToolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  isDraft: PropTypes.bool.isRequired,
};

export default EnhancedTableToolbar;
