import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';

const config = {
  apiKey: 'AIzaSyCE7eiEK0RhAwlE3-ux-XNg6QmptjFR98Y',
  authDomain: 'app-jdadvising-admin.firebaseapp.com',
  databaseURL: 'https://app-jdadvising-admin.firebaseio.com',
  projectId: 'app-jdadvising-admin',
  storageBucket: 'app-jdadvising-admin.appspot.com',
  messagingSenderId: '140276326742',
  appId: '1:140276326742:web:f838422f3471ee28',
};
// var config = {
//   apiKey: 'AIzaSyCOtX1PWnxf7U3MAfnmdVTLHSsMH2LSl7M',
//   authDomain: 'mbe-book-admin.firebaseapp.com',
//   databaseURL: 'https://mbe-book-admin.firebaseio.com',
//   projectId: 'mbe-book-admin',
//   storageBucket: 'mbe-book-admin.appspot.com',
//   messagingSenderId: '447027049670',
// };
firebase.initializeApp(config);
export default firebase;
