import React from 'react';
import styled from 'styled-components/macro';
import { color, fontWeight } from '../../styles/style';

// import PropTypes from 'prop-types';
// import Typography from '@material-ui/core/Typography';
const HeaderText = styled.div`
  font-size: 2.2rem;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  color: ${color.blueDarkest};
  text-align: right;
  font-weight: ${fontWeight.semiBold};
`;
const HeaderInfo = ({ children, ...others }) => (
  <HeaderText>{children}</HeaderText>
);

HeaderInfo.propTypes = {};
HeaderInfo.defaultProps = {};

export default HeaderInfo;
