import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import LinkButton from '../LinkButton';

import { color, fontWeight } from '../../styles/style';
const FlushButton = styled(LinkButton)`
  &&&& {
    color: ${props => color.primary};
    text-transform: uppercase;
    font-weight: ${props => fontWeight.bold};
    padding-left: 8px;
  }
`;

const FlushLinkButton = ({ ...other }) => <FlushButton {...other} />;

// const FlushLinkButton = () => {
//   return <div>FlushLinkButton</div>;
// };

FlushLinkButton.propTypes = {};

export default FlushLinkButton;
