import React from 'react';
// import PropTypes from 'prop-types';
import MuiCardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
const CardContent = ({ children, ...others }) => (
  <MuiCardContent {...others}>{children}</MuiCardContent>
);

CardContent.propTypes = {};

export default CardContent;
