import styled from 'styled-components/macro';
import { color, fontType } from '../../styles/style';

export const CopyTopic = styled.p`
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: ${fontType.copy};
  color: ${color.white};
  letter-spacing: 0;
  vertical-align: baseline;
  line-height: 1.5;
  ${'' /* margin-bottom: 1rem; */};
`;
export default CopyTopic;
