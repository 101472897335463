import React, { Component } from 'react';
// import PropTypes from 'prop-types';

/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UI LIBS  ***/
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/
import Loading from '../../components/Loading';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import Button from '../../elements/Button';

/*** ACTIONS & APIs ***/
import { addPromo } from '../../store/actions/promoActions';
/*** UTILS & HELPERS ***/
import { color, util, fontType } from '../../styles/style';
/*** SCREENS  ***/

/*** ASSETS  ***/
import PromoImage from '../../assets/images/PromoImage.png';

const EditBannerWrapper = styled.div`
  background-color: ${color.grey};
  border-width: 1px;
  border-color: ${color.blueDarkest};
  padding: ${util.padding};
  border-radius: ${util.radiusSmall};
  font-size: ${fontType.copy};
  color: ${color.blueDarkest};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const EditBannerContainer = styled.div`
  border-color: ${color.blueDarkest};
  padding: 0;
  border-radius: ${util.radiusSmall};
  font-size: ${fontType.copy};
  color: ${color.blueDarkest};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
const EditBannerActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const BannerImageArea = styled.div`
  flex-basis: 20%;
  max-width: 100px;
`;
const BannerContentArea = styled.div`
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
`;

const BannerButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ButtonLabel = styled.div`
  display: flex;
  flex-basis: 30%;
`;
const ButtonUrl = styled.div`
  display: flex;
  flex-basis: 70%;
`;

class NewBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: true,
      title: 'Try some of our other offerings!',
      content:
        'We offer a wide variety of services including our MBE Course, private one-on-one tutoring, MBE One-Sheets, and more.',
      btnLabel: 'Learn More',
      btnUrl: 'https://www.jdadvising.com/mbe-services/',
      type: 'default',
    };
    this.addPromo = this.addPromo.bind(this);
  }
  componentDidMount() {}
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
      isDirty: true,
    });
  };

  addPromo() {
    const { title, content, btnLabel, btnUrl, type } = this.state;
    const uObj = {
      title,
      content,
      btnLabel,
      btnUrl,
      type,
    };

    this.props.addPromo('default', uObj).then(() => {
      console.log(`uObj = ${uObj}`);
    });
    // console.log(`saveFieldValue_${newContent}`);
    // this.props.updateQuestion(level, qbank, qid, uobj);
  }
  render() {
    const { isDirty, title, content, btnLabel, btnUrl } = this.state;
    const { isLoading } = this.props;
    if (isLoading) {
      return (
        <EditBannerWrapper>
          <Loading />
        </EditBannerWrapper>
      );
    }

    return (
      <form>
        <EditBannerWrapper>
          <EditBannerContainer>
            <BannerImageArea>
              <img alt="promo" src={PromoImage} />
            </BannerImageArea>
            <BannerContentArea>
              <FormControl margin="none" fullWidth>
                <InputLabel htmlFor="title">Title</InputLabel>
                <Input
                  type="text"
                  name="title"
                  value={title}
                  id="title"
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="content">Content</InputLabel>
                <Input
                  type="content"
                  name="content"
                  value={content}
                  id="content"
                  multiline
                  onChange={this.handleChange}
                />
              </FormControl>
              <BannerButtonArea>
                <ButtonLabel>
                  <FormControl margin="normal">
                    <InputLabel htmlFor="btnLabel">Button Label</InputLabel>

                    <Input
                      type="btnLabel"
                      name="btnLabel"
                      value={btnLabel}
                      id="btnLabel"
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </ButtonLabel>
                <ButtonUrl>
                  <FormControl margin="normal" fullWidth>
                    <InputLabel htmlFor="btnUrl">URL</InputLabel>

                    <Input
                      type="btnUrl"
                      name="btnUrl"
                      value={btnUrl}
                      id="btnUrl"
                      onChange={this.handleChange}
                    />
                  </FormControl>
                </ButtonUrl>
              </BannerButtonArea>
            </BannerContentArea>
          </EditBannerContainer>
          <EditBannerActions>
            <Button
              color="primary"
              variant="text"
              onClick={this.addPromo}
              disabled={!isDirty}
            >
              SAVE
            </Button>
          </EditBannerActions>
        </EditBannerWrapper>
      </form>
    );
  }
}

// BasicBanner.propTypes = {
//   promo: PropTypes.object,
// };
// PromoSection.default = {
//   version: 'pass',
// };

NewBanner.propTypes = {};
const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.appStatus.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addPromo: (key, uObj) => dispatch(addPromo(key, uObj)),
    // fetchStudyGuideQuestions: sguide =>
    //   dispatch(fetchStudyGuideQuestions(sguide)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBanner);
