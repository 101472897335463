import React, { Component } from 'react';

import Button from '../../elements/Button';
class UserInfo extends Component {
  render() {
    const { user, logout } = this.props;
    return (
      <>
        <p>
          {user.firstName} {user.lastName}
        </p>
        <p>{user.email}</p>
        <p>{user.role}</p>
        <Button onClick={logout}>LOG OUT</Button>
      </>
    );
  }
}

export default UserInfo;
