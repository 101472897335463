import firebase from '../api/firebaseAdmin';
import firebaseStudent from '../api/firebaseStudent';

export const resetStudentView = (action, status) => {
  return async dispatch => {
    await dispatch({
      type: 'RESET_APP_STATUS',
      view: 'student',
      action,
      status,
    });
    // await dispatch({ type: 'STUDENTS_VIEW', view: view, status: status });
    return Promise.resolve();
  };
};

export const setStudent = uobj => {
  return async dispatch => {
    const { uid } = uobj;
    await dispatch({
      type: 'CHANGE_APP_DATA',
      loading: false,
      data: { currentId: uid },
    });
  };
};

export const createNewStudent = newUser => {
  return async dispatch => {
    const view = 'student';
    const action = 'new';
    await dispatch({
      type: 'SET_APP_STATUS',
      view,
      action,
      status: 'new-student',
      loading: true,
      error: null,
      data: {},
    });
    const createNewStudent = firebase
      .functions()
      .httpsCallable('createNewStudent');
    return createNewStudent(newUser).then(() => {
      dispatch({
        type: 'SET_APP_STATUS',
        view,
        action,
        status: 'email-sent',
        loading: false,
        error: null,
        data: {},
      });
    });
  };
};
export const removeStudentUser = uid => {
  return async dispatch => {
    const view = 'student';
    await dispatch({
      type: 'SET_APP_STATUS',
      view,
      action: 'remove',
      status: 'loading',
      loading: true,
      error: null,
      data: {},
    });

    const removeStudent = firebase.functions().httpsCallable('removeStudent');
    const result = await removeStudent(uid);
    if (result.success) {
      //console.log(`sucess`);
      await dispatch({
        type: 'SET_APP_STATUS',
        view,
        action: 'remove',
        status: 'done',
        loading: false,
        error: null,
        data: {},
      });
      // await dispatch({
      //   type: 'STUDENTS_VIEW',
      //   view: 'remove',
      //   status: 'done',
      // });
    }
  };
};
export const sendOutNewUsuerEmail = email => {
  return async dispatch => {
    try {
      await firebaseStudent.auth().sendPasswordResetEmail(email);
      dispatch({
        type: 'SET_APP_STATUS',
        view: 'student',
        action: 'new-user-email',
        status: 'email-sent',
        loading: false,
        error: null,
        data: {},
      });
      // dispatch({ type: 'STUDENT_EMAIL_SENT', status: 'email-sent' });
      return Promise.resolve();
    } catch (err) {
      dispatch({
        type: 'SET_APP_STATUS',
        view: 'student',
        action: 'new-user-email',
        status: 'email-sent',
        loading: false,
        error: err,
        data: {},
      });
    }
  };
};

export const updateStudent = sobj => {
  return async dispatch => {
    const view = 'student';
    await dispatch({
      type: 'SET_APP_STATUS',
      view,
      action: 'update',
      status: 'loading',
      loading: true,
      error: null,
      data: {},
    });
    const updateStudentRecord = firebase
      .functions()
      .httpsCallable('updateStudentRecord');

    try {
      const result = await updateStudentRecord(sobj);
      //console.log(`updateStudentRecord result=${JSON.stringify(result)}`);

      if (result.data.success) {
        //console.log(`sucess`);
        await dispatch({
          type: 'SET_APP_STATUS',
          view,
          action: 'update',
          status: 'done',
          loading: false,
          error: null,
          data: {},
        });
      }
    } catch (err) {
      dispatch({
        type: 'SET_APP_STATUS',
        view,
        action: 'update',
        status: 'error',
        loading: false,
        error: err,
        data: {},
      });
    }
  };
};
