import React from 'react';
/*** DATA, STORE and STATE  ***/
import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
/*** ROUTER  ***/

/*** STYLE  ***/
// import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/
import Loading from '../../components/Loading';
import EnhancedTable from '../../components/EnhancedTable';
/*** ELEMENTS  ***/
import TextEmptyState from '../../elements/TextEmptyState';
/*** ACTIONS & APIs ***/

/*** UTILS & HELPERS ***/

/*** SCREENS  ***/

// const styles = theme => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: theme.palette.background.paper,
//   },
//   tabsRoot: {
//     borderBottom: '1px solid #e8e8e8',
//   },
//   tabsIndicator: {
//     backgroundColor: '#1890ff',
//   },
//   tabRoot: {
//     textTransform: 'initial',
//     minWidth: 72,
//     '&:hover': {
//       color: '#40a9ff',
//       opacity: 1,
//     },
//     '&$tabSelected': {
//       color: '#1890ff',
//       fontWeight: theme.typography.fontWeightMedium,
//     },
//     '&:focus': {
//       color: '#40a9ff',
//     },
//   },
//   tabSelected: {},
//   typography: {
//     padding: theme.spacing.unit * 3,
//   },
//   emptyState: {
//     textAlign: 'center',
//     padding: '2rem',
//     color: '#BFCCD9',
//     fontWeight: 700,
//   },
// });
class QuestionsTable extends React.Component {
  state = {
    tabIndex: this.props.isDraft ? 0 : 1,
    // section: this.props.section,
    // level: this.props.level,
    // qbank: this.props.qbank,
    // dbref: `${this.props.level}/${this.props.qbank}`,
  };

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };
  render() {
    const { tabIndex } = this.state;
    const {
      // classes,
      questions,
      pubQuestions,
      section,
      level,
      qbank,
      superAdmin,
    } = this.props;

    return (
      <>
        <Tabs value={tabIndex} onChange={this.handleChange}>
          <Tab disableRipple label="DRAFTS" />
          <Tab disableRipple label="PUBLISHED" />
        </Tabs>

        {tabIndex === 0 &&
          (!isLoaded(questions) ? (
            <Loading />
          ) : isEmpty(questions) ? (
            <TextEmptyState>No Questions</TextEmptyState>
          ) : (
            <EnhancedTable
              data={questions}
              section={section}
              level={level}
              qbank={qbank}
              isDraft={true}
              superAdmin={superAdmin}
            />
          ))}
        {tabIndex === 1 &&
          (!isLoaded(pubQuestions) ? (
            <Loading />
          ) : isEmpty(pubQuestions) ? (
            <TextEmptyState>No Published Questions</TextEmptyState>
          ) : (
            <EnhancedTable
              data={pubQuestions}
              section={section}
              level={level}
              qbank={qbank}
              isDraft={false}
              superAdmin={superAdmin}
            />
          ))}
      </>
    );
  }
}
// const mapStateToProps = (state, ownProps) => {
//   if (!state.questionStatus) return;
//   return {
//     section: state.questionStatus.section,
//     level: state.questionStatus.level,
//     qbank: state.questionStatus.qbank,
//     status: state.questionStatus.status,
//     dbref: `${state.questionStatus.level}/${state.questionStatus.qbank}`,
//     isDraft: state.questionStatus.isDraft,
//     superAdmin: state.firebase.profile.isSuper
//       ? state.firebase.profile.isSuper
//       : false,
//   };
// };
//export default connect(mapStateToProps)(QuestionsTable);

export default compose(
  // connect(mapStateToProps),
  firebaseConnect(props => [
    {
      //path: `${props.level}/${props.qbank}`,
      path: get(props, 'qpath'),
      // path: get(props, 'dbref'),
      storeAs: 'draftQs',
      queryParams: ['orderByChild=published', 'equalTo=false'],
    },
    {
      // path: get(props, 'dbref'),
      //path: `${props.level}/${props.qbank}`,
      path: get(props, 'qpath'),
      storeAs: 'pubQs',
      queryParams: ['orderByChild=published', 'equalTo=true'],
    },
  ]),
  connect(({ firebase }, props) => ({
    // questions: state.firebase.ordered[get(props, 'dbref')],
    questions: get(firebase, 'ordered.draftQs'),
    pubQuestions: get(firebase, 'ordered.pubQs'),
  }))
)(QuestionsTable);
