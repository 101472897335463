const initState = [];

const studentReducer = (state = initState, action) => {
  switch (action.type) {
    case 'STUDENT_LIST_SUCCESS':
      console.log('STUDENT_LIST_SUCCESS');
      return action.payload;
    case 'STUDENT_LIST_FAILURE':
      console.log('STUDENT_LIST_FAILURE');
      return action.payload;
    default:
      return state;
  }
};

export default studentReducer;
