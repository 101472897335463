import firebase from '../api/firebaseAdmin';
export const setUser = qObj => {
  return dispatch => {
    const { uid, section } = qObj;
    let sbank;
    if (section === 'new') {
      sbank = 'users';
    } else {
      sbank = `students-${section}`;
    }
    console.log(`uid=${uid} sbank=${sbank}`);
    dispatch({
      type: 'SET_USER',
      currentId: uid,
      sbank: sbank,
      status: 'loading',
    });
    return Promise.resolve();

    // dispatch({ type: 'STUDY_GUIDE_STATUS', payload: 'review' });
  };
};

export const removeUser = uid => {
  return dispatch => {
    const firestore = firebase.firestore();
    return firestore
      .collection('users')
      .doc(uid)
      .delete();
  };
};
export const resetUserView = (view, status) => {
  return async dispatch => {
    await dispatch({ type: 'USERS_VIEW', view: view, status: status });
    return Promise.resolve();
  };
};
export const createNewUser = newUser => {
  return async dispatch => {
    await dispatch({ type: 'USERS_VIEW', view: 'new', status: 'loading' });
    // let batch = firestore.batch();
    const firestore = firebase.firestore();
    const addUserToAdmin = firebase.functions().httpsCallable('addUserToAdmin');
    addUserToAdmin(newUser).then(function(result) {
      console.log(`sucess???????? => ${JSON.stringify(result)}`);
      const { data } = result;
      const isSuccess = data.success;
      console.log(`isSuccess???????? => ${isSuccess}`);
      if (!isSuccess) {
        console.log(`data.error => ${JSON.stringify(data.error.errorInfo)}`);
        const { error } = data;
        // console.log(`result.error => ${result.data.error.message}`);
        dispatch({
          type: 'USER_ERROR',
          err: error.errorInfo,
        });
      } else {
        console.log(`addUserToAdmin uid => ${data.data.uid}`);
        const userId = data.data.uid;
        const userRef = firestore.collection('users').doc(userId);
        userRef
          .set({
            uid: userId,
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            initials: newUser.firstName[0] + newUser.lastName[0],
            role: 'admin',
            isSuper: false,
            status: 'current',
          })
          .then(() => {
            dispatch({
              type: 'NEW_USER_SUCCESS',
              currentId: userId,
              status: 'success',
            });
            return dispatch(_sendOutNewUserEmail(newUser.email));
          })
          .catch(err => {
            dispatch({ type: 'USER_ERROR', err: err });
          });
      }
    });
  };
};
export const _sendOutNewUserEmail = email => {
  return dispatch => {
    //const firebase = getFirebase();
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: 'USER_EMAIL_SENT', status: 'email-sent' });
        return Promise.resolve();
        // return Promise.resolve();
      })
      .catch(err => {
        dispatch({ type: 'USER_ERROR', err });
      });
  };
};

export const updateUser = sobj => {
  return dispatch => {
    const firestore = firebase.firestore();
    const { uid } = sobj;
    return firestore
      .collection('users')
      .doc(uid)
      .update(sobj)
      .then(() => {
        dispatch({
          type: 'UPDATE_USER_SUCCESS',
          currentId: uid,
          status: 'success',
        });
        return Promise.resolve();
      })
      .catch(err => {
        dispatch({ type: 'STUDENT_ERROR', err });
      });
  };
};
