import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
/*** ROUTER  ***/
import { withRouter } from 'react-router-dom';
/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UI LIBS  ***/
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
/*** CONTAINERS  ***/
import { MainScreen, MainFooter } from '../../../containers/Main';
import { FormControl } from '../../../containers/Form';
/*** GLOBAL COMPONENTS  ***/
import CardFieldNew from '../../../components/CardFieldNew';
import CardSelectNew from '../../../components/CardSelectNew';
import CardSelectClassification from '../../../components/CardSelectClassification';
// import ToggleBtnGroup from '../../../components/ToggleBtnGroup';
import { ToggleBtn, ToggleBtnGroup } from '../../../components/ToggleBtn';
import FeedbackButton from '../../../components/FeedbackButton';

/*** LOCAL COMPONENTS  ***/
/*** ELEMENTS  ***/
import FlushLinkButton from '../../../elements/FlushLinkButton';
// import Button from '../../../elements/Button';
import SimpleBtn from '../../../elements/SimpleBtn';
/*** ACTIONS & APIs ***/
import { saveQuestion } from '../../../store/actions/questionActions';
/*** UTILS & HELPERS ***/
// import { color } from '../../../styles/style';
/*** SCREENS  ***/

// const FeedbackButton = styled(Button)`
//   &&&&&& {
//     background-color: transparent;
//     color: ${props => color.blueDarkest};
//     border-color: ${props => color.blueLight};
//     border-width: 1px;
//     border-radius: 3px;
//     border-style: dashed;
//     height: 80px;
//     &:hover {
//       border-color: ${props => color.primary};
//       background-color: transparent;
//       color: ${props => color.primary};
//     }
//   }
// `;
// const ToggleBtnGroup = styled(ToggleButtonGroup)`
//   &&&&&& {
//     display: flex;
//     justify-content: space-between;
//     box-shadow: none;
//   }
// `;
//
// const ToggleBtn = styled(ToggleButton)`
//   &&&&&& {
//     color: ${props => color.blueDarkest};
//     border-color: ${props => color.blueLightest};
//     border-width: 1px;
//     height: 100%;
//     width: 100%;
//     border-style: solid;
//     border-radius: 3px;
//     padding: 10px;
//     margin: 10px;
//     line-height: 2rem;
//     font-size: 2rem;
//     transition: all 200ms;
//     &:hover {
//       border-color: ${props => color.primary};
//       background-color: transparent;
//       color: ${props => color.primary};
//     }
//   }
// `;
// const styles = theme => ({
//   container: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   formControl: {
//     margin: theme.spacing.unit,
//     minWidth: 200,
//     maxWidth: 1000 - 2 * theme.spacing.unit,
//   },
//   textField: {
//     marginLeft: theme.spacing.unit,
//     marginRight: theme.spacing.unit,
//   },
//   dense: {
//     marginTop: 16,
//   },
//   menu: {
//     width: 200,
//   },
//   toggleContainer: {
//     height: 56,
//     padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-around',
//     margin: `${theme.spacing.unit}px 0`,
//     background: theme.palette.background.default,
//   },
// });

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;

const FooterLeft = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;
`;
const FooterRight = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  align-items: center;
`;

class NewQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: '',
      type: props.section === 'free' ? '' : props.section,
      testCode: props.section === 'study' ? 'guide' : '',
      isSaved: false,
      qbank: null,
      level: '',
      published: false,
      subject: '',
      subjectCode: '',
      classification: '',
      question: '',
      A: '',
      B: '',
      C: '',
      D: '',
      correctAnswer: '',
      correctFeedback: '',
      feedback1: '',
      feedback2: '',
      feedback3: '',
      showFeedback2: false,
      showFeedback3: false,
    };
    this.goBackScreen = this.goBackScreen.bind(this);
    this.preSave = this.preSave.bind(this);
    this.newQuestionCancel = this.newQuestionCancel.bind(this);
    this.afterSaveEdit = this.afterSaveEdit.bind(this);
    this.afterSavePreview = this.afterSavePreview.bind(this);
  }
  afterSavePreview(e) {
    const { section } = this.props;
    const { currentSection } = this.state;
    console.log(`section-${section} ____ currentSection-${currentSection}`);
    this.props.history.push(`/admin/${currentSection}/preview`);
    e.preventDefault();
  }
  afterSaveEdit(e) {
    const { section } = this.props;
    const { currentSection } = this.state;
    console.log(`section-${section} ____ currentSection-${currentSection}`);
    this.props.history.push(`/admin/${currentSection}/edit`);
    e.preventDefault();
  }
  preSave(e) {
    const { type, testCode, subjectCode, classification } = this.state;
    const { section, saveQuestion } = this.props;
    const level = section === 'free' ? 'free' : 'paid';
    const bank = type === 'study' ? subjectCode.toLowerCase() : testCode;
    const qbank = `${type}-${bank}`;
    console.log(`subjectCode-${subjectCode}`);
    console.log(`qbank=${qbank}`);
    const sobj = this.state;
    const qObj = {
      ...sobj,
      section: section,
      subjectCode: subjectCode,
      classification: [classification],
      qbank,
      testCode,
      level,
      type,
    };
    saveQuestion(level, qbank, qObj).then(() => {
      this.props.history.push(`/admin/${section}/edit`);
    });

    e.preventDefault();
  }
  goBackScreen(e) {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);

    e.preventDefault();
  }
  newQuestionCancel(e) {
    const { section } = this.props;
    this.props.history.push(`/admin/${section}`);
    e.preventDefault();
  }

  handleSelectChange = (event, correctAnswer) =>
    this.setState({ correctAnswer });

  handleListChange = name => event => {
    console.log(`name=${name} event=${event.target.value}`);
    this.setState({
      [name]: event.target.value,
    });
  };
  handleSubjectChange = name => obj => {
    console.log(`name=${name} event=${obj.subject}`);
    this.setState({
      subject: obj.subject,
      subjectCode: obj.subjectCode,
      classification: obj.classification,
    });
  };
  handleCardAction = name => event => {
    console.log(`name=${name} event=${event}`);
    this.setState({
      [name]: event,
    });
  };

  handleFeedbackState = name => event => {
    console.log(`name=${name} event=${event}`);
    this.setState({
      [name]: true,
    });
  };
  render() {
    const { section } = this.props;
    const {
      type,

      subject,
      subjectCode,
      classification,
      question,
      A,
      B,
      C,
      D,
      correctAnswer,
      correctFeedback,
      feedback1,
      feedback2,
      feedback3,
      showFeedback2,
      showFeedback3,
      isSaved,
    } = this.state;

    const isInvalid =
      subject === '' ||
      subjectCode === '' ||
      classification === '' ||
      question === '' ||
      A === '' ||
      B === '' ||
      C === '' ||
      D === '' ||
      correctAnswer === '' ||
      correctFeedback === '' ||
      feedback1 === '';
    return (
      <Screen>
        <MainScreen
          title="New Question"
          leftSideItem={
            <FlushLinkButton onClick={this.goBackScreen}>
              <ArrowBackIcon />
              BACK
            </FlushLinkButton>
          }
          spacing={24}
        >
          {section !== 'study' && (
            <CardSelectNew>
              <Grid container justify="flex-start" alignItems="center">
                {section === 'free' && (
                  <Grid item>
                    <FormControl>
                      <InputLabel shrink={true} htmlFor="select-question-type">
                        Question Type
                      </InputLabel>
                      <Select
                        value={this.state.type}
                        onChange={this.handleListChange('type')}
                      >
                        <MenuItem value="">Select Type</MenuItem>
                        <MenuItem value="test">test</MenuItem>
                        <MenuItem value="study">study</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {type === 'test' && (
                  <Grid item>
                    <FormControl>
                      <InputLabel shrink={true} htmlFor="select-question-type">
                        Question Type
                      </InputLabel>
                      <Select
                        value={this.state.testCode}
                        onChange={this.handleListChange('testCode')}
                      >
                        <MenuItem value="">Select Test Code</MenuItem>
                        <MenuItem value="ope01">Practice Test 1</MenuItem>
                        <MenuItem value="ope02">Practice Test 2</MenuItem>
                        <MenuItem value="ope03">Practice Test 3</MenuItem>
                        <MenuItem value="ope04">Practice Test 4</MenuItem>
                        <MenuItem value="final01">Final Test 1</MenuItem>
                        <MenuItem value="final02">Final Test 2</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </CardSelectNew>
          )}

          <CardSelectClassification
            label="Subject and Classification"
            isEditMode={true}
            subjectCode={subjectCode}
            classification={classification}
            fieldChange={this.handleSubjectChange('subjectClassif')}
          />

          <CardSelectNew label="Correct Answer" content={correctAnswer}>
            <ToggleBtnGroup
              value={correctAnswer}
              exclusive
              onChange={this.handleSelectChange}
            >
              <ToggleBtn selected={correctAnswer === 'A'} value="A">
                A
              </ToggleBtn>
              <ToggleBtn selected={correctAnswer === 'B'} value="B">
                B
              </ToggleBtn>
              <ToggleBtn selected={correctAnswer === 'C'} value="C">
                C
              </ToggleBtn>
              <ToggleBtn selected={correctAnswer === 'D'} value="D">
                D
              </ToggleBtn>
            </ToggleBtnGroup>
          </CardSelectNew>

          <CardFieldNew
            label="Question"
            name="question"
            isMultiline={true}
            cardAction={this.handleCardAction('question')}
            content={question}
          />

          <CardFieldNew
            label="Answer A"
            name="A"
            cardAction={this.handleCardAction('A')}
            content={A}
          />

          <CardFieldNew
            name="B"
            label="Answer B"
            cardAction={this.handleCardAction('B')}
            content={B}
          />
          <CardFieldNew
            name="C"
            label="Answer C"
            cardAction={this.handleCardAction('C')}
            content={C}
          />
          <CardFieldNew
            name="D"
            label="Answer D"
            cardAction={this.handleCardAction('D')}
            content={D}
          />

          {/* <CardFieldNew
            label="Correct Answer"
            name="correctAnswer"
            cardAction={this.handleCardAction('correctAnswer')}
            content={correctAnswer}
          /> */}
          <CardFieldNew
            name="correctFeedback"
            label="Correct Feedback"
            isMultiline={true}
            cardAction={this.handleCardAction('correctFeedback')}
            content={correctFeedback}
          />
          <CardFieldNew
            name="feedback1"
            label="Feedback 1"
            isMultiline={true}
            cardAction={this.handleCardAction('feedback1')}
            content={feedback1}
          />
          {showFeedback2 ? (
            <CardFieldNew
              name="feedback2"
              label="Feedback 2"
              isMultiline={true}
              cardAction={this.handleCardAction('feedback2')}
              content={feedback2}
            />
          ) : (
            <Grid item xs={12}>
              <FeedbackButton
                variant="contained"
                fullWidth
                color="default"
                onClick={this.handleFeedbackState('showFeedback2')}
              >
                <AddIcon />
                Add Feedback 2
              </FeedbackButton>
            </Grid>
          )}

          {showFeedback3 && (
            <CardFieldNew
              name="feedback3"
              label="Feedback 3"
              isMultiline={true}
              cardAction={this.handleCardAction('feedback3')}
              content={feedback3}
            />
          )}
          {showFeedback2 === true &&
            showFeedback3 === false && (
              <Grid item xs={12}>
                <FeedbackButton
                  variant="contained"
                  fullWidth
                  color="default"
                  onClick={this.handleFeedbackState('showFeedback3')}
                >
                  <AddIcon />
                  Add Feedback 3
                </FeedbackButton>
              </Grid>
            )}
        </MainScreen>
        <MainFooter>
          {isSaved ? (
            <>
              <FooterLeft>
                <SimpleBtn
                  variant="contained"
                  type="remove"
                  onClick={this.afterSaveRemove}
                >
                  DELETE
                </SimpleBtn>
              </FooterLeft>
              <FooterRight>
                <SimpleBtn
                  variant="contained"
                  type="publish"
                  onClick={this.afterSaveEdit}
                >
                  EDIT
                </SimpleBtn>
                <SimpleBtn
                  variant="contained"
                  type="publish"
                  onClick={this.afterSavePreview}
                >
                  PREVIEW
                </SimpleBtn>
              </FooterRight>
            </>
          ) : (
            <>
              <SimpleBtn
                variant="outlined"
                type="default"
                onClick={this.newQuestionCancel}
              >
                CANCEL
              </SimpleBtn>
              <SimpleBtn
                disabled={isInvalid}
                variant="contained"
                type="primary"
                onClick={this.preSave}
              >
                SAVE
              </SimpleBtn>
            </>
          )}
        </MainFooter>
      </Screen>
    );
  }
}

NewQuestion.propTypes = {
  section: PropTypes.string,
  qbank: PropTypes.string,
  status: PropTypes.string,
  qid: PropTypes.string,
  view: PropTypes.string,
};
const mapStateToProps = (state, ownProps) => {
  return {
    qbank: state.questionStatus.qbank,
    status: state.questionStatus.status,
    qid: state.questionStatus.qid,
    section: state.questionStatus.section,
    view: state.questionStatus.view,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveQuestion: (level, qbank, qObj) =>
      dispatch(saveQuestion(level, qbank, qObj)),
  };
};
export default compose(
  withRouter,
  // withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewQuestion);
