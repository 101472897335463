import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';

//import { get, some } from 'lodash';
/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components/macro';
//import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
//import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
//import Input from '@material-ui/core/Input';
//import InputLabel from '@material-ui/core/InputLabel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
/*** CONTAINERS  ***/
import { MainScreen } from '../../../../containers/Main';
import { Card, CardContent } from '../../../../containers/Card';
/*** GLOBAL COMPONENTS  ***/
import AvatarCard from '../../../../components/AvatarCard';
import Loading from '../../../../components/Loading';
/*** LOCAL COMPONENTS  ***/
/*** ELEMENTS  ***/
//import SimpleBtn from '../../../../elements/SimpleBtn';
// import SimpleBtn from '../../../../elements/SimpleBtn';
import FlushLinkButton from '../../../../elements/FlushLinkButton';
/*** ACTIONS  and APIs ***/
import {
  //fetchStudentById,
  sendOutNewUsuerEmail,
} from '../../../../store/actions/studentActions';
//import firebaseStudent from '../../../../api/firebaseStudent';
//import { mbeFS } from '../../../../store/api/fb';
/*** UTILS & HELPERS ***/
import { color, fontWeight } from '../../../../styles/style';
import { formatDateTime } from '../../../../utils/formatDateTime';
/*** SPECIAL DATA  ***/
//import StatsRowFlat from '../../../../data/StatsRowFlat';
// import StatsRowCard from '../../../../data/StatsRowCard';
/*** SCREENS  ***/

function TabContainer(props) {
  return <>{props.children}</>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  emptyState: {
    textAlign: 'center',
    padding: '2rem',
    color: '#BFCCD9',
    fontWeight: 700,
  },
  tableCell: {
    color: color.dark,
    fontWeight: fontWeight.semiBold,
  },
  tableHeadCell: {
    color: color.blueDarkest,
    fontWeight: 700,
  },
  moreVert: {
    textAlign: 'right',
    color: color.blueDarkest,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: color.primary,
    '&:hover': {
      backgroundColor: color.primaryDarker,
    },
  },

  buttonProgress: {
    color: color.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;
class View extends Component {
  constructor(props) {
    super(props);
    this.goBackScreen = this.goBackScreen.bind(this);
  }
  state = {
    tabIndex: 0,
    key: null,
    isLoading: true,
    student: null,
    studentData: null,
    btnLoading: false,
    btnSuccess: false,
    isSuspended: false,
    isUpgraded: false,
  };

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  goBackScreen(e) {
    const { history } = this.props;
    history.push(`/admin/users`);
    e.preventDefault();
  }

  render() {
    const { classes, userView } = this.props;
    const { tabIndex } = this.state;

    return (
      <Screen>
        <MainScreen
          title="User Profile"
          leftSideItem={
            <FlushLinkButton onClick={this.goBackScreen}>
              <ArrowBackIcon />
              BACK
            </FlushLinkButton>
          }
          spacing={24}
        >
          {!isLoaded(userView) ? (
            <Loading />
          ) : (
            <>
              <Grid item xs={4}>
                <Grid container spacing={24} direction={'column'}>
                  <Grid item>
                    <AvatarCard user={userView} />
                  </Grid>
                  <Grid item />
                </Grid>
              </Grid>
              <Grid item xs>
                <Card>
                  <Tabs
                    value={tabIndex}
                    onChange={this.handleChange}
                    classes={{
                      root: classes.tabsRoot,
                      indicator: classes.tabsIndicator,
                    }}
                  >
                    <Tab
                      disableRipple
                      classes={{
                        root: classes.tabRoot,
                        selected: classes.tabSelected,
                      }}
                      label="PROFILE"
                    />
                  </Tabs>

                  {tabIndex === 0 && (
                    <TabContainer>
                      <CardContent>
                        <Grid container spacing={24}>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <TextField
                                label="First Name"
                                // autoComplete="Jerry"
                                // autoFocus
                                value={userView.firstName}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <TextField
                                label="Last Name"
                                // autoComplete="Jerry"
                                // autoFocus
                                value={userView.lastName}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container spacing={24}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <TextField
                                label="Email"
                                value={userView.email}
                                InputProps={{
                                  readOnly: true,
                                }}
                                // autoComplete="Jerry"
                                // autoFocus
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <TextField
                                label="Role"
                                value={userView.role}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <TextField
                                label="Start Date"
                                value={formatDateTime(userView.startDate)}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </FormControl>
                          </Grid>
                          {/* <Grid item xs={6}>
                            <FormControl fullWidth>
                              <TextField
                                label="Last Updated"
                                value={formatDateTime(userView.updateDate)}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </FormControl>
                          </Grid> */}
                        </Grid>
                      </CardContent>
                      <Divider />
                      {/* <CardContent>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled
                          // disabled={!isDirty}
                        >
                          Update User
                        </Button>
                      </CardContent> */}
                    </TabContainer>
                  )}
                </Card>
              </Grid>
            </>
          )}
        </MainScreen>
      </Screen>
    );
  }
}

View.propTypes = {
  currentId: PropTypes.string.isRequired,
};
const mapStateToProps = state => {
  return {
    currentId: state.userStatus.currentId,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    //fetchStudentById: uid => dispatch(fetchStudentById(uid)),
    sendOutNewUsuerEmail: email => dispatch(sendOutNewUsuerEmail(email)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(props => [
    {
      collection: 'users',
      doc: props.currentId,
      storeAs: 'userView',
    },
  ]),
  connect(({ firestore: { data } }, props) => ({
    userView: data.users && data.users[props.currentId],
  })),
  withStyles(styles)
)(View);
