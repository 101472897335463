import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
/*** ROUTER  ***/
import { Redirect } from 'react-router-dom';
/*** STYLE  ***/
/*** UI LIBS  ***/
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
// import CtaLanding from '../../../elements/CtaLanding';
/*** ACTIONS & APIs ***/
import { signIn, signInGoogle } from '../../../store/actions/authActions';
/*** UTILS & HELPERS ***/
// import { color, util, fontType, fontWeight } from '../../../styles/style';
/*** SCREENS  ***/

// import BgImg from '../../../assets/images/landing_image.png';
// import LogoTall from '../../../assets/logos/JD-Advising_logo-Tall.png';

// const SignInForm = styled.div`
//   background: ${color.grey};
//   padding: 5rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   height: 100vh;
//   max-width: 500px;
//   width: 100%;
//   margin: auto;
//   & img {
//     top: -7rem;
//     position: relative;
//   }
// `;

// const SignUpCta = styled.div`
//   font-size: ${fontType.copy};
//   font-weight: ${fontWeight.semiBold};
//   margin: 1.5rem 0;
//   text-align: center;
// `;

class SignIn extends Component {
  state = {
    email: '',
    password: '',
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };
  handleSignInClick = e => {
    e.preventDefault();
    this.props.signInGoogle();
  };
  render() {
    const { authError, auth, role } = this.props;
    //console.log(`auth.uid = ${auth.uid}`);
    if (auth.uid && role !== undefined)
      return (
        <Redirect
          to={{
            pathname: `/${role}`,
            state: { role: role },
          }}
        />
      );

    return (
      <form onSubmit={this.handleSubmit}>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <FormControl margin="normal" required fullWidth>
          <TextField
            error={authError ? true : false}
            label="Email Address"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange}
            helperText={authError ? authError : null}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <TextField
            error={authError ? true : false}
            label="Password"
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.handleChange}
            helperText={authError ? authError : null}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button
            type="submit"
            variant="contained"
            fullWidth={true}
            color="primary"
          >
            Sign in
          </Button>
          {
            // <div className="center red-text">
            //   {authError ? <p>{authError}</p> : null}
            // </div>
          }
          {/* <Button
            variant="contained"
            fullWidth={true}
            color="primary"
            onClick={this.handleSignInClick}
          >
            Sign in with Google
          </Button> */}
          {/* <CtaLanding>
            Dont have an account?{' '}
            <Link
              to={{
                pathname: '/sign-up',

                state: { isSignUp: true },
              }}
            >
              Sign up.
            </Link>
          </CtaLanding> */}
        </FormControl>
      </form>
    );
  }
}
SignIn.propTypes = {
  auth: PropTypes.object,
  authError: PropTypes.string,
  role: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
    role: state.firebase.profile.role,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds)),
    signInGoogle: () => dispatch(signInGoogle()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
