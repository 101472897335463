import React from 'react';
import PropTypes from 'prop-types';
/*** STYLE  ***/
import styled from 'styled-components/macro';

/*** UTILS & HELPERS ***/
import { color, util, fontWeight, fontSize } from '../../../../../styles/style';

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0;
  background: ${color.white};
  color: ${color.blueDarkest};
  box-shadow: 0 2px 7px 0 ${color.blueLightest};
  border-radius: ${util.radiusSmall};
  cursor: pointer;
  overflow: hidden;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
  &:hover {
    box-shadow: 0 5px 20px 0 ${color.blueLightest};
  }
`;
const CardSuccess = styled(Card)`
  background: ${color.secondary};
  color: ${color.white};
`;
// const CardSelected = styled(Card)`
//   background: ${color.primary};
//   color: ${color.white};
// `;
// const CardWrong = styled(Card)`
//   background: ${color.redLighter};
//   color: ${color.redDark};
// `;

const AnswerLetter = styled.div`
  padding: 2rem 2.5rem;
  font-size: ${fontSize.answerLetter};
  font-weight: ${fontWeight.semiBold};
  color: ${color.blueDarkest};
  box-shadow: 0 1px 10px -5px ${color.blueDarkest};
  min-width: 110px;
  text-align: center;
`;

const AnswerLetterSuccess = styled(AnswerLetter)`
  background: ${color.secondaryDark};
  color: ${color.white};
`;
// const AnswerLetterSelected = styled(AnswerLetter)`
//   background: ${color.primaryDark};
//   color: ${color.white};
// `;
// const AnswerLetterWrong = styled(AnswerLetter)`
//   background: ${color.redLight};
//   color: ${color.redDark};
// `;

const AnswerCopy = styled.div`
  padding: 0 2rem;
  font-size: ${fontSize.answerCopy};
  font-weight: ${fontWeight.semiBold};
  color: ${color.blueDarkest};
  letter-spacing: 0;
`;
const AnswerCopySuccess = styled(AnswerCopy)`
  color: ${color.white};
`;
// const AnswerCopySelected = styled(AnswerCopy)`
//   color: ${color.white};
// `;
// const AnswerCopyWrong = styled(AnswerCopy)`
//   color: ${color.redDark};
// `;

const AnswerItem = props => {
  if (props.isAnswer) {
    return (
      <CardSuccess>
        <AnswerLetterSuccess>{props.itemLetter}</AnswerLetterSuccess>
        <AnswerCopySuccess
          dangerouslySetInnerHTML={{
            __html: props.itemCopy,
          }}
        />
      </CardSuccess>
    );
  }
  return (
    <Card>
      <AnswerLetter>{props.itemLetter}</AnswerLetter>
      <AnswerCopy
        dangerouslySetInnerHTML={{
          __html: props.itemCopy,
        }}
      />
    </Card>
  );
};
AnswerItem.propTypes = {
  itemLetter: PropTypes.string.isRequired,
  itemCopy: PropTypes.string.isRequired,
  isAnswer: PropTypes.bool.isRequired,
};

export default AnswerItem;
