import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
/*** ROUTER  ***/
import { Switch, Route } from 'react-router-dom';
/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UI LIBS  ***/
/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/
import NavAppBar from '../../components/NavAppBar';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import NavBtn from '../../elements/NavBtn';

/*** ACTIONS & APIs ***/

/*** UTILS & HELPERS ***/
// import { color } from '../../styles/style';
/*** SCREENS  ***/
import Dashboard from './Dashboard';
import Students from './Students';
import Study from './Study';
import Test from './Test';
import Free from './Free';
import Profile from './Profile';
import Users from './Users';
import Preview from './Preview';
import EditQuestion from './EditQuestion';
import NewQuestion from './NewQuestion';

const Container = styled.div`
  max-height: 100vh;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
`;

class AdminPathway extends Component {
  render() {
    // const { auth } = this.state;
    const { match, auth } = this.props;

    return (
      <>
        <NavAppBar auth={auth}>
          <NavBtn exact to={`${match.url}`}>
            DASHBOARD
          </NavBtn>
          <NavBtn to={`${match.url}/students`}>STUDENTS</NavBtn>
          <NavBtn to={`${match.url}/study`}>STUDY</NavBtn>
          <NavBtn to={`${match.url}/test`}>TEST</NavBtn>
          <NavBtn to={`${match.url}/free`}>TRIAL</NavBtn>
        </NavAppBar>
        <Container>
          <Switch>
            <Route path={`${match.url}/students`} component={Students} />
            <Route path={`${match.url}/users`} component={Users} />
            <Route path={`${match.url}/study/preview`} component={Preview} />
            <Route path={`${match.url}/study/edit`} component={EditQuestion} />
            <Route path={`${match.url}/study/new`} component={NewQuestion} />
            <Route path={`${match.url}/study`} component={Study} />

            <Route path={`${match.url}/test/new`} component={NewQuestion} />
            <Route path={`${match.url}/test/preview`} component={Preview} />
            <Route path={`${match.url}/test/edit`} component={EditQuestion} />
            <Route path={`${match.url}/test`} component={Test} />

            <Route path={`${match.url}/free/new`} component={NewQuestion} />
            <Route path={`${match.url}/free/preview`} component={Preview} />
            <Route path={`${match.url}/free/edit`} component={EditQuestion} />
            <Route path={`${match.url}/free`} component={Free} />

            <Route path={`${match.url}/profile`} component={Profile} />
            <Route path={`${match.url}`} component={Dashboard} />
          </Switch>
        </Container>
      </>
    );
  }
}
AdminPathway.propTypes = {
  auth: PropTypes.object,
  role: PropTypes.string,
};
const mapStateToProps = state => {
  return {
    // authError: state.auth.authError,
    auth: state.firebase.auth,
    role: state.firebase.profile.role,
  };
};

export default connect(mapStateToProps)(AdminPathway);
