import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import MuiSelect from '@material-ui/core/Select';
const styles = theme => ({
  selectEmpty: {
    fontSize: 18,
    fontWeight: 600,
  },
});
const Select = ({ children, classes, ...others }) => {
  return (
    <MuiSelect className={classes.selectEmpty} {...others}>
      {children}
    </MuiSelect>
  );
};

Select.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Select);
