export const setAppStatus = ({
  view,
  action,
  status,
  loading,
  error,
  data,
}) => {
  return async dispatch => {
    await dispatch({
      type: 'SET_APP_STATUS',
      view,
      action,
      status,
      loading,
      error,
      data,
    });
    // return Promise.resolve();
  };
};

export const resetAppStatus = ({
  view,
  action,
  status,
  loading,
  error,
  data,
}) => {
  return async dispatch => {
    await dispatch({
      type: 'RESET_APP_STATUS',
      view,
      action,
      status,
      loading,
      error,
      data,
    });
    // return Promise.resolve();
  };
};
