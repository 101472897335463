import React from 'react';

import styled from 'styled-components/macro';
import { color } from '../../styles/style';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';

import BgImg from '../../assets/images/landing_image.png';
import LogoTall from '../../assets/logos/JD-Advising_logo-Tall.png';
import { Switch, Route, withRouter } from 'react-router-dom';

import ForgotPassword from './ForgotPassword';
import ReturnAction from './ReturnAction';
// import SignUp from './SignUp';
import SignIn from './SignIn';

const FormWrapper = styled.div`
  background: ${color.grey};
  padding: 4rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  max-width: 500px;
  width: 100%;
  margin: auto;
  & img {
    margin-bottom: 5rem;
  }
`;
const SignInFormBg = styled.div`
  background-image: url(${BgImg});
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  & p {
    margin-top: 1rem;
    color: ${color.primaryLight};
  }
  & h2 {
    color: white;
  }
`;
const LandingPathway = ({ match, location }) => (
  <Grid container spacing={0}>
    <Grid item xs={4}>
      <FormWrapper>
        <img alt="logo" src={LogoTall} />
        <Switch>
          {/* <Route path="/sign-up" component={SignUp} /> */}

          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/action" component={ReturnAction} />
          <Route component={SignIn} />
        </Switch>
      </FormWrapper>
    </Grid>
    <Grid item xs={8}>
      <SignInFormBg>
        <Typography component="h2" variant="h3">
          Welcome Back Admin!
        </Typography>

        <Typography component="p" variant="h6">
          JD Advising MBE QBank Admin
        </Typography>
      </SignInFormBg>
    </Grid>
  </Grid>
);

LandingPathway.propTypes = {};

export default withRouter(LandingPathway);
