import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components/macro';
// import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import FormControl from '@material-ui/core/FormControl';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import FormHelperText from '@material-ui/core/FormHelperText';
/*** CONTAINERS  ***/
import { MainScreen } from '../../../../containers/Main';
import { Card, CardContent } from '../../../../containers/Card';
import { FormControl } from '../../../../containers/Form';

/*** GLOBAL COMPONENTS  ***/
// import DialogModalStudent from '../../../../components/DialogModalStudent';
import LoadingModal from '../../../../components/LoadingModal';
import ContinueModal from '../../../../components/ContinueModal';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import FlushLinkButton from '../../../../elements/FlushLinkButton';

// import AuthErrorTxt from '../../../../elements/AuthErrorTxt';

/*** ACTIONS & APIs ***/
import {
  createNewStudent,
  resetStudentView,
} from '../../../../store/actions/studentActions';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;
// const styles = theme => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   formControl: {
//     margin: theme.spacing.unit,
//
//     minWidth: 220,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing.unit * 2,
//   },
// });
// const initialState = {
//   dialogStatus: 'ready',
//   dialogType: 'preview',
//   isDialogOpen: false,
//   email: '',
//   password: '',
//   firstName: '',
//   lastName: '',
//   initials: '',
//   role: 'student',
//   status: 'new',
//   accountType: '',
//   studentObj: {},
// };
// import Typography from '@material-ui/core/Typography';
class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogStatus: 'ready',
      dialogType: 'new',
      isDialogOpen: false,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      // password: Math.random()
      //   .toString(36)
      //   .slice(-8),
      role: 'student',
      status: 'new',
      accountType: '',
      // studentObj: {},
    };
    this.goBackScreen = this.goBackScreen.bind(this);
  }

  componentDidMount() {
    this.resetFormFields();
  }
  resetFormFields() {
    this.props.resetStudentView('new', 'start').then(() => {
      this.setState({
        email: '',
        firstName: '',
        lastName: '',
        password: Math.random()
          .toString(36)
          .slice(-8),
        accountType: '',
      });
    });
  }
  goBackScreen(e) {
    const { history } = this.props;
    history.push(`/admin/students`);
    e.preventDefault();
  }
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSelectChange = event => {
    this.setState({ accountType: event.target.value });
  };
  handleDialogCancel = () => {
    console.log(`handle CANCEL`);
    this.setState({ isDialogOpen: false });
    // this.cancelFooterAction();
  };
  handleDialogClose = () => {
    console.log(`handle CLOSE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogDone = () => {
    console.log(`handle DONE`);
    const { history, resetStudentView } = this.props;
    resetStudentView('list', 'start').then(() => {
      history.push(`/admin/students`);
      // this.resetFormFields();
    });

    // this.resetFormFields();
    // this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogAction = () => {
    console.log(`handle ACTION`);
    const { resetStudentView } = this.props;
    resetStudentView('new', 'start').then(() => {
      this.resetFormFields();
    });
    // this.setState({ ...initialState });
    // this.postFooterAction();
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log(`state = ${JSON.stringify(this.state)}`);
    const { isDialogOpen, dialogStatus, dialogType, ...sobj } = this.state;
    console.log(`state = ${JSON.stringify(sobj)}`);

    const studentObj = {
      ...sobj,
    };
    // this.setState({
    //   studentObj: sobj,
    //   dialogType: 'create',
    //   dialogStatus: 'ready',
    //   isDialogOpen: true,
    // });
    this.props.createNewStudent(studentObj);
    // .then(() => {
    //   this.setState({
    //     studentObj: sobj,
    //     dialogType: 'create',
    //     dialogStatus: 'ready',
    //     isDialogOpen: false,
    //   });
    // });
  };

  render() {
    const {
      // isDialogOpen,
      // dialogType,
      email,
      password,
      firstName,
      lastName,
      accountType,
      // studentObj,
    } = this.state;
    const { error, status, loading } = this.props;
    const inValid =
      firstName === '' || lastName === '' || email === '' || accountType === '';
    return (
      <>
        {loading && <LoadingModal open={loading} />}
        <ContinueModal
          title="Email Sent"
          bodyContent="You have successfully CREATED a new Student and an Email has been sent to them. What would you like to do now?"
          open={status === 'email-sent'}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />
        {/* <DialogModalStudent
          type={dialogType}
          open={isDialogOpen}
          item={studentObj}
          status={dialogStatus}
          handleClose={this.handleDialogClose}
          handleCancel={this.handleDialogCancel}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        /> */}
        <Screen>
          <MainScreen
            title="New Student"
            leftSideItem={
              <FlushLinkButton onClick={this.goBackScreen}>
                <ArrowBackIcon />
                BACK
              </FlushLinkButton>
            }
            spacing={24}
          >
            <Grid item xs={12}>
              <Card>
                <form onSubmit={this.handleSubmit}>
                  <CardContent>
                    <Grid container spacing={24}>
                      <Grid item xs>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="firstName">
                            First Name
                          </InputLabel>
                          <Input
                            type="text"
                            name="firstName"
                            value={firstName}
                            id="firstName"
                            onChange={this.handleChange}
                            autoFocus
                          />
                        </FormControl>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="lastName">Last Name</InputLabel>
                          <Input
                            type="text"
                            name="lastName"
                            value={lastName}
                            id="lastName"
                            onChange={this.handleChange}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                      <Grid item xs>
                        <FormControl
                          error={error ? true : false}
                          margin="normal"
                          required
                        >
                          <InputLabel htmlFor="email">Email Address</InputLabel>
                          <Input
                            id="email"
                            name="email"
                            value={email}
                            autoComplete="email"
                            onChange={this.handleChange}
                          />
                          <FormHelperText id="component-error-text">
                            {error ? error : null}
                          </FormHelperText>
                        </FormControl>
                        <FormControl margin="normal" required>
                          <InputLabel htmlFor="passwordg">Password</InputLabel>
                          <Input
                            name="passwordg"
                            type="text"
                            value={password}
                            id="passwordg"
                            readOnly
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={24}>
                      <Grid item xs>
                        <FormControl required>
                          <InputLabel htmlFor="accountType-native-simple">
                            Account Type
                          </InputLabel>
                          <Select
                            native
                            value={accountType}
                            onChange={this.handleSelectChange}
                            inputProps={{
                              name: 'accountType',
                              id: 'accountType-native-simple',
                            }}
                          >
                            <option value="" />
                            <option value="trial">Trial</option>
                            <option value="pro">Pro</option>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={inValid}
                        >
                          Create Student
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </form>
              </Card>
            </Grid>
          </MainScreen>
        </Screen>
      </>
    );
  }
}

New.propTypes = {
  studentStatus: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  // error: PropTypes.object,
  newid: PropTypes.string,
};
const mapStateToProps = state => {
  return {
    appStatus: state.appStatus,
    loading: state.appStatus.loading,
    status: state.appStatus.status,
    error: state.appStatus.error,
    newid: state.appStatus.data.currentId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewStudent: creds => dispatch(createNewStudent(creds)),
    resetStudentView: (action, status) =>
      dispatch(resetStudentView(action, status)),
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
  // withStyles(styles)
)(New);
