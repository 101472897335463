import firebase from '../api/firebaseAdmin';

export const updatePromo = (key, uobj) => {
  return async dispatch => {
    await dispatch({
      type: 'SET_APP_STATUS',
      view: 'promos',
      action: 'update',
      status: 'pending',
      loading: true,
      error: null,
      notes: '',
    });
    const promosRef = firebase.database().ref('promos');
    const promoDoc = await promosRef.child(key).update(uobj);
    console.log(`promoDoc = ${JSON.stringify(promoDoc)}`);
    await dispatch({
      type: 'SET_APP_STATUS',
      view: 'promos',
      action: 'update',
      status: 'success',
      loading: false,
      error: null,
      notes: promoDoc,
    });
  };
};

export const addPromo = (key, uobj) => {
  return async dispatch => {
    await dispatch({
      type: 'SET_APP_STATUS',
      view: 'promos',
      action: 'add',
      status: 'pending',
      loading: true,
      error: null,
      notes: '',
    });
    const promosRef = firebase.database().ref('promos');
    const promoDoc = await promosRef.child(key).set(uobj);
    console.log(`promoDoc = ${JSON.stringify(promoDoc)}`);

    await dispatch({
      type: 'SET_APP_STATUS',
      view: 'promos',
      action: 'add',
      status: 'success',
      loading: false,
      error: null,
      notes: promoDoc,
    });

    // return Promise.resolve();
  };
};
