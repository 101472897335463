import React from 'react';
// import PropTypes from 'prop-types';
import MuiCardHeader from '@material-ui/core/CardHeader';
// import CardMedia from '@material-ui/core/CardMedia';
// import CardContent from '@material-ui/core/CardContent';
// import CardActions from '@material-ui/core/CardActions';
const CardHeader = ({ children, ...others }) => <MuiCardHeader {...others} />;

CardHeader.propTypes = {};

export default CardHeader;
