const convertToHtml = inputString => {
  // const q5 = qnum.q5;
  //replace '\r\n' with break and/or '\n' with break
  // const regpat = /\r\n/g;
  const regpat = /(\r\n)?(\n)/gi;
  //replace(/\\r\\n/g, "<br />");
  const replacement = '<br />';
  if (inputString) {
    return inputString.replace(regpat, replacement);
  }
  return;
};

export { convertToHtml };
