import React from 'react';
import PropTypes from 'prop-types';
import MuiFormControl from '@material-ui/core/FormControl';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    maxWidth: 1000 - 2 * theme.spacing.unit,
  },
});
const FormControl = ({ children, classes, ...others }) => (
  <MuiFormControl className={classes.formControl} {...others}>
    {children}
  </MuiFormControl>
);

FormControl.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(FormControl);
