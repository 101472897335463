import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { compose } from 'redux';
import { connect } from 'react-redux';
/*** ROUTER  ***/

/*** STYLE  ***/
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';

/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/
import Loading from '../../components/Loading';
/*** LOCAL COMPONENTS  ***/
import Content from './Content';
/*** ELEMENTS  ***/
/*** ACTIONS & APIs ***/
import {
  // publishQuestion,
  publishQuestions,
  removeQuestion,
  unpublishQuestion,
} from '../../store/actions/questionActions';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/

// import DialogActions from '@material-ui/core/DialogActions';

// import DialogContent from '@material-ui/core/DialogContent';

// import DialogTitle from '@material-ui/core/DialogTitle';

const DialogContentText = withStyles(theme => ({
  root: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
}))(MuiDialogContentText);

const DialogContentWorking = withStyles(theme => ({
  root: {
    margin: 0,
    height: '100%',

    padding: '6rem',
  },
}))(MuiDialogContent);

class DialogModal extends React.Component {
  state = {
    status: 'ready',
  };
  componentDidMount() {
    console.log(`DialogModal componentDidMount ${this.state.statue} `);
    this.setState({ status: 'ready' });
  }

  beginPublish = event => {
    this.setState({ status: 'working' });

    event.preventDefault();
    this.callPublish();
    // this.props.publishQuestions(level, qbank, questions);
  };
  callPublish() {
    const { level, qbank, questions } = this.props;
    console.log(`qbank=${qbank} qid= ${questions[0]}`);
    this.props.publishQuestions(level, qbank, questions).then(() => {
      this.setState({ status: 'done' });
    });
  }
  beginUnpublish = event => {
    const { level, qbank, qid } = this.props;
    this.setState({ status: 'working' });
    console.log(`qbank=${qbank} qid= ${qid}`);

    this.props.unpublishQuestion(level, qbank, qid).then(() => {
      this.setState({ status: 'done' });
      // this.props.handleAction();
    });
    event.preventDefault();
  };

  beginDelete = event => {
    const { level, qbank, qid } = this.props;
    this.setState({ status: 'working' });
    console.log(`qbank=${qbank} qid= ${qid}`);
    this.props.removeQuestion(level, qbank, qid).then(() => {
      this.setState({ status: 'done' });
      // this.props.handleAction();
    });
    event.preventDefault();
  };
  handlePostDelete = event => {
    const { view } = this.props;
    if (view === 'preview') {
    }
    this.setState({ status: 'ready' });
    this.props.handleDone();
  };
  handlePreDone = event => {
    this.setState({ status: 'ready' });
    this.props.handleDone();
  };
  handlePreAction = event => {
    //this.setState({ status: 'ready' });
    this.props.handleAction();
  };
  // pubQ = (qbank, qid) => {
  //   this.props.publishQuestion(qbank, qid).then(() => {
  //     console.log('subbbbbb');
  //     this.props.handleAction();
  //   });
  // };
  render() {
    const { open, qStatus, type, handleClose, handleCancel } = this.props;

    const { status } = this.state;
    const titleType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';
    const title = `${titleType} Question`;

    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        {status === 'working' && (
          <Content title={title} hasActions={false}>
            <DialogContentWorking>
              <Loading />
            </DialogContentWorking>
          </Content>
        )}
        {status === 'done' && (
          <>
            {qStatus === 'published' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
                rightLabel="Add Another"
                rightAction={this.handlePreAction}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully PUBLISHED the question. What would you
                    like to do now?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {qStatus === 'unpublished' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully UNPUBLISHED the question.
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {qStatus === 'removed' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully REMOVED the question.
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
          </>
        )}

        {status === 'ready' && (
          <>
            {type === 'delete' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="Delete"
                rightAction={this.beginDelete}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to DELETE the Question?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === 'unpublish' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="UNPUBLISH"
                rightAction={this.beginUnpublish}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to UNPUBLISH the Question?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === 'publish' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="Publish"
                rightAction={this.beginPublish}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to PUBLISH the Question?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
          </>
        )}
      </Dialog>
    );
  }
}

DialogModal.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  qStatus: PropTypes.string.isRequired,
  qid: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleDone: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  return {
    section: state.questionStatus.section,
    qbank: state.questionStatus.qbank,
    level: state.questionStatus.level,
    view: state.questionStatus.view,
    qid: state.questionStatus.qid,
    qStatus: state.questionStatus.status,
    isDraft: state.questionStatus.isDraft,
  };
};
// export default withStyles(styles)(DialogModal);
const mapDispatchToProps = dispatch => {
  return {
    publishQuestions: (level, qbank, questions) =>
      dispatch(publishQuestions(level, qbank, questions)),
    removeQuestion: (level, qbank, qid) =>
      dispatch(removeQuestion(level, qbank, qid)),
    unpublishQuestion: (level, qbank, qid) =>
      dispatch(unpublishQuestion(level, qbank, qid)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DialogModal);
