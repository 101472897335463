import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { compose } from 'redux';
import { connect } from 'react-redux';
/*** ROUTER  ***/

/*** STYLE  ***/
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
/*** CONTAINERS  ***/
/*** GLOBAL COMPONENTS  ***/
import Loading from '../../../../components/Loading';
/*** LOCAL COMPONENTS  ***/
import Content from './Content';
/*** ELEMENTS  ***/
/*** ACTIONS & APIs ***/
import { removeUser } from '../../../../store/actions/userActions';
/*** UTILS & HELPERS ***/
/*** SCREENS  ***/

const DialogContentText = withStyles(theme => ({
  root: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
}))(MuiDialogContentText);

const DialogContentWorking = withStyles(theme => ({
  root: {
    margin: 0,
    height: '100%',

    padding: '6rem',
  },
}))(MuiDialogContent);

class UserDialogModal extends React.Component {
  state = {
    status: 'ready',
  };

  beginCreate = event => {
    const { item, addUser } = this.props;
    this.setState({ status: 'working' });

    addUser(item).then(() => {
      this.setState({ status: 'done' });
    });
    event.preventDefault();
  };

  beginUpdate = event => {
    const { item, updateUser } = this.props;
    this.setState({ status: 'working' });

    updateUser(item).then(() => {
      this.setState({ status: 'done' });
    });
    event.preventDefault();
  };

  beginDelete = event => {
    const { item, removeUser } = this.props;
    const userId = item.uid;
    this.setState({ status: 'working' });

    removeUser(userId).then(() => {
      this.setState({ status: 'done' });
      // this.props.handleAction();
    });
    event.preventDefault();
  };

  handlePreDone = event => {
    this.setState({ status: 'ready' });
    this.props.handleDone();
  };
  handlePreAction = event => {
    this.setState({ status: 'ready' });
    this.props.handleAction();
  };

  render() {
    const { open, type, handleClose, handleCancel } = this.props;

    const { status } = this.state;
    const titleType = type ? type.charAt(0).toUpperCase() + type.slice(1) : '';
    const title = `${titleType} User`;

    return (
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        {status === 'working' && (
          <Content title={title} hasActions={false}>
            <DialogContentWorking>
              <Loading />
            </DialogContentWorking>
          </Content>
        )}
        {status === 'done' && (
          <>
            {type === 'delete' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully REMOVED the User.
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === 'create' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
                rightLabel="Add Another"
                rightAction={this.handlePreAction}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully CREATED a new User. What would you
                    like to do now?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === 'update' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Done"
                leftAction={this.handlePreDone}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    You have successfully UPDATED the User Record.
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
          </>
        )}
        {status === 'ready' && (
          <>
            {type === 'delete' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="Delete"
                rightAction={this.beginDelete}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to REMOVE the User?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === 'create' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="CREATE"
                rightAction={this.beginCreate}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to CREATE a New User?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
            {type === 'update' && (
              <Content
                title={title}
                hasActions={true}
                leftLabel="Cancel"
                leftAction={handleCancel}
                rightLabel="UPDATE"
                rightAction={this.beginUpdate}
              >
                <MuiDialogContent>
                  <DialogContentText>
                    Are you sure you want to UPDATE the User Record?
                  </DialogContentText>
                </MuiDialogContent>
              </Content>
            )}
          </>
        )}
      </Dialog>
    );
  }
}

UserDialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
const mapStateToProps = (state, ownProps) => {
  return {
    userStatus: state.userStatus,
    uStatus: state.userStatus.status,
    error: state.userStatus.error,
    newid: state.userStatus.currentId,
  };
};
// export default withStyles(styles)(DialogModal);
const mapDispatchToProps = dispatch => {
  return {
    // addUser: sobj => dispatch(addUser(sobj)),
    removeUser: uid => dispatch(removeUser(uid)),
    // updateUser: sobj => dispatch(updateUser(sobj)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserDialogModal);
