import React from 'react';
// import PropTypes from 'prop-types';
/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UTILS & HELPERS ***/
import { color } from '../../styles/style';

/*** ELEMENTS  ***/
import Button from '../../elements/Button';

const FeedbackBtn = styled(Button)`
  &&&&&& {
    background-color: transparent;
    color: ${props => color.blueDarkest};
    border-color: ${props => color.blueLight};
    border-width: 1px;
    border-radius: 3px;
    border-style: dashed;
    height: 80px;
    &:hover {
      border-color: ${props => color.primary};
      background-color: transparent;
      color: ${props => color.primary};
    }
  }
`;
const FeedbackButton = ({ children, ...others }) => (
  <FeedbackBtn {...others}>{children}</FeedbackBtn>
);
FeedbackButton.propTypes = {};

export default FeedbackButton;
