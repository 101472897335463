import firebase from '../api/firebaseAdmin';
import firebaseStudent from '../api/firebaseStudent';

export const signInGoogle = () => {
  return dispatch => {
    //const firebase = getFirebase();
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase.auth().signInWithRedirect(provider);
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        if (result.credential) {
          firebaseStudent.auth().signInWithCredential(result.credential);
          var token = result.credential.accessToken;
          console.log(`token === ${token}`);
        }
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch(err => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signIn = credentials => {
  return dispatch => {
    //const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch(err => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return dispatch => {
    //const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

export const signUp = newUser => {
  return dispatch => {
    //const firebase = getFirebase();
    const firestore = firebase.firestore();
    let batch = firestore.batch();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        const userId = resp.user.uid;
        const userRef = firestore.collection('users').doc(userId);
        batch.set(userRef, {
          email: newUser.email,
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          initials: newUser.firstName[0] + newUser.lastName[0],
          role: 'admin',
          status: 'current',
        });

        return batch
          .commit()
          .then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' });
          })
          .catch(err => {
            dispatch({ type: 'SIGNUP_ERROR', err });
          });
      });
    // .then(resp => {
    //   return firestore
    //     .collection('users')
    //     .doc(resp.user.uid)
    //     .set({
    //       email: newUser.email,
    //       firstName: newUser.firstName,
    //       lastName: newUser.lastName,
    //       initials: newUser.firstName[0] + newUser.lastName[0],
    //       role: newUser.role,
    //       status: 'new',
    //     });
    // })
    // .then(resp => {
    //   return firestore
    //     .collection('students')
    //     .doc(resp.user.uid)
    //     .set({ sgCount: 0, tgCount: 0 });
    // })
    // .then(() => {
    //   dispatch({ type: 'SIGNUP_SUCCESS' });
    // })
    // .catch(err => {
    //   dispatch({ type: 'SIGNUP_ERROR', err });
    // });
  };
};
export const forgotPassword = credentials => {
  return (dispatch, getState) => {
    //const firebase = getFirebase();
    return firebase
      .auth()
      .sendPasswordResetEmail(credentials.email)
      .then(() => {
        dispatch({ type: 'EMAIL_SENT', payload: 'password-recovery' });
        // return Promise.resolve();
      })
      .catch(err => {
        dispatch({ type: 'EMAIL_ERROR', err });
      });
  };
};
