import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*** DATA, STORE and STATE  ***/
import { compose } from 'redux';
// import { connect } from 'react-redux';
/*** ROUTER  ***/
import { withRouter } from 'react-router-dom';
/*** STYLE  ***/
// import classNames from 'classnames';
// import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
// import Checkbox from '@material-ui/core/Checkbox';
/*** CONTAINERS  ***/
/*** SCREENS  ***/
/*** GLOBAL COMPONENTS  ***/
import DialogModalStudent from '../../components/DialogModalStudent';
/*** LOCAL COMPONENTS  ***/
import StudentTableHead from './StudentTableHead';
// import TableMainToolbar from './TableMainToolbar';
import RowMenu from './RowMenu';
/*** ELEMENTS  ***/
/*** ACTIONS  ***/
// import {
//   setQuestion,
//   publishQuestion,
// } from '../../store/actions/questionActions';
/*** UTILS & HELPERS ***/
import { color, fontWeight } from '../../styles/style';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    // minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    color: color.dark,
    fontWeight: fontWeight.semiBold,
  },
  tableHeadCell: {
    color: color.blueDarkest,
    fontWeight: 700,
  },
  moreVert: {
    textAlign: 'right',
    color: color.blueDarkest,
  },
});

class StudentTable extends Component {
  state = {
    dialogStatus: 'ready',
    dialogType: 'preview',
    isDialogOpen: false,
    order: 'desc',
    orderBy: 'id',
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 5,
    qid: '',
    studentObj: {},
  };
  static getDerivedStateFromProps(props, state) {
    return { data: props.data || [] };
  }

  handleMenuActionEdit = (event, uid) => {
    let newSelected = [];
    newSelected.push(uid);

    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'publish',
      uid: uid,
      selected: newSelected,
    });
  };
  handleMenuActionDelete = (event, uid) => {
    // console.log(`uid = ${uid}`);

    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'delete',
      studentObj: {
        uid: uid,
      },
    });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  // handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     this.setState(state => ({ selected: state.data.map(n => n.uid) }));
  //     return;
  //   }
  //   this.setState({ selected: [] });
  // };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  handleDialogCancel = () => {
    console.log(`handle CANCEL`);
    this.setState({ isDialogOpen: false });
    // this.cancelFooterAction();
  };
  handleDialogClose = () => {
    console.log(`handle CLOSE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogDone = () => {
    // console.log(`handle DONE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogAction = () => {
    console.log(`handle ACTION`);
    this.setState({ isDialogOpen: false });
    // this.postFooterAction();
  };
  handleDialogAction = () => {
    console.log(`handle ACTION`);
    this.setState({ isDialogOpen: false });
    // this.postFooterAction();
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, section, colHeaders } = this.props;
    const {
      dialogType,
      dialogStatus,
      isDialogOpen,
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      studentObj,
    } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <>
        <DialogModalStudent
          type={dialogType}
          open={isDialogOpen}
          item={studentObj}
          status={dialogStatus}
          handleClose={this.handleDialogClose}
          handleCancel={this.handleDialogCancel}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />

        <div className={classes.root}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <StudentTableHead
                rows={colHeaders}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((val, indx) => {
                    // console.log(`indx = ${indx} val=${JSON.stringify(val)}`);
                    const isSelected = this.isSelected(val.uid);
                    return (
                      <TableRow
                        hover
                        onClick={event => this.handleClick(event, val.uid)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={val.uid}
                        selected={isSelected}
                      >
                        <TableCell align="left" component="th" scope="row">
                          {val.lastName}
                        </TableCell>
                        <TableCell align="left">{val.firstName}</TableCell>

                        <TableCell align="left">{val.email}</TableCell>

                        {section === 'new' && (
                          <>
                            <TableCell align="left">{`${
                              val.accountType
                            }`}</TableCell>
                            <TableCell align="left">
                              {val.isActive ? (
                                <DoneIcon color="secondary" />
                              ) : (
                                <ClearIcon color="error" />
                              )}
                            </TableCell>
                          </>
                        )}
                        {section !== 'new' && (
                          <>
                            <TableCell align="left">
                              {val.isActive ? (
                                <DoneIcon color="secondary" />
                              ) : (
                                <ClearIcon color="error" />
                              )}
                            </TableCell>

                            {val.all ? (
                              <TableCell align="right">{`${val.all.total} - ${
                                val.all.correct
                              } `}</TableCell>
                            ) : (
                              <TableCell align="left">no data</TableCell>
                            )}
                          </>
                        )}
                        <TableCell align="center">
                          {
                            <RowMenu
                              uid={val.uid}
                              section={section}
                              menuActionDelete={event =>
                                this.handleMenuActionDelete(event, val.uid)
                              }
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </>
    );
  }
}

StudentTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  section: PropTypes.string.isRequired,
};
// const mapDispatchToProps = dispatch => {
//   return {
//     setQuestion: qObj => dispatch(setQuestion(qObj)),
//     publishQuestion: (level, qbank, qid) =>
//       dispatch(publishQuestion(level, qbank, qid)),
//   };
// };

export default compose(
  withRouter,
  withStyles(styles)
  // connect(
  //   null,
  //   mapDispatchToProps
  // )
)(StudentTable);
