import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import HeaderTitle from '../../../elements/HeaderTitle';
import HeaderInfo from '../../../elements/HeaderInfo';
export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10rem;
  z-index: 1;
`;
export const MainContent = styled.main`
  flex: 1;
  width: 80%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
`;
const ScreenHeader = styled.div`
  height: 90px;
`;

const MainScreen = props => {
  return (
    <Main>
      <MainContent>
        {props.leftSideItem && (
          <Grid container spacing={props.spacing}>
            <Grid item xs={12}>
              {props.leftSideItem}
            </Grid>
          </Grid>
        )}
        <ScreenHeader>
          <Grid
            container
            justify="space-between"
            alignItems="flex-end"
            spacing={props.spacing}
          >
            <Grid item>
              <HeaderTitle>{props.title}</HeaderTitle>
            </Grid>
            {props.rightSideItem && (
              <Grid container justify="flex-end" item xs={6}>
                <HeaderInfo>{props.rightSideItem}</HeaderInfo>
              </Grid>
            )}
          </Grid>
        </ScreenHeader>
        <Grid container spacing={props.spacing}>
          {props.children}
        </Grid>
      </MainContent>
    </Main>
  );
};

MainScreen.propTypes = {
  title: PropTypes.string.isRequired,
  spacing: PropTypes.number,
  leftSideItem: PropTypes.node,
  rightSideItem: PropTypes.node,
};
MainScreen.defaultProps = {
  spacing: 24,
  goBack: null,
};

export default MainScreen;
