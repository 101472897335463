import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
import styled, { css } from 'styled-components/macro';
/*** UI LIBS  ***/
/*** CONTAINERS  ***/

/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import Button from '../../elements/Button';
/*** ACTIONS & APIs ***/

/*** UTILS & HELPERS ***/
import { color, util, fontWeight, fontType } from '../../styles/style';
/*** SCREENS  ***/
/*** ASSETS  ***/
import PromoImage from '../../assets/images/PromoImage.png';

const PromoWrapper = styled.div`
  background-color: ${color.blueDarkest};
  padding: ${util.padding};
  border-radius: ${util.radiusSmall};
  font-size: ${fontType.copy};
  color: ${color.blueLightest};
  display: flex;
  flex-grow: 1;
  flex-direction: ${props => (props.isCol ? 'column' : 'row')};
  justify-content: space-between;
`;

const PromoCopy = styled.div`
  ${props =>
    !props.isCol &&
    css`
      flex-basis: 50%;
    `};
`;
const PromoText = styled.p`
  font-weight: ${fontWeight.semiBold};
`;
const PromoTitle = styled.div`
  color: white;
  font-size: ${fontType.subTitle};
  font-weight: ${fontWeight.bold};
  margin: 0;
  ${props =>
    !props.isCol &&
    css`
      margin-bottom: 5px;
    `};
`;
const PromoButtonArea = styled.div`
  ${props =>
    !props.isCol &&
    css`
      flex-basis: 30%;
      text-align: center;
      align-self: center;
    `};
`;
const PromoImageArea = styled.div`
  flex-basis: 20%;
  max-width: 100px;
`;
const BasicBanner = props => {
  const { isCol, promo } = props;

  return (
    <PromoWrapper isCol={isCol}>
      {!isCol && (
        <PromoImageArea>
          <img alt="promo" src={PromoImage} />
        </PromoImageArea>
      )}
      <PromoCopy isCol={isCol}>
        <PromoTitle isCol={isCol}>{promo.title}</PromoTitle>
        <PromoText>{promo.content}</PromoText>
      </PromoCopy>
      <PromoButtonArea isCol={isCol}>
        <Button
          href={promo.btnUrl}
          color="secondary"
          variant="contained"
          target="_blank"
        >
          {promo.btnLabel}
        </Button>
      </PromoButtonArea>
    </PromoWrapper>
  );
};

BasicBanner.propTypes = {
  promo: PropTypes.object.isRequired,
  isCol: PropTypes.bool.isRequired,
};
// PromoSection.default = {
//   version: 'pass',
// };

export default BasicBanner;
