import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { firebaseConnect } from 'react-redux-firebase';
/*** ROUTER  ***/

/*** STYLE  ***/
// import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
/*** CONTAINERS  ***/
import { MainScreen } from '../../../../containers/Main';

/*** GLOBAL COMPONENTS  ***/

// import TableMain from '../../../../components/TableMain';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import Button from '../../../../elements/Button';
/*** ACTIONS & APIs ***/
// import { fetchAllStudents } from '../../../../store/actions/studentActions';
// import { mbeFS } from '../../../../store/api/fb';
/*** UTILS & HELPERS ***/
// import { color, fontWeight } from '../../../../styles/style';
/*** SCREENS  ***/
import UserTable from './UserTable';

const newCols = [
  { id: 'lastName', align: 'left', disablePadding: false, label: 'Last Name' },
  {
    id: 'firstName',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'email',
  },
  { id: 'menu', align: 'center', disablePadding: false, label: '' },
];
class List extends Component {
  constructor(props) {
    super(props);
    // this.refUsers = mbeFS.collection('users');
    // this.newUsers = null;
    this.state = {
      tabIndex: 0,
      users: [],
      isLoading: true,
    };
    this.goToScreen = this.goToScreen.bind(this);
  }
  componentDidMount() {}
  componentWillUnmount() {
    // this.newUsers();
  }

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };
  goToScreen(e) {
    const { match, history } = this.props;

    history.push(`${match.url}/new`);

    e.preventDefault();
  }
  render() {
    const { users } = this.props;
    const { tabIndex } = this.state;

    return (
      <MainScreen
        title="Users"
        rightSideItem={
          <Button
            color="primary"
            variant="contained"
            aria-label="Add User"
            onClick={this.goToScreen}
          >
            Add User
          </Button>
        }
      >
        <Grid item xs={12}>
          <Card>
            <Tabs
              value={tabIndex}
              onChange={this.handleChange}
              // classes={{
              //   root: classes.tabsRoot,
              //   indicator: classes.tabsIndicator,
              // }}
            >
              <Tab
                disableRipple
                // classes={{
                //   root: classes.tabRoot,
                //   selected: classes.tabSelected,
                // }}
                label="USERS"
              />
            </Tabs>

            {tabIndex === 0 &&
              users.length > 0 && (
                <UserTable colHeaders={newCols} data={users} section="users" />
              )}
          </Card>
        </Grid>
      </MainScreen>
    );
  }
}
// static propTypes = {
//    uid: PropTypes.string,
//    selectedCategory: PropTypes.string,
//    firestore: PropTypes.shape({
//      add: PropTypes.func.isRequired
//    }).isRequired
//  }
List.propTypes = {
  users: PropTypes.array,
  // studentsNew: state.studentsNew ? state.studentsNew : [],
  // studentsPaid: PropTypes.array,
  // studentStatus: PropTypes.object,
};
const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users ? state.firestore.ordered.users : [],
  };
};
// const mapDispatchToProps = dispatch => {
//   return {
//     fetchAllStudents: () => dispatch(fetchAllStudents()),
//   };
// };

export default compose(
  // firebaseConnect(() => ['users']),
  connect(mapStateToProps)
  // withStyles(styles)
)(List);
