import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
/*** ROUTER  ***/

/*** STYLE  ***/
// import styled from 'styled-components/macro';

import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import IconEdit from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';

/*** CONTAINERS  ***/
import {
  Card,
  CardHeader,
  CardContent,
  CardDirty,
} from '../../containers/Card';
/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import Button from '../../elements/Button';
/*** ACTIONS & APIs ***/
import { updateQuestion } from '../../store/actions/questionActions';
/*** UTILS & HELPERS ***/
import { color, fontWeight } from '../../styles/style';
/*** SCREENS  ***/

// const EditCard = styled.div`
//   color: ${color.dark};
//   ${props =>
//     props.isDirty &&
//     `
//     background-color: ${color.secondaryLight};
//   `};
// `;

const styles = theme => ({
  card: {
    display: 'flex',
    width: '100%',
  },
  content: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: fontWeight.semiBold,
    color: color.blueDarkest,
  },
  questionCopy: {
    fontSize: 14,
    lineHeight: 'initial',
    fontWeight: fontWeight.regular,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class CardFieldEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      isDirty: false,
      oldContent: props.content,
      newContent: props.content,
    };
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.saveFieldValue = this.saveFieldValue.bind(this);
    this.cancelFieldChange = this.cancelFieldChange.bind(this);
  }

  toggleEditMode() {
    this.setState(state => ({
      editMode: !state.editMode,
    }));
  }
  cancelFieldChange() {
    const { oldContent } = this.state;
    this.setState({ newContent: oldContent, editMode: false });
  }
  saveFieldValue() {
    const { newContent } = this.state;
    const { fieldKey, qid, level, qbank } = this.props;
    const uobj = {
      [fieldKey]: newContent,
    };
    console.log(`saveFieldValue_${newContent}`);
    this.props.updateQuestion(level, qbank, qid, uobj).then(() => {
      this.setState({
        editMode: false,
      });
    });
  }
  handleChange = name => event => {
    console.log(`_______handleChange_____${event.target.value}`);
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, label } = this.props;
    const { editMode, newContent, oldContent } = this.state;
    return (
      <Grid item xs={12}>
        {editMode ? (
          <Card>
            <CardDirty isDirty={newContent !== oldContent}>
              <CardHeader
                action={
                  <IconButton
                    onClick={this.toggleEditMode}
                    disabled={newContent !== oldContent}
                  >
                    <IconEdit />
                  </IconButton>
                }
                title={label}
              />

              <CardContent className={classes.content}>
                <TextField
                  id="standard-multiline-flexible"
                  multiline
                  // rowsMax="4"
                  value={this.state.newContent}
                  onChange={this.handleChange('newContent')}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.questionCopy,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </CardContent>
              <CardContent className={classes.actions}>
                <Button
                  color="default"
                  variant="text"
                  onClick={this.cancelFieldChange}
                >
                  CANCEL
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={this.saveFieldValue}
                  disabled={newContent === oldContent}
                >
                  SAVE
                </Button>
              </CardContent>
            </CardDirty>
          </Card>
        ) : (
          <Card>
            <CardHeader
              action={
                <IconButton onClick={this.toggleEditMode}>
                  <IconEdit />
                </IconButton>
              }
              title={label}
            />

            <CardContent
              className={classes.content}
              dangerouslySetInnerHTML={{
                __html: newContent,
              }}
            />
          </Card>
        )}
      </Grid>
    );
  }
}

CardFieldEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default withStyles(styles)(CardFieldEdit);
const mapStateToProps = (state, ownProps) => {
  return {
    qbank: state.questionStatus.qbank,
    level: state.questionStatus.level,
    status: state.questionStatus.status,
    qid: state.questionStatus.qid,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateQuestion: (level, qbank, qid, uObj) =>
      dispatch(updateQuestion(level, qbank, qid, uObj)),
    // fetchStudyGuideQuestions: sguide =>
    //   dispatch(fetchStudyGuideQuestions(sguide)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(CardFieldEdit);
