import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
/*** ROUTER  ***/
import { withRouter } from 'react-router-dom';
/*** STYLE  ***/
// import styled from 'styled-components/macro';
// import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
/*** CONTAINERS  ***/
import { MainScreen } from '../../../containers/Main';
import { Card, CardContent } from '../../../containers/Card';
/*** GLOBAL COMPONENTS  ***/
import QuestionsTable from '../../../components/QuestionsTable';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import Button from '../../../elements/Button';
import Select from '../../../elements/Select';
/*** ACTIONS & APIs ***/
import {
  sectionReset,
  setQbank,
  setSection,
} from '../../../store/actions/questionActions';
/*** UTILS & HELPERS ***/
import { free as qbanks } from '../../../_CONS/qbanks';

/*** SCREENS  ***/

class Free extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qpath: 'free/study-civ',
      qbank: 'study-civ',
    };
    this.addNewQuestion = this.addNewQuestion.bind(this);
  }

  // static getDerivedStateFromProps(props, state) {
  //   return { qbank: props.qbank };
  // }

  componentDidMount() {
    const { section } = this.props;
    if (section !== 'free') {
      const qbank = qbanks[0].value;
      const qpath = `free/${qbank}`;
      const uObj = {
        section: 'free',
        level: 'free',
        qbank,
        view: 'list',
        qpath,
      };

      this.props.sectionReset(uObj).then(() => {
        this.setState({ qpath, qbank });
      });
    }
  }

  handleChange = event => {
    const qbank = event.target.value;
    const qpath = `free/${qbank}`;
    const uObj = {
      section: 'free',
      level: 'free',
      qbank,
      qpath,
    };

    // this.props.setQbank(uObj);
    this.props.setQbank(uObj).then(() => {
      this.setState({ qbank, qpath });
    });
    // this.setState({ [event.target.name]: event.target.value });
  };

  addNewQuestion(e) {
    const { history, level, section, qbank } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: section,
      level: level,
      qbank: qbank,
      view: 'new',
      qid: '',
    };
    this.props
      .setSection(sObj)
      .then(() => history.push(`/admin/${section}/new`));
  }
  render() {
    const { qpath, qbank } = this.state;
    return (
      <MainScreen
        title="Trial Questions"
        rightSideItem={
          <Button
            onClick={this.addNewQuestion}
            color="primary"
            variant="contained"
            aria-label="Add Question"
          >
            Add Question
          </Button>
        }
      >
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Select
                value={this.state.qbank || ''}
                onChange={this.handleChange}
                name="qbank"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>Select Question Bank</em>
                </MenuItem>

                {qbanks.map(item => {
                  return (
                    <MenuItem
                      key={item.value}
                      name={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </CardContent>
            <Divider />
            {qpath && (
              <QuestionsTable
                qpath={qpath}
                qbank={qbank}
                section="free"
                level="free"
              />
            )}
          </Card>
        </Grid>
      </MainScreen>
    );
  }
}
Free.propTypes = {
  section: PropTypes.string,
  level: PropTypes.string,
  qbank: PropTypes.string,
  status: PropTypes.string,
};

// export default withStyles(styles)(Test);
const mapStateToProps = (state, ownProps) => {
  // const lastSection = state.questionStatus.section;
  // console.log(`lastSection=${lastSection}`);
  // const newSection = lastSection !== 'free';
  // const lastQbank = state.questionStatus.qbank
  //   ? state.questionStatus.qbank
  //   : '';

  return {
    qbank: state.questionStatus.qbank,
    level: state.questionStatus.level,
    section: state.questionStatus.section,
    status: state.questionStatus.status,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sectionReset: uObj => dispatch(sectionReset(uObj)),
    setQbank: uObj => dispatch(setQbank(uObj)),
    setSection: sObj => dispatch(setSection(sObj)),
  };
};
export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
  // withStyles(styles)
)(Free);
