// import React from 'react';
// import PropTypes from 'prop-types';
/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UI LIB  ***/
import MuiToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
/*** UTILS & HELPERS ***/
// import { color } from '../../../styles/style';

const ToggleBtnGroup = styled(MuiToggleButtonGroup)`
  &&&&&& {
    display: flex;
    justify-content: space-between;
    box-shadow: none;
  }
`;

// const ToggleBtnGroup = () => {
//   return <div>ToggleBtnGroup</div>;
// };
//
// ToggleBtnGroup.propTypes = {};

export default ToggleBtnGroup;
