import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { NavLink, Switch, Route } from 'react-router-dom';
import { NavLink, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

/**
 * Styled-Components
 */
import styled from 'styled-components/macro';

import { color } from '../../styles/style';

import NavAppBar from '../../components/NavAppBar';

import Dashboard from './Dashboard';
// import Study from './Study';
// import Review from './Review';
// import Test from './Test';
import Profile from './Profile';

const NavBtn = styled(NavLink)`
  text-decoration: none;
  margin: 0 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: 290486px;
  letter-spacing: 1.7px;
  font-size: 0.75em;
  text-transform: uppercase;
  font-weight: 700;
  color: ${color.blueLight};
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &.active {
    color: ${color.primary};
    background-color: ${color.grey};
  }
  &:hover {
    color: ${color.primary};
  }
`;

// const NavBtn = styled(NavLink)`
//   text-decoration: none;
//   margin: 0 0.25rem;
//   padding: 0.5rem 1rem;
//   border-radius: 290486px;
//   letter-spacing: 2px;
//   font-size: 75%;
//   text-transform: uppercase;
//   font-weight: 600;
//   color: ${color.blueLight};
//   &.active {
//     color: ${color.primary};
//     background-color: ${color.grey};
//   }
// `;
// const Nav = styled.nav`
//   display: flex;
//   height: auto;
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%, 0);
// `;
// const Nav = styled.nav`
//   height: 100%;
//   display: flex;
//   align-items: center;
// `;

const Container = styled.div`
  max-height: 100vh;
  height: calc(100vh - 4rem);
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin: 0;
`;

class StudentPathway extends Component {
  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    // const { auth, anchorEl } = this.state;
    // const open = Boolean(anchorEl);
    const { auth } = this.state;
    const { match } = this.props;
    console.log(`Studentmatch= ${JSON.stringify(match)}`);
    return (
      <>
        <NavAppBar auth={auth}>
          <NavBtn exact to={`${match.url}`}>
            DASHBOARD
          </NavBtn>
        </NavAppBar>

        <Container>
          <Switch>
            <Route path={`${match.url}/profile`} component={Profile} />
            <Route exact path={`${match.url}`} component={Dashboard} />
          </Switch>
        </Container>
      </>
    );
  }
}
StudentPathway.propTypes = {
  studentId: PropTypes.string,
};

// export default withRouter(Student);

const mapStateToProps = state => {
  return {
    studentId: state.auth.uid,
  };
};
export default connect(mapStateToProps)(StudentPathway);
