import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
/*** ROUTER  ***/
import { Link } from 'react-router-dom';
/*** STYLE  ***/

/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
/*** CONTAINERS  ***/
import { MainScreen } from '../../../containers/Main';
import { Card, CardHeader, CardContent } from '../../../containers/Card';
/*** GLOBAL COMPONENTS  ***/
// import ExpandPanels from '../../../components/ExpandPanels';
/*** LOCAL COMPONENTS  ***/
/*** ELEMENTS  ***/
import Button from '../../../elements/Button';
/*** ACTIONS  ***/
import { setSection } from '../../../store/actions/questionActions';
/*** UTILS & HELPERS ***/
// import { color, fontWeight } from '../../../styles/style';
/*** SCREENS  ***/
import Promos from '../../../screens/Promos';
//import QuestionSearch from '../../../screens/QuestionSearch';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    // this.importFreeQuestions = this.importFreeQuestions.bind(this);

    this.handleAddTestQuestion = this.handleAddTestQuestion.bind(this);
    this.handleAddStudyQuestion = this.handleAddStudyQuestion.bind(this);
    this.handleAddFreeQuestion = this.handleAddFreeQuestion.bind(this);
  }

  handleAddFreeQuestion(e) {
    const { history } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: 'free',
      level: 'free',
      qbank: '',
      view: 'new',
      qid: '',
    };
    this.props.setSection(sObj).then(() => history.push('/admin/free/new'));
  }
  handleAddStudyQuestion(e) {
    const { history } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: 'study',
      level: 'paid',
      qbank: '',
      view: 'new',
      qid: '',
    };
    this.props.setSection(sObj).then(() => history.push('/admin/study/new'));
  }
  handleAddTestQuestion(e) {
    const { history } = this.props;
    e.preventDefault();
    const sObj = {
      status: 'loading',
      section: 'test',
      level: 'paid',
      qbank: '',
      view: 'new',
      qid: '',
    };
    this.props.setSection(sObj).then(() => history.push('/admin/test/new'));
  }
  render() {
    const { match } = this.props;
    return (
      <MainScreen title="Dashboard">
        <Grid item xs={4}>
          <Card>
            <CardHeader
              action={
                <IconButton component={Link} to={`${match.url}/study`}>
                  <ChevronRight />
                </IconButton>
              }
              title="Study Questions"
            />
            <Divider />

            <CardContent>
              <Button
                onClick={this.handleAddStudyQuestion}
                color="primary"
                variant="contained"
                aria-label="Add Question"
              >
                Add Question
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader
              action={
                <IconButton component={Link} to={`${match.url}/test`}>
                  <ChevronRight />
                </IconButton>
              }
              title="Test Questions"
            />
            <Divider />
            <CardContent>
              <Button
                onClick={this.handleAddTestQuestion}
                color="primary"
                variant="contained"
                aria-label="Add Question"
              >
                Add Question
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader
              action={
                <IconButton component={Link} to={`${match.url}/free`}>
                  <ChevronRight />
                </IconButton>
              }
              title="Free Questions"
            />
            <Divider />
            <CardContent>
              <Button
                onClick={this.handleAddFreeQuestion}
                color="primary"
                variant="contained"
                aria-label="Add Question"
              >
                Add Question
              </Button>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={12}>
          <QuestionSearch />
        </Grid> */}
        <Grid item xs={12}>
          <Promos />
        </Grid>
      </MainScreen>
    );
  }
}

Dashboard.propTypes = {
  // adminId: PropTypes.string.isRequired,
  setSection: PropTypes.func.isRequired,
};
// const mapStateToProps = state => {
//   return {
//     adminId: state.auth.uid,
//   };
// };
const mapDispatchToProps = dispatch => {
  return {
    setSection: sObj => dispatch(setSection(sObj)),
    // addFreeQuestions: () => dispatch(addFreeQuestions()),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(Dashboard);
