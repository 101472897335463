import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UI LIBS  ***/
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
/*** CONTAINERS  ***/
/*** GLOBAL COMPONENTS  ***/
/*** ELEMENTS  ***/
/*** ASSETS  ***/
import Logo from '../../assets/logos/JD-Advising_Logo.svg';
/*** LOCAL COMPONENTS  ***/
import UserMenu from './UserMenu';
const Nav = styled.nav`
  display: flex;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

const LogoImage = styled.img`
  width: 155px;
`;

const NavAppBar = props => {
  return (
    <AppBar position="relative" color="default">
      <Toolbar variant="regular">
        <LogoImage src={Logo} alt="logo" />

        <Nav>{props.children}</Nav>
        {props.auth && <UserMenu />}
      </Toolbar>
    </AppBar>
  );
};

NavAppBar.propTypes = {
  auth: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default NavAppBar;
