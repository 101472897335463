import React, { Component } from 'react';
//import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
// import { compose } from 'redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
/*** ROUTER  ***/

/*** STYLE  ***/

/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
/*** CONTAINERS  ***/
import { MainScreen } from '../../../../containers/Main';

/*** GLOBAL COMPONENTS  ***/
import Loading from '../../../../components/Loading';
import StudentTable from '../../../../components/StudentTable';
// import TableMain from '../../../../components/TableMain';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import TextEmptyState from '../../../../elements/TextEmptyState';
import Button from '../../../../elements/Button';
/*** ACTIONS & APIs ***/
//import { fetchAllStudents } from '../../../../store/actions/studentActions';
//import { mbeFS } from '../../../../store/api/fb';
import firebaseAdmin from '../../../../store/api/firebaseAdmin';
import { setAppStatus } from '../../../../store/actions/appActions';
/*** UTILS & HELPERS ***/
// import { color, fontWeight } from '../../../../styles/style';
/*** SCREENS  ***/

const newCols = [
  { id: 'lastName', align: 'left', disablePadding: false, label: 'Last Name' },
  {
    id: 'firstName',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'email',
  },

  { id: 'accountType', align: 'left', disablePadding: false, label: 'type' },
  { id: 'isActive', align: 'left', disablePadding: false, label: 'active' },
  { id: 'menu', align: 'center', disablePadding: false, label: '' },
];
const otherCols = [
  { id: 'lastName', align: 'left', disablePadding: false, label: 'Last Name' },
  {
    id: 'firstName',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'email',
    align: 'left',
    disablePadding: false,
    label: 'email',
  },

  { id: 'isActive', align: 'left', disablePadding: false, label: 'active' },
  { id: 'score', align: 'left', disablePadding: false, label: 'Score' },
  { id: 'menu', align: 'center', disablePadding: false, label: '' },
];
class List extends Component {
  constructor(props) {
    super(props);
    this.refUsers = null;
    this.newUsers = null;
    this.state = {
      tabIndex: 0,
      newStudents: [],
      freeStudents: [],
      paidStudents: [],
      isLoading: true,
    };
    this.goToScreen = this.goToScreen.bind(this);
  }
  onCollectionUpdate = querySnapshot => {
    const newStudents = [];
    const freeStudents = [];
    const paidStudents = [];
    querySnapshot.forEach(doc => {
      const { status, accountType } = doc.data();

      if (status === 'new') {
        newStudents.push({
          ...doc.data(),
          uid: doc.id,
        });
      }
      if (accountType === 'paid' || accountType === 'pro') {
        paidStudents.push({
          ...doc.data(),
          uid: doc.id,
        });
      }
      if (accountType === 'free' || accountType === 'trial') {
        freeStudents.push({
          ...doc.data(),
          uid: doc.id,
        });
      }
    });
    this.setState({
      newStudents,
      paidStudents,
      freeStudents,
    });
  };
  componentDidMount() {
    this.refUsers = firebaseAdmin
      .firestore()
      .collection('students')
      .onSnapshot(this.onCollectionUpdate);

    this.props.setAppStatus({
      view: 'student',
      action: 'list',
      status: 'list',
      loading: false,
      error: null,
      data: {},
    });

    // this.newUsers = this.refUsers
    //   .where('status', '==', 'new')
    //   .onSnapshot(this.onCollectionUpdate);
    // this.newUsers = this.refUsers
    //   .where('status', '==', 'new')
    //   .onSnapshot(this.onCollectionUpdate);
    // this.props
    //   .fetchAllStudents()
    //   .then(() => this.setState({ isLoading: false }));
  }
  componentWillUnmount() {
    this.refUsers();
    //this.newUsers();
  }
  // componentDidMount() {
  //   this.props
  //     .fetchAllStudents()
  //     .then(() => this.setState({ isLoading: false }));
  // }
  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };
  goToScreen(e) {
    const { match, history } = this.props;

    history.push(`${match.url}/new`);

    e.preventDefault();
  }
  render() {
    const { tabIndex, newStudents, freeStudents, paidStudents } = this.state;

    return (
      <MainScreen
        title="Students"
        rightSideItem={
          <Button
            color="primary"
            variant="contained"
            aria-label="Add Student"
            onClick={this.goToScreen}
          >
            Add Student
          </Button>
        }
      >
        <Grid item xs={12}>
          <Card>
            <Tabs
              value={tabIndex}
              onChange={this.handleChange}
              // classes={{
              //   root: classes.tabsRoot,
              //   indicator: classes.tabsIndicator,
              // }}
            >
              <Tab
                disableRipple
                // classes={{
                //   root: classes.tabRoot,
                //   selected: classes.tabSelected,
                // }}
                label="NEW"
              />
              <Tab
                disableRipple
                // classes={{
                //   root: classes.tabRoot,
                //   selected: classes.tabSelected,
                // }}
                label="TRIAL"
              />
              <Tab
                disableRipple
                // classes={{
                //   root: classes.tabRoot,
                //   selected: classes.tabSelected,
                // }}
                label="PRO"
              />
            </Tabs>
            {tabIndex === 0 &&
              (!isLoaded(newStudents) ? (
                <Loading />
              ) : isEmpty(newStudents) ? (
                <TextEmptyState>No New Students</TextEmptyState>
              ) : (
                <StudentTable
                  colHeaders={newCols}
                  data={newStudents}
                  section="new"
                />
              ))}
            {tabIndex === 1 &&
              (!isLoaded(freeStudents) ? (
                <Loading />
              ) : isEmpty(freeStudents) ? (
                <TextEmptyState>No Trial Students</TextEmptyState>
              ) : (
                <StudentTable
                  colHeaders={otherCols}
                  data={freeStudents}
                  section="free"
                />
              ))}
            {tabIndex === 2 &&
              (!isLoaded(paidStudents) ? (
                <Loading />
              ) : isEmpty(paidStudents) ? (
                <TextEmptyState>No Pro Students</TextEmptyState>
              ) : (
                <StudentTable
                  colHeaders={otherCols}
                  data={paidStudents}
                  section="paid"
                />
              ))}
          </Card>
        </Grid>
      </MainScreen>
    );
  }
}

const mapStateToProps = state => {
  return {
    appStatus: state.appStatus,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setAppStatus: appObj => dispatch(setAppStatus(appObj)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
