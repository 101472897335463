import React, { Component } from 'react';
//import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
/*** ROUTER  ***/
/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UI LIBS  ***/
/*** ACTIONS & APIs ***/
/*** UTILS & HELPERS ***/
import { color, util } from '../../../../styles/style';
/*** SCREENS  ***/

const SideBarWrapper = styled.aside`
  background-color: ${color.white};
  width: 100%;
  z-index: 5;
  position: relative;
`;

// const SideBarTools = styled.div``;
const SideBarExtras = styled.div`
  padding: ${util.padding};
`;

const Line = styled.hr`
  background-color: ${color.blueLightest};
  border: 0;
  height: 1px;
  width: 100%;
`;

class PreviewSidebar extends Component {
  render() {
    return (
      <SideBarWrapper>
        <Line />
        {this.props.children && (
          <SideBarExtras>{this.props.children}</SideBarExtras>
        )}
      </SideBarWrapper>
    );
  }
}

PreviewSidebar.propTypes = {
  //children: PropTypes.node.isRequired,
};
// Specifies the default values for props:
PreviewSidebar.defaultProps = {};
export default PreviewSidebar;
