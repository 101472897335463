const initState = [];

const studentsPaid = (state = initState, action) => {
  switch (action.type) {
    case 'STUDENT_LIST_PAID':
      console.log(`STUDENT_LIST_PAID`);
      return action.payload;
    default:
      return state;
  }
};

export default studentsPaid;
