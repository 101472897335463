import styled from 'styled-components/macro';
import { color, fontWeight, fontSize } from '../../../../../styles/style';
export const FeedbackCopy = styled.p`
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: ${fontWeight.semiBold};
  vertical-align: baseline;
  line-height: 1.5;
  margin-bottom: 1rem;
  font-size: ${fontSize.feedbackCopy};
  color: ${color.dark};
  letter-spacing: 0;
`;
FeedbackCopy.propTypes = {};
export default FeedbackCopy;
