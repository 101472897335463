import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
/* style lib */

/* UI lib components */
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

/* GLOBAL ELEMENTS */
// import SignUpCta from '../../../elements/SignUpCta';
import AuthErrorTxt from '../../../../elements/AuthErrorTxt';
// import LandingInfoText from '../../../elements/LandingInfoText';
/* STYLES & UTILS */
// import { forgotPassword } from '../../../store/actions/authActions';

// const SignUpCta = styled.div`
//   font-size: ${fontType.copy};
//   font-weight: ${fontWeight.semiBold};
//   margin: 1.5rem 0;
//   text-align: center;
// `;

class ResetPassword extends Component {
  state = {
    newEmail: '',
    newPassword: '',
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handlePasswordSubmit = e => {
    e.preventDefault();
    const { newPassword } = this.state;
    const uObj = {
      password: newPassword,
    };
    this.props.formSubmit(true, uObj);
  };
  handleEmailSubmit = e => {
    e.preventDefault();
    const { newEmail } = this.state;
    const uObj = {
      email: newEmail,
    };
    this.props.formSubmit(false, uObj);
  };
  render() {
    const { authError, hasErrors, email } = this.props;
    const { newEmail, newPassword } = this.state;

    if (hasErrors) {
      return (
        <form onSubmit={this.handleEmailSubmit}>
          <AuthErrorTxt>{authError ? authError.message : null}</AuthErrorTxt>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="newEmail"
              name="newEmail"
              value={newEmail}
              autoComplete="newEmail"
              autoFocus
              onChange={this.handleChange}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <Button
              type="submit"
              variant="contained"
              fullWidth={true}
              color="primary"
            >
              Send Reset Email
            </Button>
          </FormControl>
        </form>
      );
    }
    return (
      <form onSubmit={this.handlePasswordSubmit}>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input id="email" value={email} name="email" readOnly />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="newPassword">Password</InputLabel>
          <Input
            name="newPassword"
            type="password"
            id="newPassword"
            value={newPassword}
            autoComplete="current-password"
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <Button
            type="submit"
            variant="contained"
            fullWidth={true}
            color="primary"
          >
            New Password
          </Button>
        </FormControl>
      </form>
    );
  }
}

ResetPassword.propTypes = {};

export default ResetPassword;
