import React from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
// import { compose } from 'redux';
// import { connect } from 'react-redux';
/*** ROUTER  ***/

/*** STYLE  ***/
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
/*** CONTAINERS  ***/
/*** GLOBAL COMPONENTS  ***/
import Loading from '../../components/Loading';
/*** LOCAL COMPONENTS  ***/
// import Content from './Content';
/*** ELEMENTS  ***/
/*** ACTIONS & APIs ***/
// import {
//   createNewStudent,
//   removeStudent,
//   updateStudent,
// } from '../../store/actions/studentActions';
/*** UTILS & HELPERS ***/
/*** SCREENS  ***/

const DialogContentWorking = withStyles(theme => ({
  root: {
    margin: 0,
    height: '100%',

    padding: '6rem',
  },
}))(MuiDialogContent);

class LoadingModal extends React.Component {
  render() {
    const { open } = this.props;

    return (
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
      >
        <DialogContentWorking>
          <Loading />
        </DialogContentWorking>
      </Dialog>
    );
  }
}

LoadingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  // status: PropTypes.string.isRequired,
};

export default LoadingModal;
