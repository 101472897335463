import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
// import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
/*** CONTAINERS  ***/
import { MainScreen } from '../../../../containers/Main';
import { Card, CardContent } from '../../../../containers/Card';
/*** GLOBAL COMPONENTS  ***/
import DialogModalStudent from '../../../../components/DialogModalStudent';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import FlushLinkButton from '../../../../elements/FlushLinkButton';
import SimpleBtn from '../../../../elements/SimpleBtn';
/*** ACTIONS  ***/
//import { mbeFS } from '../../../../store/api/fb';
import firebaseStudent from '../../../../store/api/firebaseStudent';
import { updateStudent } from '../../../../store/actions/studentActions';
/*** UTILS & HELPERS ***/

/*** SCREENS  ***/

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,

    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;
const initialState = {
  dialogStatus: 'ready',
  dialogType: 'preview',
  isDialogOpen: false,
  email: '',
  password: '',
  password2: '',
  firstName: '',
  lastName: '',
  initials: '',
  role: 'student',
  status: 'new',
  accountType: '',
  accountLevel: '',
  studentObj: {},
};
// import Typography from '@material-ui/core/Typography';
class Update extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpgraded: false,
      isSuspended: false,
      isLoading: true,
      dialogStatus: 'ready',
      dialogType: 'update',
      isDialogOpen: false,
      email: '',
      password: '',
      password2: '',
      firstName: '',
      lastName: '',
      initials: '',
      role: '',
      status: '',
      accountType: '',
      prevAccountType: '',
      prevStatus: '',
      studentObj: {},
    };
    this.goBackScreen = this.goBackScreen.bind(this);
  }
  componentDidMount() {
    const { currentId } = this.props;
    const studentFS = firebaseStudent.firestore();
    const ref = studentFS.collection('users').doc(currentId);
    ref.get().then(doc => {
      if (doc.exists) {
        const student = doc.data();

        this.setState({
          email: student.email,
          firstName: student.firstName,
          lastName: student.lastName,
          initials: student.initials,
          status: student.status,
          accountType: student.accountType,
          prevFirstName: student.firstName,
          prevLastName: student.lastName,
          prevStatus: student.status,
          prevAccountType: student.accountType,
          isLoading: false,
        });
      } else {
        console.log('No such document!');
      }
    });
  }

  goBackScreen(e) {
    const { history } = this.props;
    history.push(`/admin/students`);
    e.preventDefault();
  }
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleSelectChangeAccountType = event => {
    console.log(`handleSelectChangeAccountType=${event.target.value}`);
    this.setState({ accountType: event.target.value });
  };
  handleSelectChangeStatus = event => {
    this.setState({ status: event.target.value });
  };
  handleDialogCancel = () => {
    console.log(`handle CANCEL`);
    this.setState({ isDialogOpen: false });
    // this.cancelFooterAction();
  };
  handleDialogClose = () => {
    console.log(`handle CLOSE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogDone = () => {
    console.log(`handle DONE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogAction = () => {
    console.log(`handle ACTION`);
    this.setState({ ...initialState });
    // this.postFooterAction();
  };
  handleUpgrade = e => {
    console.log(`handle UPGRADE`);
    e.preventDefault();
    this.setState({ accountType: 'pro', status: 'new', isUpgraded: true });
    // this.props.cancelGuideAction();
  };
  handleUpgradeReset = e => {
    console.log(`handle UPGRADE reset`);
    const { prevAccountType, prevStatus } = this.state;
    e.preventDefault();
    this.setState({
      accountType: prevAccountType,
      status: prevStatus,
      isUpgraded: false,
    });
    // this.props.cancelGuideAction();
  };
  handleSuspend = e => {
    console.log(`handle SUSPEND`);
    e.preventDefault();
    this.setState({
      status: 'suspend',
      isSuspended: true,
    });
    // this.props.cancelGuideAction();
  };

  handleSuspendReset = e => {
    console.log(`handle SUSPEND`);
    const { prevStatus } = this.state;
    e.preventDefault();
    this.setState({
      status: prevStatus,
      isSuspended: false,
    });
    // this.props.cancelGuideAction();
  };
  handleSubmit = e => {
    e.preventDefault();
    console.log(`state = ${JSON.stringify(this.state)}`);
    const { firstName, lastName, status, accountType, isUpgraded } = this.state;
    const { currentId } = this.props;

    // console.log(`state = ${JSON.stringify(sobj)}`);

    const mainSobj = {
      uid: currentId,
      firstName,
      lastName,
      status,
      accountType,
    };
    let sobj;
    if (isUpgraded) {
      sobj = { ...mainSobj, upgradedDate: new Date(), upgraded: true };
    } else {
      sobj = { ...mainSobj };
    }
    this.setState({
      studentObj: sobj,
      dialogType: 'update',
      dialogStatus: 'ready',
      isDialogOpen: true,
    });
    // this.props.createNewStudent(this.state);
  };

  render() {
    const {
      dialogType,
      dialogStatus,
      isDialogOpen,
      email,
      firstName,
      lastName,
      accountType,
      studentObj,
      status,
      prevFirstName,
      prevLastName,
      prevStatus,
      prevAccountType,
      isSuspended,
      isUpgraded,
    } = this.state;
    const { classes, error } = this.props;

    const isDirty =
      prevFirstName !== firstName ||
      prevLastName !== lastName ||
      prevStatus !== status ||
      prevAccountType !== accountType;

    return (
      <>
        <DialogModalStudent
          type={dialogType}
          open={isDialogOpen}
          item={studentObj}
          status={dialogStatus}
          handleClose={this.handleDialogClose}
          handleCancel={this.handleDialogCancel}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />
        <Screen>
          <MainScreen
            title="Update Student"
            leftSideItem={
              <FlushLinkButton onClick={this.goBackScreen}>
                <ArrowBackIcon />
                BACK
              </FlushLinkButton>
            }
            spacing={24}
          >
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <form onSubmit={this.handleSubmit}>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel htmlFor="email">Email Address</InputLabel>
                      <Input id="email" name="email" value={email} readOnly />
                    </FormControl>

                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="firstName">First Name</InputLabel>
                      <Input
                        type="text"
                        name="firstName"
                        value={firstName}
                        id="firstName"
                        onChange={this.handleChange}
                      />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                      <InputLabel htmlFor="lastName">Last Name</InputLabel>
                      <Input
                        type="text"
                        name="lastName"
                        value={lastName}
                        id="lastName"
                        onChange={this.handleChange}
                      />
                    </FormControl>
                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="account-readonly">
                          Account Type
                        </InputLabel>
                        <Input
                          id="account-readonly"
                          name="account-readonly"
                          value={accountType}
                          readOnly
                        />
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        {!isUpgraded ? (
                          <SimpleBtn
                            disabled={accountType === 'pro' || isSuspended}
                            variant="contained"
                            type="secondary"
                            fullWidth
                            onClick={this.handleUpgrade}
                          >
                            Upgrade
                          </SimpleBtn>
                        ) : (
                          <SimpleBtn
                            variant="outlined"
                            type="reset"
                            fullWidth
                            onClick={this.handleUpgradeReset}
                          >
                            Reset
                          </SimpleBtn>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="status-readonly" disableAnimation>
                          Account Type
                        </InputLabel>
                        <Input
                          id="status-readonly"
                          name="status-readonly"
                          value={status}
                          readOnly
                        />
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        {!isSuspended ? (
                          <SimpleBtn
                            variant="contained"
                            fullWidth
                            type="remove"
                            disabled={status === 'suspend' || isUpgraded}
                            onClick={this.handleSuspend}
                          >
                            Suspend
                          </SimpleBtn>
                        ) : (
                          <SimpleBtn
                            variant="outlined"
                            fullWidth
                            type="reset"
                            onClick={this.handleSuspendReset}
                          >
                            Reset
                          </SimpleBtn>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl margin="normal" fullWidth>
                        <div>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={!isDirty}
                          >
                            Update Student
                          </Button>
                          <div className="center red-text">
                            {error ? <p>{error}</p> : null}
                          </div>
                        </div>
                      </FormControl>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </MainScreen>
        </Screen>
      </>
    );
  }
}

Update.propTypes = {
  updateStudent: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    currentId: state.studentStatus.currentId,
    sbank: state.studentStatus.sbank,
    status: state.studentStatus.status,
    currentStudent: state.studentStatus.current,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateStudent: sobj => dispatch(updateStudent(sobj)),
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Update);
