import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
// import { connect } from 'react-redux';
import { compose } from 'redux';

/*** ROUTER  ***/

/*** STYLE  ***/
// import styled from 'styled-components/macro';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
/*** UI LIBS  ***/
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
/*** CONTAINERS  ***/
// import { MainScreen } from '../../../../containers/Main';
import { CardContent } from '../../../../../containers/Card';
/*** GLOBAL COMPONENTS  ***/
// import AvatarCard from '../../../../../components/AvatarCard';
/*** LOCAL COMPONENTS  ***/
/*** ELEMENTS  ***/
import SimpleBtn from '../../../../../elements/SimpleBtn';
// import FlushLinkButton from '../../../../elements/FlushLinkButton';
/*** ACTIONS  and APIs ***/
// import {
//   fetchStudentById,
//   sendOutNewUsuerEmail,
// } from '../../../../store/actions/studentActions';
// import { mbeFS } from '../../../../store/api/fb';
/*** UTILS & HELPERS ***/
import { color } from '../../../../../styles/style';
import { formatDateTime } from '../../../../../utils/formatDateTime';
/*** SPECIAL DATA  ***/
// import StatsRowFlat from '../../../../data/StatsRowFlat';
// import StatsRowCard from '../../../../data/StatsRowCard';
/*** SCREENS  ***/

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  emptyState: {
    textAlign: 'center',
    padding: '2rem',
    color: '#BFCCD9',
    fontWeight: 700,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    // backgroundColor: color.primary,
    // '&:hover': {
    //   backgroundColor: color.primaryDarker,
    // },
  },

  buttonProgress: {
    color: color.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
class Profile extends Component {
  constructor(props) {
    super(props);
    let atype;
    if (props.student.accountType === 'free') {
      atype = 'trial';
    } else if (props.student.accountType === 'paid') {
      atype = 'pro';
    } else {
      atype = props.student.accountType;
    }

    this.state = {
      isLoading: true,
      dialogStatus: 'ready',
      dialogType: 'update',
      isDialogOpen: false,
      studentId: props.student.uid,
      email: props.student.email,
      firstName: props.student.firstName,
      lastName: props.student.lastName,
      status: props.student.status,
      prevStatus: props.student.status,
      accountType: atype,
      prevAccountType: atype,
      isActive: props.student.isActive,
      // prevFirstName: props.student.firstName,
      // prevLastName: props.student.lastName,
      // prevStatus: props.student.status,
      // prevAccountType: props.student.accountType,
      startDate: props.student.startDate,
      updatedDate: props.student.updatedDate,
      isSuspended: false,
      isUnSuspended: false,
      isUpgraded: false,
    };
    // this.handleSubmitBtn = this.handleSubmitBtn.bind(this);
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handleUpgrade = e => {
    //  console.log(`handle UPGRADE`);
    e.preventDefault();
    this.setState({ accountType: 'pro', status: 'new', isUpgraded: true });
    // this.props.cancelGuideAction();
  };
  handleUpgradeReset = e => {
    console.log(`handle UPGRADE reset`);
    const { prevAccountType, prevStatus } = this.state;
    e.preventDefault();
    this.setState({
      accountType: prevAccountType,
      status: prevStatus,
      isUpgraded: false,
    });
    // this.props.cancelGuideAction();
  };
  handleSuspend = e => {
    // console.log(`handle SUSPEND`);
    e.preventDefault();
    this.setState({
      isSuspended: true,
      isActive: false,
    });
    // this.props.cancelGuideAction();
  };
  handleSuspendReset = e => {
    // console.log(`handle SUSPEND RESET`);

    e.preventDefault();
    this.setState({
      isActive: true,
      isSuspended: false,
    });
    // this.props.cancelGuideAction();
  };
  handleUnSuspend = e => {
    // console.log(`handle UNSUSPEND`);
    e.preventDefault();
    this.setState({
      isUnSuspended: true,
      isActive: true,
    });
    // this.props.cancelGuideAction();
  };

  handleUnSuspendReset = e => {
    // console.log(`handle UNSUSPEND RESET`);
    // const { prevStatus } = this.state;
    e.preventDefault();
    this.setState({
      isUnSuspended: false,
      isActive: false,
    });
    // this.props.cancelGuideAction();
  };
  // handleSubmit = e => {
  //   e.preventDefault();
  //   console.log(`state = ${JSON.stringify(this.state)}`);
  //   const { studentId, firstName, lastName, status, accountType } = this.state;
  //   console.log(`studentId = ${studentId}`);
  //
  //   const sobj = {
  //     uid: studentId,
  //     firstName,
  //     lastName,
  //     status,
  //     accountType,
  //   };
  //   this.props.action(e, sobj);
  // };
  handleSubmitBtn = e => {
    e.preventDefault();
    // console.log(`state = ${JSON.stringify(this.state)}`);
    const { firstName, lastName, status, accountType, isActive } = this.state;
    const { studentId } = this.props;
    // console.log(`studentUID = ${student.uid}`);
    // console.log(`student.id = ${student.id}`);
    // console.log(`studentId = ${studentId}`);
    const sobj = {
      uid: studentId,
      firstName,
      lastName,
      status,
      accountType,
      isActive,
    };
    this.props.actionUpdate(e, sobj);
  };

  resendEmail = (event, email) => {
    this.setState({ btnLoading: true });
    event.preventDefault();
    this.props.actionEmail(email).then(() => {
      this.setState({
        btnLoading: false,
        btnSuccess: true,
      });
    });
  };

  render() {
    const {
      // dialogType,
      // dialogStatus,
      // isDialogOpen,
      email,
      firstName,
      lastName,
      accountType,
      status,
      //updatedDate,
      // startDate,
      // prevFirstName,
      // prevLastName,
      // prevStatus,
      // prevAccountType,
      isSuspended,
      isUnSuspended,
      isUpgraded,
      // isLoading,
      btnLoading,
      btnSuccess,
      studentId,
      isActive,
    } = this.state;
    const { classes, student, isUpdating } = this.props;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: btnSuccess,
    });
    const isDirty =
      student.firstName !== firstName ||
      student.lastName !== lastName ||
      student.status !== status ||
      student.accountType !== accountType ||
      student.isActive !== isActive;
    // console.log(`student.isActive = ${student.isActive}`);
    return (
      <form>
        <CardContent>
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="firstName">First Name</InputLabel>
                <Input
                  type="text"
                  name="firstName"
                  value={firstName}
                  id="firstName"
                  onChange={this.handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <Input
                  type="text"
                  name="lastName"
                  value={lastName}
                  id="lastName"
                  onChange={this.handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={24}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Email"
                  value={email}
                  InputProps={{
                    readOnly: true,
                  }}
                  // autoComplete="Jerry"
                  // autoFocus
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {student.status === 'new' && isActive === true && (
                <div className={classes.wrapper}>
                  <Button
                    onClick={e => this.resendEmail(e, email)}
                    variant="text"
                    color="primary"
                    className={buttonClassname}
                    disabled={btnLoading}
                  >
                    Re-Send Email
                  </Button>
                  {btnLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={24}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Start Date"
                  value={formatDateTime(student.startDate)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Last Updated"
                  value={formatDateTime(student.updatedDate)}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Account Type"
                  value={accountType}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // autoComplete="Jerry"
                  // autoFocus
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                {!isUpgraded ? (
                  <SimpleBtn
                    disabled={accountType === 'pro' || isUpgraded}
                    variant="text"
                    type="secondary"
                    fullWidth
                    onClick={this.handleUpgrade}
                  >
                    Upgrade
                  </SimpleBtn>
                ) : (
                  <SimpleBtn
                    variant="text"
                    type="reset"
                    fullWidth
                    onClick={this.handleUpgradeReset}
                  >
                    Reset
                  </SimpleBtn>
                )}
              </FormControl>
            </Grid>
            {student.isActive && (
              <>
                {!isSuspended ? (
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Status"
                          value={status}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // autoComplete="Jerry"
                          // autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <SimpleBtn
                          variant="text"
                          fullWidth
                          type="remove"
                          disabled={status === 'suspend' || isSuspended}
                          onClick={this.handleSuspend}
                        >
                          Suspend
                        </SimpleBtn>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  //isSuspended = true
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Status"
                          defaultValue="SUSPENDED"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // autoComplete="Jerry"
                          // autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <SimpleBtn
                          variant="text"
                          fullWidth
                          type="reset"
                          onClick={this.handleSuspendReset}
                        >
                          Reset
                        </SimpleBtn>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            )}

            {!student.isActive && (
              <>
                {!isUnSuspended ? (
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Status"
                          defaultValue="SUSPENDED"
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // autoComplete="Jerry"
                          // autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <SimpleBtn
                          variant="text"
                          fullWidth
                          type="remove"
                          disabled={status === 'suspend' || isUnSuspended}
                          onClick={this.handleUnSuspend}
                        >
                          UN-Suspend
                        </SimpleBtn>
                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  //isUnSuspended = true
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Status"
                          value={status}
                          InputProps={{
                            readOnly: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // autoComplete="Jerry"
                          // autoFocus
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl className={classes.formControl}>
                        <SimpleBtn
                          variant="text"
                          fullWidth
                          type="reset"
                          onClick={this.handleUnSuspendReset}
                        >
                          Reset
                        </SimpleBtn>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <Button
            onClick={e => this.handleSubmitBtn(e, studentId)}
            variant="contained"
            color="primary"
            disabled={!isDirty}
          >
            Update Student
          </Button>
        </CardContent>
      </form>
    );
  }
}

Profile.propTypes = {
  studentId: PropTypes.string,
  student: PropTypes.object,
  actionUpdate: PropTypes.func,
  actionEmai: PropTypes.func,
};

export default compose(withStyles(styles))(Profile);
