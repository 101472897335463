import React, { Component } from 'react';
import PropTypes from 'prop-types';

/*** DATA, STORE and STATE  ***/
import { compose } from 'redux';
import { connect } from 'react-redux';
/*** ROUTER  ***/
import { withRouter } from 'react-router-dom';
/*** STYLE  ***/
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components/macro';
/*** UI LIBS  ***/
import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

import DialogModal from '../../../../components/DialogModal';
/*** LOCAL COMPONENTS  ***/
/*** ELEMENTS  ***/
import SimpleBtn from '../../../../elements/SimpleBtn';
/*** ACTIONS  ***/
import { setQuestionView } from '../../../../store/actions/questionActions';
/*** UTILS & HELPERS ***/
import { color } from '../../../../styles/style';
/*** SCREENS  ***/

const PreviewFooterWrapper = styled.footer`
  background: ${props => color.blueLightest};
  width: 100%;
`;

const PreviewFooterAreaControls = styled.div`
  display: flex;
  justify-content: space-between;
  height: 6rem;
  align-items: center;
  max-width: 1000px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
`;
const ToolbarControls = styled.div`
  display: flex;
  flex-basis: 50%;
  max-width: 420px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const Btn = styled(SimpleBtn)`
  &&& {
    margin-right: 10px;
  }
`;
const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
  },
  copy: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
});
// width: theme.spacing.unit * 50,
class PreviewFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      dialogStatus: 'ready',
      dialogType: 'none',
      selected: [],
    };

    this.preFooterAction = this.preFooterAction.bind(this);
    this.cancelFooterAction = this.cancelFooterAction.bind(this);
    this.postFooterAction = this.postFooterAction.bind(this);
    this.continueToNext = this.continueToNext.bind(this);

    // this.handleExited = this.handleExited.bind(this);
  }
  componentDidUpdate(prevProps) {
    Object.keys(this.props).forEach(key => {
      if (this.props[key] !== prevProps[key]) {
        console.log(key, 'changed from', prevProps[key], 'to', this.props[key]);
      }
    });
  }
  handleDialogCancel = () => {
    console.log(`handle CANCEL`);
    this.setState({ isDialogOpen: false });
    // this.cancelFooterAction();
  };
  handleDialogClose = () => {
    console.log(`handle CLOSE`);
    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogDone = () => {
    const { section, history, view } = this.props;
    console.log(`handle DONE section=${section} view=> ${view}`);
    if (view === 'preview') {
      const url = `/admin/${section}`;
      this.props.setQuestionView('list').then(() => {
        history.push(url);
      });
    }

    this.setState({ isDialogOpen: false });
    // this.props.cancelGuideAction();
  };
  handleDialogAction = () => {
    // console.log(`handle ACTION`);
    this.setState({ isDialogOpen: false });
    this.gotoNewQuestionScreen();
    // this.postFooterAction();
  };
  gotoNewQuestionScreen = () => {
    const { section, history } = this.props;

    const url = `/admin/${section}/new`;
    history.push(url);
  };

  continueToNext() {
    this.props.nextQuestion();
  }
  preFooterAction() {
    this.props.preGuideAction();
  }
  cancelFooterAction() {
    this.props.cancelGuideAction();
  }
  postFooterAction() {
    this.props.postGuideAction();
  }

  handleClickEdit = event => {
    const { section, history } = this.props;
    event.preventDefault();
    const url = `/admin/${section}/edit`;
    this.props.setQuestionView('edit').then(() => {
      history.push(url);
    });
  };
  handleClickRemove = (qid, event) => {
    let newSelected = [];
    newSelected.push(qid);
    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'delete',
      qid: qid,
      selected: newSelected,
    });
  };
  handleClickUnpublish = (qid, event) => {
    let newSelected = [];
    newSelected.push(qid);
    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'unpublish',
      qid: qid,
      selected: newSelected,
    });
  };
  handleClickPublish = (qid, event) => {
    let newSelected = [];
    console.log(`qid= ${qid}`);
    newSelected.push(qid);
    event.preventDefault();
    this.setState({
      isDialogOpen: true,
      dialogStatus: 'ready',
      dialogType: 'publish',
      qid: qid,
      selected: newSelected,
    });
  };
  render() {
    const { qid, questions, questionNumber, isDraft } = this.props;

    const { isDialogOpen, dialogStatus, dialogType } = this.state;
    const totalQuestions = questions.length;
    // const hasMore = totalQuestions > 1;
    const isFirst = questionNumber === 1;
    const isLast = totalQuestions === questionNumber;

    return (
      <>
        <DialogModal
          type={dialogType}
          open={isDialogOpen}
          qid={qid}
          questions={questions}
          status={dialogStatus}
          handleClose={this.handleDialogClose}
          handleCancel={this.handleDialogCancel}
          handleAction={this.handleDialogAction}
          handleDone={this.handleDialogDone}
        />
        <PreviewFooterWrapper>
          <PreviewFooterAreaControls>
            {isFirst ? (
              <Button
                variant="text"
                color="default"
                onClick={this.postFooterAction}
              >
                Close
              </Button>
            ) : (
              <Button
                variant="text"
                color="default"
                onClick={this.postFooterAction}
              >
                <ArrowBack /> PREVIOUS
              </Button>
            )}

            {isDraft ? (
              <ToolbarControls>
                <Btn
                  variant="contained"
                  type="remove"
                  onClick={e => this.handleClickRemove(qid, e)}
                >
                  DELETE
                </Btn>

                <Btn
                  variant="contained"
                  type="primary"
                  onClick={this.handleClickEdit}
                >
                  EDIT
                </Btn>
                <SimpleBtn
                  variant="contained"
                  type="secondary"
                  onClick={e => this.handleClickPublish(qid, e)}
                >
                  PUBLISH
                </SimpleBtn>
              </ToolbarControls>
            ) : (
              <ToolbarControls>
                <SimpleBtn
                  variant="contained"
                  type="remove"
                  onClick={e => this.handleClickUnpublish(qid, e)}
                >
                  UNPUBLISH
                </SimpleBtn>
              </ToolbarControls>
            )}

            {isLast ? (
              <Button
                variant="text"
                type="default"
                onClick={this.continueToNext}
              >
                END
              </Button>
            ) : (
              <Button
                variant="text"
                type="default"
                onClick={this.continueToNext}
              >
                NEXT
                <ArrowForward />
              </Button>
            )}
          </PreviewFooterAreaControls>
        </PreviewFooterWrapper>
      </>
    );
  }
}

PreviewFooter.propTypes = {
  isDraft: PropTypes.bool.isRequired,
  section: PropTypes.string.isRequired,
  qid: PropTypes.string.isRequired,
  questions: PropTypes.array.isRequired,
  questionNumber: PropTypes.number.isRequired,
  postGuideAction: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
};
const mapDispatchToProps = dispatch => {
  return {
    setQuestionView: view => dispatch(setQuestionView(view)),
  };
};
export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(PreviewFooter);
