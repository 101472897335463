import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/

/*** ROUTER  ***/

/*** STYLE  ***/
import styled from 'styled-components/macro';
/*** UI LIBS  ***/
/*** CONTAINERS  ***/
/*** GLOBAL COMPONENTS  ***/
import Loading from '../../../../components/Loading';
/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/
import QuestionTitle from './QuestionTitle';
import QuestionCopy from './QuestionCopy';
import AnswerItem from './AnswerItem';
import FeedbackCard from './FeedbackCard';
import FeedbackTitle from './FeedbackTitle';
import FeedbackCopy from './FeedbackCopy';
// import { getQuestion } from '../../store/actions/studyGuideBuilderActions';
// import { convertToHtml } from '../../../../utils/convertToHtml';

const AnswersArea = styled.div`
  padding: 3rem 0;
`;
const FeedbackArea = styled.div`
  margin: 2rem 0 0;
`;

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerItems: ['A', 'B', 'C', 'D'],
      correctAnswer: props.content.correctAnswer,
    };
    // this.checkItemType = this.checkItemType.bind(this);
  }
  componentDidMount() {
    // const { qid } = this.props;
    // this.props.getQuestion(qid);
  }
  // toggleAnswerItem(key, e) {
  //   console.log(`key=${key}`);
  //   const { selectUserAnswer } = this.props;
  //   selectUserAnswer(key);
  //   e.preventDefault();
  //
  // }

  // checkItemType(key) {
  //   const { content, userAnswer } = this.props;
  //   // const { userAnswer } = this.state;
  //   const { correctAnswer } = content.qdata;
  //   let buttonType = 'normal';
  //   if (key === userAnswer) {
  //     if (userAnswer === correctAnswer) {
  //       buttonType = 'success';
  //     } else {
  //       buttonType = 'wrong';
  //     }
  //   } else {
  //     if (key === correctAnswer) {
  //       buttonType = 'success';
  //     }
  //   }
  //   return buttonType;
  // }
  render() {
    const { content, qnum } = this.props;
    const { answerItems } = this.state;

    const qobj = content !== null ? content : null;
    if (qobj) {
      return (
        <>
          <QuestionTitle>{`Question #${qnum}`}</QuestionTitle>
          <QuestionCopy dangerouslySetInnerHTML={{ __html: qobj.question }} />

          <FeedbackArea>
            <FeedbackCard>
              <FeedbackTitle>Explanation</FeedbackTitle>
              <FeedbackCopy
                className="correctFeedback"
                dangerouslySetInnerHTML={{
                  __html: qobj.correctFeedback,
                }}
              />
              <FeedbackCopy
                className="feedback1"
                dangerouslySetInnerHTML={{
                  __html: qobj.feedback1,
                }}
              />
              {qobj.feedback2 && (
                <FeedbackCopy
                  className="feedback2"
                  dangerouslySetInnerHTML={{
                    __html: qobj.feedback2,
                  }}
                />
              )}
              {qobj.feedback3 && (
                <FeedbackCopy
                  className="feedback3"
                  dangerouslySetInnerHTML={{
                    __html: qobj.feedback3,
                  }}
                />
              )}
            </FeedbackCard>
          </FeedbackArea>

          <AnswersArea>
            {answerItems.map(itemKey => (
              <AnswerItem
                key={itemKey}
                itemLetter={itemKey}
                itemCopy={qobj[itemKey]}
                isAnswer={qobj.correctAnswer === itemKey}
              />
            ))}
          </AnswersArea>
        </>
      );
    }
    return <Loading />;
  }
}

Question.propTypes = {
  content: PropTypes.object,
  qnum: PropTypes.number,
  // An object taking on a particular shape
  // qobj: PropTypes.shape({
  //   color: PropTypes.string,
  //   fontSize: PropTypes.number,
  // }),
};
export default Question;
