import React, { Component } from 'react';
import PropTypes from 'prop-types';
/*** DATA, STORE and STATE  ***/
import { connect } from 'react-redux';
/*** ROUTER  ***/

/*** STYLE  ***/

/*** UI LIBS  ***/

/*** CONTAINERS  ***/
import { MainScreen } from '../../../containers/Main';
/*** SCREENS  ***/
import UserInfo from '../../../screens/UserInfo';
/*** GLOBAL COMPONENTS  ***/

/*** LOCAL COMPONENTS  ***/

/*** ELEMENTS  ***/

/*** ACTIONS & APIs ***/
import { signOut } from '../../../store/actions/authActions';
/*** UTILS & HELPERS ***/

class Dashboard extends Component {
  render() {
    const { signOut, user } = this.props;
    return (
      <MainScreen title="Student Dashboard">
        <UserInfo logout={signOut} user={user} />
      </MainScreen>
    );
  }
}
Dashboard.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func,
};
const mapStateToProps = state => {
  return {
    user: state.firebase.profile,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
