import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import authReducer from './authReducer';
import usersReducer from './usersReducer';
import studentStatus from './studentStatus';
import studentReducer from './studentReducer';
import questionStatus from './questionStatus';
import studentsFree from './studentsFree';
import studentsNew from './studentsNew';
import studentsPaid from './studentsPaid';
import userStatus from './userStatus';
import appStatus from './appStatus';
// import studyGuideStatus from './studyGuideStatus';
const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  studentReducer: studentReducer,
  questionStatus: questionStatus,
  studentStatus,
  studentsFree,
  studentsNew,
  studentsPaid,
  userStatus,
  appStatus,
});

export default rootReducer;
