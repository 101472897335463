import React, { Component } from 'react';
import PropTypes from 'prop-types';

/***    router          ***/

/***    data and store  ***/
import { connect } from 'react-redux';
import { compose } from 'redux';
/***    ui & styles     ***/
import styled from 'styled-components/macro';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
/***    containers      ***/
import { MainScreen } from '../../../../containers/Main';
import { Card } from '../../../../containers/Card';
/***    screens         ***/
import Profile from './Profile';
import Billing from './Billing';
/***    components      ***/
import LoadingModal from '../../../../components/LoadingModal';
import AvatarCard from '../../../../components/AvatarCard';
/***    elements        ***/
import FlushLinkButton from '../../../../elements/FlushLinkButton';
/***    assets          ***/
/***    actions         ***/
import {
  updateStudent,
  sendOutNewUsuerEmail,
} from '../../../../store/actions/studentActions';
/***    utils           ***/
import firebaseAdmin from '../../../../store/api/firebaseAdmin';
import { color, fontWeight } from '../../../../styles/style';
import StatsRowFlat from '../../../../data/StatsRowFlat';

function TabContainer(props) {
  return <>{props.children}</>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  typography: {
    padding: theme.spacing.unit * 3,
  },
  emptyState: {
    textAlign: 'center',
    padding: '2rem',
    color: '#BFCCD9',
    fontWeight: 700,
  },
  tableCell: {
    color: color.dark,
    fontWeight: fontWeight.semiBold,
  },
  tableHeadCell: {
    color: color.blueDarkest,
    fontWeight: 700,
  },
  moreVert: {
    textAlign: 'right',
    color: color.blueDarkest,
  },
  wrapper: {
    margin: theme.spacing.unit,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: color.primary,
    '&:hover': {
      backgroundColor: color.primaryDarker,
    },
  },

  buttonProgress: {
    color: color.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const Screen = styled.div`
  position: relative;
  width: 100%;
  overflow-y: auto;
`;
class View extends Component {
  constructor(props) {
    super(props);
    // this.refUserDoc = mbeFS.collection('users').doc(props.currentId);
    this.refUserDoc = null;
    this.goBackScreen = this.goBackScreen.bind(this);
  }
  state = {
    tabIndex: 0,
    key: null,
    isReady: false,
    student: null,
    studentData: null,
    isUpdating: false,
    // btnLoading: false,
    // btnSuccess: false,
  };

  onDocUpdate = doc => {
    const student = doc.data();
    console.log(`${JSON.stringify(student)}`);
    const init = {
      total: 0,
      correct: 0,
      wrong: 0,
    };
    const all = student.hasOwnProperty('all') ? student.all : init;
    const test = student.hasOwnProperty('test') ? student.test : init;
    const study = student.hasOwnProperty('study') ? student.study : init;

    this.setState({
      student: doc.data(),
      key: doc.id,
      isReady: true,
      isUpdating: false,
      studentData: {
        all,
        test,
        study,
      },
    });
  };
  componentDidMount() {
    const { currentId } = this.props;
    const adminFS = firebaseAdmin.firestore();
    //console.log(`currentId= ${currentId}`);
    this.refUserDoc = adminFS
      .collection('students')
      .doc(currentId)
      .onSnapshot(this.onDocUpdate);
  }
  componentWillUnmount() {
    this.refUserDoc();
  }

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex });
  };
  resendEmail = email => {
    return this.props.sendOutNewUsuerEmail(email);
  };
  updateStudent = (event, sobj) => {
    const { key } = this.state;
    // this.setState({
    //   isLoading: true,
    // });
    event.preventDefault();
    const studentObj = {
      ...sobj,
      uid: key,
    };

    this.props.updateStudent(studentObj).then(() => {
      this.setState({
        isReady: true,
        isUpdating: true,
      });
    });
  };

  goBackScreen(e) {
    const { history } = this.props;
    history.push(`/admin/students`);
    e.preventDefault();
  }

  render() {
    const { classes, currentId, loading } = this.props;
    const {
      isReady,
      // btnLoading,
      student,
      tabIndex,
      studentData,
      isUpdating,
      // btnSuccess,
      // isSuspended,
      // isUpgraded,
    } = this.state;

    return (
      <>
        {loading && <LoadingModal open={loading} />}
        <Screen>
          <MainScreen
            title="Student Profile"
            leftSideItem={
              <FlushLinkButton onClick={this.goBackScreen}>
                <ArrowBackIcon />
                BACK
              </FlushLinkButton>
            }
            spacing={24}
          >
            {isReady && (
              <>
                <Grid item xs={4}>
                  <Grid container spacing={24} direction={'column'}>
                    <Grid item>
                      <AvatarCard user={student} />
                    </Grid>
                    <Grid item />
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Card>
                    <Tabs
                      value={tabIndex}
                      onChange={this.handleChange}
                      classes={{
                        root: classes.tabsRoot,
                        indicator: classes.tabsIndicator,
                      }}
                    >
                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="PROFILE"
                      />
                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="BILLING"
                      />
                      {/* {student.accountType === 'pro' ||
                        (student.accountType === 'paid' && (
                          <Tab
                            disableRipple
                            classes={{
                              root: classes.tabRoot,
                              selected: classes.tabSelected,
                            }}
                            label="BILLING"
                          />
                        ))} */}

                      <Tab
                        disableRipple
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabSelected,
                        }}
                        label="ACTIVITY"
                      />
                    </Tabs>

                    {tabIndex === 0 && (
                      <TabContainer>
                        {isUpdating ? (
                          <Grid
                            container
                            direction="column"
                            justify="space-around"
                            alignItems="center"
                          >
                            <CircularProgress />
                          </Grid>
                        ) : (
                          <Profile
                            studentId={currentId}
                            student={student}
                            // isUpdating = { isUpdating }
                            actionUpdate={this.updateStudent}
                            actionEmail={this.resendEmail}
                          />
                        )}
                      </TabContainer>
                    )}
                    {tabIndex === 1 && (
                      <TabContainer>
                        <Billing studentId={currentId} student={student} />
                      </TabContainer>
                    )}
                    {tabIndex === 2 && (
                      <TabContainer>
                        {studentData ? (
                          <>
                            <StatsRowFlat
                              label="Overall"
                              stats={studentData.all}
                            />

                            <StatsRowFlat
                              label="Study Aids"
                              stats={studentData.study}
                            />
                            <StatsRowFlat
                              label="Practice Exams"
                              stats={studentData.test}
                            />
                          </>
                        ) : (
                          <div className={classes.emptyState}>
                            No Student Data
                          </div>
                        )}
                      </TabContainer>
                    )}
                  </Card>
                </Grid>
              </>
            )}
          </MainScreen>
        </Screen>
      </>
    );
  }
}

View.propTypes = {
  currentId: PropTypes.string,
  //sbank: PropTypes.string.isRequired,
};
const mapStateToProps = state => {
  return {
    appStatus: state.appStatus,
    loading: state.appStatus.loading,
    currentId: state.appStatus.data.currentId,
    // sbank: state.studentStatus.sbank,
    status: state.appStatus.status,
    //currentStudent: state.studentStatus.current,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // fetchStudentById: uid => dispatch(fetchStudentById(uid)),
    updateStudent: sobj => dispatch(updateStudent(sobj)),
    sendOutNewUsuerEmail: email => dispatch(sendOutNewUsuerEmail(email)),
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(View);
