import React from 'react';
// import PropTypes from "prop-types";
import styled from 'styled-components/macro';
import { color, util } from '../../../../../styles/style';
const Card = styled.div`
  padding: ${util.padding};
  background: ${color.white};
  box-shadow: 0 2px 7px 0 ${color.blueLightest};
  border-radius: ${util.radiusSmall};
  border-top: solid 6px ${color.primary};
  position: relative;
  transition: all 0.233s cubic-bezier(0, 0, 0.21, 1) 0.1s;
`;

const FeedbackCard = props => <Card>{props.children}</Card>;

FeedbackCard.propTypes = {};

export default FeedbackCard;
